import React from 'react';
import axios from 'axios';
import Swal from 'sweetalert2';
import { updateElementPropertyInList } from '../../../helpers/utilities';
import { URLS } from '../../../constants/urls';
import Toast from '../../../components/Toast/Toast';
import { USER_ROLES } from '../../../constants/enums';
import UsersTable from './UsersTable';


export default class ActiveUsers extends React.Component {
  constructor(props) {
    super(props);

    this.onDelete = this.onDelete.bind(this);
    this.onRolesChange = this.onRolesChange.bind(this);
  }

  onDelete(user) {
    const root = this;
    Swal.fire({
      title: "Are you sure?",
      type: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes",
    }).then((result) => {
      if (result.value) {
        let users = [...root.props.users];
        users = updateElementPropertyInList(
          users,
          "id",
          user.id,
          "loading",
          true
        );
        root.props.onUpdateUsers(users);
        axios
          .post(URLS.USERS.DELETE + user.id)
          .then(function (response) {
            if (response.data.success) {
              root.props.onUpdateUsers(
                updateElementPropertyInList(
                  users,
                  "id",
                  user.id,
                  "deleted",
                  true
                )
              );
            } else if (response.data.errors) {
              Toast.error(response.data.errors);
            }
          })
          .catch(function (response) {
            Toast.error(response.message, "Error deleting user");
          })
          .then(function () {
            root.props.onUpdateUsers(
              updateElementPropertyInList(
                users,
                "id",
                user.id,
                "loading",
                false
              )
            );
          });
      }
    });
  }

  onRolesChange(user) {
    let userRoles = JSON.parse(JSON.stringify(user.roles));
    const index = userRoles.indexOf(USER_ROLES.admin);

    if (index > -1) {
      userRoles.splice(index, 1);
    } else {
      userRoles.push(USER_ROLES.admin);
    }

    const root = this;
    let users = [...root.props.users];

    root.props.onUpdateUsers(
      updateElementPropertyInList(users, "id", user.id, "loading", true)
    );

    axios
      .post(URLS.USERS.ROLES + user.id, userRoles)
      .then(function (response) {
        if (response.data.success) {
          root.props.onUpdateUsers(
            updateElementPropertyInList(
              users,
              "id",
              user.id,
              "roles",
              userRoles
            )
          );
        } else if (response.data.errors) {
          Toast.error(response.data.errors);
        }
      })
      .catch(function (response) {
        Toast.error(response.message, "Error updating user");
      })
      .then(function () {
        root.props.onUpdateUsers(
          updateElementPropertyInList(users, "id", user.id, "loading", false)
        );
      });
  }

  render() {
    return (
      <div className="row">
        <div className="col-12">
          <p className="mb-4">Active users who can log in and use the system</p>
        </div>
        <div className="col-12">
          {this.props.users.length > 0 && (
            <UsersTable
              onDelete={this.onDelete}
              onRolesChange={this.onRolesChange}
              users={this.props.users}
              type="active"
              currentUser={this.props.currentUser}
            />
          )}
        </div>
      </div>
    );
  }
}

