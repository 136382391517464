import React from "react";
import Auth from "./../../helpers/auth";
import Spinner from "./../Spinner/Spinner";
import LoggedOut from "./LoggedOut";
import { MainContext } from './../../StateManagement/StateManagement';

/*
<Page
  title="Page title"
  extras={<button>Edit</button>}
  adminOnly={true}
  className="bg-white"  // default bg-light
  loading={this.state.loading}
  type="tabs">           // default empty
	  Children
</Page>
*/
export default class Page extends React.Component {
  static contextType = MainContext;

  componentDidMount() {
    if (this.props.title) document.title = this.props.title;
  }

  render() {
    // When user signs out in one tab/window, other open tabs/windows will do the check too.
    if (!Auth.isSignedIn()) {
      return <LoggedOut />;
    }

    const currentUser = this.context.state.currentUser;
    let content;
    if (this.props.adminOnly && !currentUser.isAdmin) {
      content = <NotAllowedPage />;
    } else if (this.props.loading) {
      content = <Spinner />;
    } else {
      const titleAndExtras = (
        <div className="row">
          {this.props.title && (
            <div className={this.props.extras ? "col-md-6" : "col-md-12"}>
              <h5 className="mb-3 text-black-50">{this.props.title}</h5>
            </div>
          )}
          {this.props.extras && (
            <div className={this.props.title ? "col-md-6" : "col-md-12"}>
              <div className="mb-3 mb-md-0 float-md-right">
                {this.props.extras}
              </div>
            </div>
          )}
        </div>
      );

      if (this.props.type === "tabs") {
        content = (
          <div className="bg-white mt-n4 mx-n3 mx-md-n4 pt-3 px-3 px-md-4">
            {titleAndExtras}
            <div className="mx-n3 mx-md-n4">{this.props.children}</div>
          </div>
        );
      } else {
        content = (
          <div className="mt-n2">
            {titleAndExtras}
            {this.props.children}
          </div>
        );
      }
    }

    return (
      <main className={this.props.className || "bg-light"}>{content}</main>
    );
  }
}

/*
  <FullPage 
    className="bg-light">
    Children
  </FullPage>
*/
export function FullPage(props) {
  return (
    <div
      className={
        "h-100 w-100 fixed-top " + (props.className || "")
      }
    >
      <div className="row h-100 m-0">
        <div className="my-auto mx-auto text-center text-secondary">
          {props.children}
        </div>
      </div>
    </div>
  );
}

export function NotAllowedPage() {
  return (
    <div className="d-flex">
      <div className="text-danger mx-auto my-3">
        You don't have sufficient previleges to access to this page. Please,
        contact your admin.
      </div>
    </div>
  );
}
