import React from "react";
import Rollbar from "rollbar";
import { FullPage } from "./Page";
import { FloatingLabelTextArea } from "./../FloatingLabelFormElements/FloatingLabelFormElements";
import CONFIG, { Environment } from "./../../constants/envConfig";
import { MainContext } from "./../../StateManagement/StateManagement";

/* 
    This component is mounted on a very top level, therefore Rollbar is configured on app start.
    React catches errors inside "componentDidCatch()" function and displays "Something went wrong" message.
    Having "Rollbar" object inside "window" object lets Rollbar globally catch/collect javascript errors.
    For example errors inside events are not caught by React.

    <ErrorBoundary>
      Children
    </ErrorBoundary>
*/

export default class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hasError: false,
      error: {},
      errorInfo: null,
      notes: "",
      sent: false,
    };

    this.onChange = this.onChange.bind(this);
    this.onSend = this.onSend.bind(this);
  }

  static contextType = MainContext;

  componentDidUpdate() {
    if (!window.Rollbar && !Environment.isLocal()) {
      const { currentUser } = this.context.state;
      let person = null;
      if (currentUser) {
        person = {
          id: currentUser.id,
          email: currentUser.email,
          username: currentUser.email,
          name: `${currentUser.firstName} ${currentUser.lastName}`,
        };
      }

      // Add Rollbar object to window, so that it will globally catch/collect all javascript errors.
      window.Rollbar = new Rollbar({
        accessToken: CONFIG.rollbarAccessToken,
        captureUncaught: true,
        captureUnhandledRejections: true,
        payload: {
          environment: Environment.env,
          person: person,
        },
      });
    }
  }

  static getDerivedStateFromError(error) {
    document.title = "Something went wrong";

    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  // Send React caught errors to Rollbar
  componentDidCatch(error, errorInfo) {
    this.setState({ error, errorInfo });

    if (window.Rollbar) {
      window.Rollbar.error(error, errorInfo);
    }
  }

  onChange(e) {
    this.setState({ notes: e.target.value });
  }

  onSend() {
    if (window.Rollbar) {
      const { error, errorInfo, notes } = this.state;
      const description = "[USERNOTES]";
      const payload = { userNotes: notes };
      window.Rollbar.error(description, error, errorInfo, payload);
      this.setState({ sent: true });
    }
  }

  render() {
    if (this.state.hasError) {
      return (
        <FullPage className="bg-light">
          <div className="col">
            <h3>Oh ohh...</h3>
            <h5>Something went wrong</h5>
            <p className="text-danger">
              {/* Display real error message only in local and dev environment*/}
              {Environment.isLocal() || Environment.isDevelopment()
                ? this.state.error.message
                : ":("}
            </p>
          </div>
          <div className="col-md-12 mt-3">
            <a href="/App" className="btn btn-outline-secondary">
              Dashboard
            </a>
            <button
              onClick={() => window.location.reload()}
              className="btn btn-outline-secondary ml-2"
            >
              Reload
            </button>
          </div>
          <div className="col-md-4 offset-md-4 mt-4">
            {this.state.sent ? (
              <p className="text-primary">Notes sent. Thank you!</p>
            ) : (
              <>
                <p>
                  Can you please, provide us the steps you followed before
                  getting to this page ? This will help us to reproduce the
                  issue and fix it asap. Please, <strong>do not</strong> enter
                  any sensitive information (e.g passwords).
                </p>
                <FloatingLabelTextArea
                  name="description"
                  placeholder="Enter your notes"
                  minHeight="80px"
                  onChange={this.onChange}
                  value={this.state.notes}
                />
                <button
                  className="btn btn-outline-primary"
                  style={{ minWidth: "100px" }}
                  onClick={this.onSend}
                  disabled={!this.state.notes}
                >
                  Send
                </button>
              </>
            )}
          </div>
        </FullPage>
      );
    }
    return this.props.children;
  }
}
