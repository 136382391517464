import React from "react";
import "./FileInput.css";

const iconMagnify =
  "M9.5,3A6.5,6.5 0 0,1 16,9.5C16,11.11 15.41,12.59 14.44,13.73L14.71,14H15.5L20.5,19L19,20.5L14,15.5V14.71L13.73,14.44C12.59,15.41 11.11,16 9.5,16A6.5,6.5 0 0,1 3,9.5A6.5,6.5 0 0,1 9.5,3M9.5,5C7,5 5,7 5,9.5C5,12 7,14 9.5,14C12,14 14,12 14,9.5C14,7 12,5 9.5,5Z";
const iconDelete =
  "M19,4H15.5L14.5,3H9.5L8.5,4H5V6H19M6,19A2,2 0 0,0 8,21H16A2,2 0 0,0 18,19V7H6V19Z";
const iconUndo =
  "M12.5,8C9.85,8 7.45,9 5.6,10.6L2,7V16H11L7.38,12.38C8.77,11.22 10.54,10.5 12.5,10.5C16.04,10.5 19.05,12.81 20.1,16L22.47,15.22C21.08,11.03 17.15,8 12.5,8Z";
const iconUpload =
  "M14,2L20,8V20A2,2 0 0,1 18,22H6A2,2 0 0,1 4,20V4A2,2 0 0,1 6,2H14M18,20V9H13V4H6V20H18M12,12L16,16H13.5V19H10.5V16H8L12,12Z";
const iconClose =
  "M19,6.41L17.59,5L12,10.59L6.41,5L5,6.41L10.59,12L5,17.59L6.41,19L12,13.41L17.59,19L19,17.59L13.41,12L19,6.41Z";
const iconLeft =
  "M15.41,16.58L10.83,12L15.41,7.41L14,6L8,12L14,18L15.41,16.58Z";
const iconRight = "M8.59,16.58L13.17,12L8.59,7.41L10,6L16,12L10,18L8.59,16.58Z";
const iconDownload = "M5,20H19V18H5M19,9H15V3H9V9H5L12,16L19,9Z";
/* 
  <FileInput 
    name="file" 
    onChange={this.onChange}
    files={this.state.files}      // single File or array of Files or FileList
    existingFileUrls="someurl"    // single string or array of strings
    onDeleteExistingFileUrl={this.onOriginalFileDelete}   // will call the provided "delete" function with existingFileUrl as parameter. Used when a file is chosen to be deleted or a new file has replaced it.
    onRestoreExistingFileUrl={this.onOriginalFileRestore} // will call the provided "unDelete/restore" function with existingFileUrl as parameter. Used old file needs to be restored after a new one replaced it.
    accept="image"    // "image" | "image, pdf, doc" | "png, jpg" //without spaces and dots
    maxSize="5"       // int mb values
    maxFileCount="3"  // max number of files allowed if multiple
    disabled={true}   // or just "disabled"
    multiple={true}   // or just "multiple"
    outerClassName="col-md-3" // wrapper div's class
    innerClassName="h-100"    // main div's class
  /> 
*/
export default class FileInput extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      fileItems: [], // e.g [{file, errors: ["err1"], "existingFileurl"}]
      fileItemIdToZoom: null
    };

    this.accept = [];
    this.maxSize = null;
    this.maxFileCount = null;

    this.onChange = this.onChange.bind(this);
    this.onRestore = this.onRestore.bind(this);
    this.onDelete = this.onDelete.bind(this);
    this.onZoom = this.onZoom.bind(this);
  }

  componentDidMount() {
    this.maxSize = this.props.maxSize
      ? parseFloat(this.props.maxSize)
      : this.maxSize;

    this.maxFileCount = this.props.multiple
      ? this.props.maxFileCount
        ? parseFloat(this.props.maxFileCount)
        : 100
      : 1;

    this.accept = this.props.accept
      ? this.props.accept
          .replace(" ", "")
          .replace(".", "")
          .toLowerCase()
          .split(",")
      : this.accept;

    this.initFileItems();
  }

  componentDidUpdate(prevProps) {
    if (this.props.existingFileUrls !== prevProps.existingFileUrls) {
      let newUrls = Array.isArray(this.props.existingFileUrls)
        ? this.props.existingFileUrls
        : [this.props.existingFileUrls];
      let oldUrls = Array.isArray(prevProps.existingFileUrls)
        ? prevProps.existingFileUrls
        : [prevProps.existingFileUrls];

      if (
        newUrls.length !== oldUrls.length ||
        !newUrls.sort().every((value, index) => value === oldUrls.sort()[index])
      ) {
        this.initFileItems();
      }
    }
  }

  initFileItems() {
    let fileItems = [];

    if (this.props.existingFileUrls) {
      if (Array.isArray(this.props.existingFileUrls)) {
        fileItems = this.props.existingFileUrls.map((url, index) => ({
          id: Date.now() + index,
          file: null,
          errors: [],
          existingFileUrl: url
        }));
      } else {
        fileItems.push({
          id: Date.now() + 1,
          file: null,
          errors: [],
          existingFileUrl: this.props.existingFileUrls
        });
      }
    }

    if (this.props.files) {
      if (Array.isArray(this.props.files)) {
        fileItems = fileItems.concat(
          this.props.files.map((file, index) => ({
            id: Date.now() * 10 + index,
            file: file,
            errors: [],
            existingFileUrl: null
          }))
        );
      } else {
        fileItems.push({
          id: Date.now() * 10 + 1,
          file: this.props.files,
          errors: [],
          existingFileUrl: null
        });
      }
    }

    this.setState({ fileItems });
  }

  onChange(arg1, arg2) {
    let e = arg2 || arg1;
    const fileItemId = arg2 && arg1;

    e.preventDefault();

    if (this.props.disabled) return;

    let files;

    if (e.type === "drop") {
      files = e.dataTransfer.files;
      e = { target: { name: this.props.name, type: "file", files } };
    } else {
      files = e.target.files;
    }

    if (!files || files.length === 0) return;

    let fileItems = [];

    for (var i = 0; i < files.length; i++) {
      if (
        !fileItemId &&
        this.state.fileItems.length + fileItems.length === this.maxFileCount
      )
        break;

      let file = files[i];
      let errors = [];

      if (!file) return;

      if (
        this.accept.length > 0 &&
        !this.accept.includes(
          file.name
            .split(".")
            .pop()
            .toLowerCase()
        ) &&
        !(
          (this.accept.includes("image") || this.accept.includes("images")) &&
          isImage({ file, existingFileUrl: null })
        )
      ) {
        errors.push(
          `Invalid format! Allowed formats: ${this.accept.join(", ")}`
        );
      }

      if (this.maxSize && file.size > this.maxSize * 1000000) {
        errors.push(`Exceeded max allowed file size of ${this.maxSize} mb`);
      }

      if (errors.length > 0) {
        file = null;
      }

      fileItems.push({
        id: Date.now() + i,
        file,
        errors,
        existingFileUrl: null
      });
    }

    if (fileItemId) {
      let items = [...this.state.fileItems];
      let fileItem = items.find(fi => fi.id === fileItemId);
      fileItem.file = fileItems[0].file;
      fileItem.errors = fileItems[0].errors;
      fileItems = items;

      if (fileItem.existingFileUrl && this.props.onDeleteExistingFileUrl) {
        this.props.onDeleteExistingFileUrl(fileItem.existingFileUrl);
      }
    } else {
      fileItems = this.state.fileItems.concat(fileItems);
    }

    this.setState({ fileItems });

    e = {
      target: {
        name: this.props.name,
        type: "file",
        files: fileItems.reduce((accumulator, fi) => {
          if (fi.file) {
            accumulator.push(fi.file);
          }
          return accumulator;
        }, [])
      }
    };

    // update file in parent component
    this.props.onChange(e); // onChange of parent component
  }

  onRestore(fileItemId) {
    let fileItems = [...this.state.fileItems];
    let fileItem = fileItems.find(fi => fi.id === fileItemId);
    if (fileItem.existingFileUrl) {
      fileItem.file = null;
      fileItem.errors = [];

      if (this.props.onRestoreExistingFileUrl) {
        this.props.onRestoreExistingFileUrl(fileItem.existingFileUrl);
      }
    } else {
      fileItems.splice(fileItems.indexOf(fileItem), 1);
    }

    this.setState({ fileItems });

    const e = {
      target: {
        name: this.props.name,
        type: "file",
        files: fileItems.reduce((accumulator, fi) => {
          if (fi.file) {
            accumulator.push(fi.file);
          }
          return accumulator;
        }, [])
      }
    };

    // reset file in parent component
    this.props.onChange(e); // onChange of parent component
  }

  onDelete(fileItemId) {
    let fileItems = [...this.state.fileItems];
    let fileItem = fileItems.find(fi => fi.id === fileItemId);

    if (fileItem.existingFileUrl) {
      if (this.props.onDeleteExistingFileUrl) {
        this.props.onDeleteExistingFileUrl(fileItem.existingFileUrl);
      }

      fileItems.splice(fileItems.indexOf(fileItem), 1);
      this.setState({ fileItems });
    }
  }

  onZoom(fileItemId) {
    this.setState({ fileItemIdToZoom: fileItemId || null });
  }

  render() {
    const outerClassName = this.props.outerClassName || "";
    const innerClassName =
      "file-input " +
      (this.props.disabled ? "disabled " : "") +
      (this.props.innerClassName || "");
    const mainText = this.props.disabled
      ? "Upload disabled"
      : "Browse files or drag here";

    let list = this.state.fileItems.map(item => {
      const _isImage = isImage(item);
      let { src, fileName, size, type } = getDetails(item);

      const leftActionTitle = _isImage ? "Zoom" : "Download";
      const leftActionIcon = (
        <svg viewBox="0 0 20 30">
          <path fill="#000000" d={_isImage ? iconMagnify : iconDownload} />
        </svg>
      );

      const rightActionTitle =
        item.existingFileUrl && (item.file || item.errors.length > 0)
          ? "Restore original file"
          : "Delete";
      const rightActionIcon = (
        <svg viewBox="0 0 20 30">
          {item.existingFileUrl && (item.file || item.errors.length > 0) ? (
            <path fill="#000000" d={iconUndo} />
          ) : (
            <path fill="#000000" d={iconDelete} />
          )}
        </svg>
      );

      return (
        <div className={outerClassName} key={item.id}>
          <div className={innerClassName}>
            {item.errors.length > 0 && (
              <div>
                <ul className="darken">
                  {item.errors.map((error, index) => (
                    <li key={index}>{error}</li>
                  ))}
                </ul>
                {!this.props.disabled && (
                  <div className="actions">
                    <button
                      className="right"
                      onClick={() => this.onRestore(item.id)}
                      title={rightActionTitle}
                    >
                      {rightActionIcon}
                    </button>
                  </div>
                )}
              </div>
            )}
            {item.errors.length === 0 && src && (
              <div className="inner">
                <div className="darken" />
                <div className="details">
                  <span className="name">{fileName}</span>
                  {size && <span className="size">size</span>}
                </div>
                <div className="preview">
                  {_isImage && <img src={src} alt="Preview" />}
                  {!_isImage && fileName && (
                    <div className="type">{type.toUpperCase()}</div>
                  )}
                </div>
              </div>
            )}
            {
              <div className="actions">
                {item.errors.length === 0 && leftActionIcon && _isImage && (
                  <button
                    className="left"
                    onClick={() => this.onZoom(item.id)}
                    title={leftActionTitle}
                  >
                    {leftActionIcon}
                  </button>
                )}
                {item.errors.length === 0 &&
                  leftActionIcon &&
                  !_isImage &&
                  item.existingFileUrl &&
                  !item.file && (
                    <a
                      href={item.existingFileUrl}
                      className="left"
                      title={leftActionTitle}
                    >
                      {leftActionIcon}
                    </a>
                  )}
                {!this.props.disabled && item.file && (
                  <button
                    className="right"
                    onClick={() => this.onRestore(item.id)}
                    title={rightActionTitle}
                  >
                    {rightActionIcon}
                  </button>
                )}
                {!this.props.disabled &&
                  item.existingFileUrl &&
                  !item.file &&
                  item.errors.length === 0 && (
                    <button
                      className="right"
                      onClick={() => this.onDelete(item.id)}
                      title={rightActionTitle}
                    >
                      {rightActionIcon}
                    </button>
                  )}
              </div>
            }
            {(this.props.disabled || item.errors.length === 0) && (
              <label className={src ? "hide" : ""}>
                <svg viewBox="0 -6 24 42">
                  <path d={iconUpload} />
                </svg>
                <p>{mainText}</p>
                <input
                  onClick={e => (e.target.value = null)} // this will reset input all times, so that same file can be selected with onChange trigger
                  type="file"
                  name={this.props.name}
                  onChange={this.onChange.bind(this, item.id)}
                  onDrop={this.onChange.bind(this, item.id)}
                  disabled={this.props.disabled}
                  title={
                    this.props.disabled
                      ? "Upload disabled"
                      : fileName
                  }
                />
              </label>
            )}
          </div>
        </div>
      );
    });

    list.push(
      this.state.fileItems.length < this.maxFileCount && (
        <div className={outerClassName} key="add-new-file">
          <div className={innerClassName}>
            <label>
              <svg viewBox="0 -6 24 42">
                <path d={iconUpload} />
              </svg>
              <p>{mainText}</p>
              <input
                onClick={e => (e.target.value = null)} // this will reset input all times, so that same file can be selected with onChange trigger
                type="file"
                name={this.props.name}
                onChange={this.onChange}
                onDrop={this.onChange}
                disabled={this.props.disabled}
                multiple={this.maxFileCount > 1}
                title={mainText}
              />
            </label>
          </div>
        </div>
      )
    );

    if (this.state.fileItemIdToZoom) {
      list.push(
        <ZoomModal
          key="zoom-modal-key"
          fileItems={this.state.fileItems}
          fileItemId={this.state.fileItemIdToZoom}
          onClose={this.onZoom}
        />
      );
    }

    return list;
  }
}

class ZoomModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      fileItem: null
    };

    this.fileItems = [];

    this.onNav = this.onNav.bind(this);
  }

  componentDidMount() {
    const fileItems = this.props.fileItems.filter(fi => isImage(fi));
    this.fileItems = [...fileItems];
    const fileItem = this.fileItems.find(fi => fi.id === this.props.fileItemId);
    this.setState({ fileItem });
  }

  onNav(next = true) {
    let index = this.fileItems.findIndex(
      fi => fi.id === this.state.fileItem.id
    );
    index = next ? index + 1 : index - 1;
    index =
      index === this.fileItems.length
        ? 0
        : index === -1
        ? this.fileItems.length - 1
        : index;

    this.setState({ fileItem: this.fileItems[index] });
  }

  render() {
    const { src, fileName, size } = this.state.fileItem
      ? getDetails(this.state.fileItem)
      : {};

    return (
      <div className="zoom-modal animate fade-in">
        <div className="img-area animate zoom-in">
          <img src={src} alt={fileName}/>
          {size && <span className="details size">{size}</span>}
          {fileName && <span className="details name">{fileName}</span>}

          <button onClick={() => this.props.onClose()} className="leave">
            <svg viewBox="0 0 24 24">
              <path fill="#fff" d={iconClose} />
            </svg>
          </button>
          {this.fileItems.length > 1 && (
            <button onClick={() => this.onNav(false)} className="prev">
              <svg viewBox="0 0 24 24">
                <path fill="#fff" d={iconLeft} />
              </svg>
            </button>
          )}
          {this.fileItems.length > 1 && (
            <button onClick={() => this.onNav(true)} className="next">
              <svg viewBox="0 0 24 24">
                <path fill="#fff" d={iconRight} />
              </svg>
            </button>
          )}
        </div>
      </div>
    );
  }
}

function getDetails(fileItem) {
  let details = { src: "", fileName: "", size: "", type: "" };

  if (fileItem.file) {
    details.src = URL.createObjectURL(fileItem.file);
    details.fileName = fileItem.file.name;
    details.size =
      fileItem.file.size < 1000000
        ? Math.floor(fileItem.file.size / 1000) + "kb"
        : Math.floor(fileItem.file.size / 1000000) + "mb";
  } else if (fileItem.existingFileUrl) {
    details.src = fileItem.existingFileUrl;
    details.fileName = fileItem.existingFileUrl.substring(
      fileItem.existingFileUrl.lastIndexOf("/") + 1
    );
  }

  if (details.fileName) {
    details.type = details.fileName.split(".").pop();
  }

  return details;
}

function isImage(fileItem) {
  if (fileItem.file) {
    return fileItem.file.type.startsWith("image/");
  } else if (fileItem.existingFileUrl) {
    const ext = fileItem.existingFileUrl
      .split(".")
      .pop()
      .toLowerCase();
    return ["jpg", "jpeg", "png", "gif"].includes(ext);
  }
  return false;
}
