import React, { useState, useCallback } from "react";
import axios from "axios";
import { URLS } from "./../../../constants/urls";
import Toast from "../../../components/Toast/Toast";
import { toastBookingMessage } from "./BookingModal";
import Spinner from "./../../../components/Spinner/Spinner";
import {
  FloatingLabelInput,
  FloatingLabelTextArea,
} from "./../../../components/FloatingLabelFormElements/FloatingLabelFormElements";
import LoaderButton from "./../../../components/LoaderButton/LoaderButton";

export default function Cancellation(props) {
  const [cancellationData, setCancellationData] = useState({
    reason: "",
    returnedFee: 0,
    returnedDeposit: 0,
    returnedFeatureFees: 0,
    totalRefunded: 0,
  });
  const [loading, setLoading] = useState(false);
  const [cancelling, setCancelling] = useState(false);
  const [warning, setWarning] = useState("");

  useState(() => {
    setLoading(true);
    axios
      .get(URLS.BOOKING_ENTRIES.REVIEW_CANCEL + props.booking.id)
      .then(function (response) {
        if (response.data.success === true && response.data.data) {
          const { payment } = response.data.data;
          setCancellationData({
            ...cancellationData,
            returnedFee: payment.returnedFee,
            returnedDeposit: payment.returnedDeposit,
            returnedFeatureFees: payment.returnedFeatureFees,
            totalRefunded: payment.totalRefunded,
          });

          if (response.data.message) {
            setWarning(response.data.message); // warning about cancellation deadline passed
          }
        } else {
          Toast.error(response.data.errors);
        }
      })
      .catch(function (response) {
        Toast.error(response.message, "Error reviewing cancellation");
      })
      .then(function () {
        setLoading(false);
      });
  }, []);

  const onChange = useCallback(
    (e) => {
      const _cancellationData = { ...cancellationData };
      _cancellationData[e.target.name] =
        e.target.name === "reason"
          ? e.target.value
          : parseFloat(e.target.value);

      _cancellationData.totalRefunded =
        (_cancellationData.returnedFee || 0) +
        (_cancellationData.returnedDeposit || 0) +
        (_cancellationData.returnedFeatureFees || 0);

      setCancellationData(_cancellationData);
    },
    [cancellationData]
  );

  function cancel() {
    setCancelling(true);

    axios
      .post(URLS.BOOKING_ENTRIES.CANCEL + props.booking.id, cancellationData)
      .then(function (response) {
        if (response.data.success === true && response.data.data) {
          const booking = response.data.data;
          props.onUpdateBookingEntries(booking);
          toastBookingMessage("Booking cancelled", booking);
          props.onClose();
        } else {
          Toast.error(response.data.errors);
        }
      })
      .catch(function (response) {
        Toast.error(response.message, "Error cancelling booking");
      })
      .then(function () {
        setCancelling(false);
      });
  }

  function render() {
    const { payment } = props.booking;
    if (loading) {
      return <Spinner />;
    }
    return (
      <div className="bg-light mx-n3 my-n3 p-3">
        <div className="row">
          {warning && (
            <div className="col-md-12">
              <div className="alert alert-warning" role="alert">
                {warning}
              </div>
            </div>
          )}

          <div className="col-md-12">
            <h6>Amounts paid</h6>
          </div>
          <div className="col-md-2">
            <FloatingLabelInput
              name="fee"
              value={payment.fee}
              placeholder="Fee"
              disabled={true}
              type="number"
            />
          </div>
          <div className="col-md-2">
            <FloatingLabelInput
              name="deposit"
              value={payment.deposit || 0}
              placeholder="Deposit"
              disabled={true}
              type="number"
            />
          </div>
          <div className="col-md-3">
            <FloatingLabelInput
              name="featurefees"
              value={payment.featureFees || 0}
              placeholder="Feature fees"
              disabled={true}
              type="number"
            />
          </div>
          <div className="col-md-2">
            <FloatingLabelInput
              name="discount"
              value={payment.discount || 0}
              disabled={true}
              placeholder="Discount"
              type="number"
            />
          </div>
          <div className="col-md-3">
            <FloatingLabelInput
              name="totalPaid"
              value={payment.totalPaid}
              disabled={true}
              placeholder="Total paid"
              type="number"
            />
          </div>
          <div className="col-12 border-top pt-3 mt-1">
            <h6>Amounts to return</h6>
          </div>
          <div className="col-md-2">
            <FloatingLabelInput
              name="returnedFee"
              value={cancellationData.returnedFee}
              placeholder="Fee"
              onChange={onChange}
              type="number"
            />
          </div>
          <div className="col-md-2">
            <FloatingLabelInput
              name="returnedDeposit"
              value={cancellationData.returnedDeposit || 0}
              placeholder="Deposit"
              onChange={onChange}
              type="number"
            />
          </div>
          <div className="col-md-3">
            <FloatingLabelInput
              name="returnedFeatureFees"
              value={cancellationData.returnedFeatureFees || 0}
              placeholder="Feature fees"
              onChange={onChange}
              type="number"
            />
          </div>
          <div className="offset-md-2 col-md-3">
            <FloatingLabelInput
              name="totalRefunded"
              disabled={true}
              value={cancellationData.totalRefunded}
              placeholder="Total refund"
              type="number"
            />
          </div>
          <div className="col-12 mt-2">
            <FloatingLabelTextArea
              name="reason"
              placeholder="Cancellation reason"
              onChange={onChange}
              value={cancellationData.reason}
              type="text"
            />
          </div>
          <div className="col-12">
            <LoaderButton
              onClick={() => cancel()}
              className="btn btn-outline-primary float-right"
              loading={cancelling}
              loadingText="Cancelling..."
              disabled={cancellationData.reason.trim().length === 0}
            >
              Cancel Booking
            </LoaderButton>
          </div>
        </div>
      </div>
    );
  }
  return render();
}
