import React from "react";
import { Link } from "react-router-dom";
import RentablesGrid from './RentablesGrid';

export default class ActiveRentables extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      rentables: [],
    };
  }

  componentDidMount() {
    this.setState({ rentables: this.props.rentables });
  }

  render() {
    return (
      <div>
        <div className="row">
          <div className="col-md-9 mb-md-2">
            {`Your organization has ${this.state.rentables.length} active rentables`}
          </div>
          <div className="col-md-3 mb-3">
            {this.props.currentUser.isAdmin && (
              <Link
                to="/App/Rentables/Add"
                className="btn btn-primary float-md-right btn-xs-block mt-2 mt-md-0"
              >
                Add Rentable
              </Link>
            )}
          </div>
        </div>
        <RentablesGrid rentables={this.state.rentables} />
      </div>
    );
  }
}
