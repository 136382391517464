import React, { useMemo, useCallback } from "react";
import moment from "moment";
import "./DatePicker.css";
import DropDown from "./../DropDown/DropDown";

/*
<DatePicker 
  onDatesSelect={this.onDateSelected}     // will pass list of selected dates if multiDaySelect is on, otherwise single date as param(s) in js date object format
  selectedDates={this.state.dates}        // pass array of js date objects if multiDaySelect is on, eitherwise pass single js date object
  multiDaySelect={true}                   // default false, only 1 day is allowed
  label="Start date"                      // default "Select date"
  minDate={moment("1999 12 12").toDate()} // earliest selectable date
  maxDate={new Date()}                    // latest selectable date
/>
*/
export default function DatePicker(props) {
  const onDatesSelect = useCallback(
    (dates) => {
      props.onDatesSelect(props.multiDaySelect === true ? dates : dates[0]);
    },
    [props]
  );

  const selectedDates = useMemo(() => {
    return !props.selectedDates
      ? []
      : props.multiDaySelect === true
      ? props.selectedDates
      : [props.selectedDates];
  }, [props.multiDaySelect, props.selectedDates]);

  const calendar = useMemo(() => {
    return (
      <Calendar
        selectedDates={selectedDates}
        onDatesSelect={onDatesSelect}
        multiDaySelect={props.multiDaySelect}
        minDate={props.minDate}
        maxDate={props.maxDate}
      />
    );
  }, [
    onDatesSelect,
    props.maxDate,
    props.minDate,
    props.multiDaySelect,
    selectedDates,
  ]);

  function render() {
    return (
      <DropDown
        label={props.label || "Select date"}
        text={
          !selectedDates || selectedDates.length === 0
            ? "No date selected"
            : selectedDates.length === 1
            ? moment(selectedDates[0]).format("DD MMM YYYY")
            : "Multiple dates selected"
        }
        className="mb-3 form-label-group"
        btnClass="form-control overflow-hidden"
        contentClass="p-1"
        closeOnContentClick={false}
      >
        {calendar}
      </DropDown>
    );
  }
  return render();
}

/*
<Calendar
  onDatesSelect={this.onDateSelected}     // will pass list of selected dates as param(s) in js date object format
  selectedDates={[this.state.dates]}      // array of js date objects
  multiDaySelect={true}                   // default only 1 day is allowed
  disabledDates=[date, date2]             // js date
  minDate={moment("1999 12 12").toDate()} // earliest selectable date
  maxDate={new Date()}                    // latest selectable date
  minYear={1992}                          // default 2015 if minDate is not present
  maxYear={2025}                          // default now+5 years if maxDate is not present
/>
*/
class Calendar extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      currentCalendarDate:
        this.props.selectedDates && this.props.selectedDates[0]
          ? moment(this.props.selectedDates[0])
          : moment(),
    };

    this.now = moment();
    this.weekdaysShort = moment.weekdaysShort();
    this.months = moment.months();
    this.years = this.getYears();
    this.minYear = this.years[0];
    this.maxYear = this.years[this.years.length - 1];

    this.onMonthOrYearChange = this.onMonthOrYearChange.bind(this);
    this.onMonthOrYearSelect = this.onMonthOrYearSelect.bind(this);
    this.onDaySelect = this.onDaySelect.bind(this);
  }

  getYears() {
    let start = this.props.minDate
      ? moment(this.props.minDate).get("year")
      : this.props.minYear
      ? parseInt(this.props.minYear)
      : 2015;

    const end = this.props.maxDate
      ? moment(this.props.maxDate).get("year")
      : this.props.maxYear
      ? parseInt(this.props.maxYear)
      : this.now.year() + 5;

    let result = [];
    while (start <= end) result.push(start++);
    return result;
  }

  onMonthOrYearChange(type, next) {
    let currentCalendarDate = moment({ ...this.state.currentCalendarDate });
    if (next) {
      currentCalendarDate.add(1, type);
    } else {
      currentCalendarDate.subtract(1, type);
    }
    this.setState({ currentCalendarDate });
  }

  onMonthOrYearSelect(e, type) {
    let currentCalendarDate = moment({ ...this.state.currentCalendarDate });
    currentCalendarDate.set(type, e.target.value);
    this.setState({ currentCalendarDate });
  }

  onDaySelect(day) {
    let date = null;
    let currentCalendarDate = moment({ ...this.state.currentCalendarDate });
    currentCalendarDate.set("date", day);
    let selectedDates = [...this.props.selectedDates];
    const index = selectedDates.findIndex(
      (d) => d.toDateString() === currentCalendarDate.toDate().toDateString()
    );
    if (index > -1) {
      selectedDates.splice(index, 1);
    } else {
      date = currentCalendarDate.toDate();
      if (this.props.multiDaySelect === true) {
        selectedDates.push(date);
      } else {
        selectedDates = [date];
      }
    }

    this.setState({ currentCalendarDate });

    if (this.props.onDatesSelect) {
      this.props.onDatesSelect(selectedDates);
    }
  }

  isToday(day) {
    let date = moment({ ...this.state.currentCalendarDate });
    date.set("date", day);
    return this.now.toDate().toDateString() === date.toDate().toDateString();
  }

  isSelected(day) {
    let date = moment({ ...this.state.currentCalendarDate });
    date.set("date", day);
    return this.props.selectedDates.some(
      (d) => d.toDateString() === date.toDate().toDateString()
    );
  }

  isDisabled(day) {
    let date = moment({ ...this.state.currentCalendarDate })
      .set("date", day)
      .toDate();
    return (
      (this.props.minDate && date < this.props.minDate) ||
      (this.props.maxDate && date > this.props.maxDate) ||
      (this.props.disabledDates &&
        this.props.disabledDates.some(
          (d) => d.toDateString() === date.toDateString()
        ))
    );
  }

  render() {
    const { currentCalendarDate } = this.state;

    let daysFromPrevMonth = [];
    for (
      let i = 0;
      i < moment(currentCalendarDate).startOf("month").format("d");
      i++
    ) {
      daysFromPrevMonth.push(<td key={"p-" + i} className="muted" />);
    }

    let daysInMonth = [];
    for (let d = 1; d <= currentCalendarDate.daysInMonth(); d++) {
      const isDisabled = this.isDisabled(d);
      daysInMonth.push(
        <td
          key={"m-" + d}
          onClick={() => {
            !isDisabled && this.onDaySelect(d);
          }}
          className={
            (isDisabled ? "disabled" : "") +
            (this.isSelected(d) ? " selected" : "") +
            (this.isToday(d) ? " today" : "")
          }
        >
          {d}
        </td>
      );
    }

    var allSlots = [...daysFromPrevMonth, ...daysInMonth];
    let weeks = [];
    let days = [];
    allSlots.forEach((row, i) => {
      if (i % 7 !== 0) {
        days.push(row);
      } else {
        weeks.push(days);
        days = [];
        days.push(row);
      }
      if (i === allSlots.length - 1) {
        weeks.push(days);
      }
    });

    return (
      <div className="calendar">
        <div className="header">
          <div className="month">
            <button
              className="prev"
              disabled={
                this.minYear === currentCalendarDate.year() &&
                currentCalendarDate.month() === 0
              }
              onClick={() => this.onMonthOrYearChange("month", false)}
            >
              &nbsp;
            </button>
            <select
              value={currentCalendarDate.month()}
              onChange={(e) => this.onMonthOrYearSelect(e, "month")}
            >
              {this.months.map((m, i) => (
                <option key={i} value={i}>
                  {m}
                </option>
              ))}
            </select>
            <button
              className="next"
              disabled={
                this.maxYear === currentCalendarDate.year() &&
                currentCalendarDate.month() === 11
              }
              onClick={() => this.onMonthOrYearChange("month", true)}
            >
              &nbsp;
            </button>
          </div>
          <div className="year">
            <button
              className="prev"
              disabled={this.minYear === currentCalendarDate.year()}
              onClick={() => this.onMonthOrYearChange("year", false)}
            >
              &nbsp;
            </button>
            <select
              value={currentCalendarDate.year()}
              onChange={(e) => this.onMonthOrYearSelect(e, "year")}
            >
              {this.years.map((y, i) => (
                <option key={i} value={y}>
                  {y}
                </option>
              ))}
            </select>
            <button
              className="next"
              disabled={this.maxYear === currentCalendarDate.year()}
              onClick={() => this.onMonthOrYearChange("year", true)}
            >
              &nbsp;
            </button>
          </div>
          <div className="today">
            <button
              onClick={() => {
                this.setState({ currentCalendarDate: this.now });
              }}
              disabled={
                (this.props.minDate && this.now < this.props.minDate) ||
                (this.props.maxDate && this.now > this.props.maxDate)
              }
            >
              Today
            </button>
          </div>
        </div>

        <table>
          <thead>
            <tr>
              {this.weekdaysShort.map((wd, i) => (
                <th key={i}>{wd}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {weeks.map((d, i) => {
              return <tr key={i}>{d}</tr>;
            })}
          </tbody>
        </table>
      </div>
    );
  }
}
