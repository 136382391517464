import React from "react";
import axios from "axios";
import { FloatingLabelInput } from "../../components/FloatingLabelFormElements/FloatingLabelFormElements";
import LoaderButton from "../../components/LoaderButton/LoaderButton";
import Toast from "../../components/Toast/Toast";
import { URLS } from "../../constants/urls";
import { isValidEmail, onFormChange } from "../../helpers/utilities";
import { MainContext } from './../../StateManagement/StateManagement';
import Actions from './../../StateManagement/actions';

export default class EditAccount extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      firstName: "",
      lastName: "",
      email: "",
    };

    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  static contextType = MainContext;

  componentDidMount() {
    const currentUser = this.context.state.currentUser;
    this.setState({
      firstName: currentUser.firstName,
      lastName: currentUser.lastName,
      email: currentUser.email,
    });
  }

  onChange(e) {
    const { name, value } = onFormChange(e);
    this.setState({ [name]: value });
  }

  onSubmit() {
    const root = this;

    if (!isValidEmail(root.state.email)) {
      Toast.error("Email address is not valid");
      return;
    }

    root.setState({ loading: true });

    axios
      .post(URLS.ACCOUNT.UPDATE, {
        firstName: root.state.firstName,
        lastName: root.state.lastName,
        email: root.state.email,
      })
      .then(function (response) {
        if (response.data.success === true && response.data.data) {
          Toast.success("Account updated successfully");
          root.context.dispatch(Actions.setCurrentUser(response.data.data));
        } else {
          Toast.error(response.data.errors);
        }
      })
      .catch(function (response) {
        Toast.error(response.message, "Error updating account");
      })
      .then(function () {
        root.setState({ loading: false });
      });
  }

  render() {
    return (
      <div className="row">
        <fieldset disabled={this.props.disabled} className="col-md-4">
          <FloatingLabelInput
            type="text"
            placeholder="Role"
            value={this.context.state.currentUser.roles.join(", ")}
            disabled={true}
          />
          <FloatingLabelInput
            type="text"
            name="firstName"
            placeholder="First name"
            onChange={this.onChange}
            value={this.state.firstName}
          />
          <FloatingLabelInput
            type="text"
            name="lastName"
            placeholder="Last name"
            onChange={this.onChange}
            value={this.state.lastName}
          />
          <FloatingLabelInput
            type="text"
            name="email"
            placeholder="Email address"
            onChange={this.onChange}
            value={this.state.email}
          />
          <div className="offset-md-8 col-md-4 px-0">
            <LoaderButton
              onClick={this.onSubmit}
              className="btn-primary btn-block"
              loading={this.state.loading}
              loadingText="Loading..."
            >
              Save
            </LoaderButton>
          </div>
        </fieldset>
      </div>
    );
  }
}
