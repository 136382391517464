import React from "react";
import Swal from "sweetalert2";
import axios from "axios";
import CheckBoxCard from "./../../../components/CheckBoxCard/CheckBoxCard";
import Radio from "./../../../components/Radio/Radio";
import Spinner from "./../../../components/Spinner/Spinner";
import { onFormChange } from "./../../../helpers/utilities";
import { URLS } from "./../../../constants/urls";
import Toast from "../../../components/Toast/Toast";

export default class Features extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      displayAll: true,
      loading: false,
    };

    this.onFilter = this.onFilter.bind(this);
    this.onDetailsClick = this.onDetailsClick.bind(this);
  }

  componentDidMount() {
    if (
      this.props.rentable.typeId &&
      (!this.props.featureOptions || this.props.featureOptions.length === 0)
    ) {
      const root = this;
      root.setState({ loading: true });
      axios
        .get(
          URLS.RENTABLE_TYPES_FEATURES.FEATURES_BY_TYPE +
            this.props.rentable.typeId
        )
        .then(function (response) {
          if (
            response.data.success === true &&
            response.data.data &&
            response.data.data.length > 0
          ) {
            root.props.onUpdateFeatureOptions(response.data.data);
          }
        })
        .catch(function (response) {
          Toast.error(response.message, "Error getting features");
        })
        .then(function () {
          root.setState({ loading: false });
        });
    }
  }

  onFilter(e) {
    const { value } = onFormChange(e);
    this.setState({ displayAll: value });
  }

  onDetailsClick(featureId, type) {
    const root = this;
    let rentable = { ...root.props.rentable };
    let feature = rentable.features.find(
      (a) => a.featureId === featureId && !a.isDeleted
    );
    if (feature) {
      if (type === "fee") {
        Swal.fire({
          title: "Fee amount",
          text: "Leave blank if free",
          input: "number",
          inputValue: feature.fee || "",
          showCancelButton: true,
          inputValidator: (value) => {
            if (value && isNaN(value)) {
              return "Invalid numeric value";
            }
          },
        }).then((response) => {
          if (!response.dismiss) {
            feature.fee = response.value;
            root.props.onUpdateRentable(rentable);
          }
        });
      } else if (type === "description") {
        Swal.fire({
          title: "Enter description",
          text: "Enter special notes or additional information if needed",
          input: "textarea",
          inputValue: feature.description || "",
          showCancelButton: true,
          inputValidator: (value) => {
            if (value && value.length > 150) {
              return `Max 150 characters allowed. You have used ${value.length}`;
            }
          },
        }).then((response) => {
          if (!response.dismiss) {
            feature.description = response.value;
            root.props.onUpdateRentable(rentable);
          }
        });
      }
    }
  }

  render() {
    const rentableType = this.props.rentableTypeOptions.find(
      (t) => t.value === parseInt(this.props.rentable.typeId)
    );

    return this.state.loading ? (
      <Spinner />
    ) : (
      <div className="row">
        <div className="col-md-7">
          {rentableType && (
            <p>
              Displaying features associated to rentable type
              <strong className="ml-1">{rentableType.name}</strong>.<br />
              Free features are automatically added to bookings, others can be
              optionally added during booking creation.
            </p>
          )}
        </div>
        <div className="col-md-5 mb-4 text-md-right">
          <Radio
            label="All features"
            name="displayAll"
            className="d-inline-block"
            checked={this.state.displayAll}
            value={true}
            onChange={this.onFilter}
          />
          <Radio
            label="Selected features"
            name="displayAll"
            className="d-inline-block ml-4"
            checked={!this.state.displayAll}
            value={false}
            onChange={this.onFilter}
          />
        </div>

        {this.props.featureOptions.map((fo) => {
          let feature = this.props.rentable.features.find(
            (a) => a.featureId === fo.id
          );
          feature = feature ? { ...feature } : { isDeleted: true };
          const checked = !feature.isDeleted;
          if (this.state.displayAll || checked) {
            return (
              <div key={fo.id} className="col-md-2 mb-4">
                <CheckBoxCard
                  disabled={this.props.isDisabled}
                  label={fo.name}
                  checked={checked}
                  value={fo.id}
                  name="features"
                  onChange={this.props.onChange}
                  onLeftClick={() => this.onDetailsClick(fo.id, "description")}
                  onRightClick={() => this.onDetailsClick(fo.id, "fee")}
                  leftText={feature.description || "Description"}
                  rightText={feature.fee ? "$" + feature.fee : "Free"}
                />
              </div>
            );
          }
          return null;
        })}
      </div>
    );
  }
}
