import React from "react";
import "./CheckBoxCard.scss";

/* 
<CheckBoxCard
  label="Wifi"
  checked={item.checked}
  value={item.id}
  name="items[]"
  onChange={this.props.onChange}
  onLeftClick={this.props.onLeftClick}
  onRightClick={this.props.onRightClick}
  leftText="Description"
  rightText="Fee"
  disabled={this.state.disabled}
  size="sm"  // sm or md (default md)
/> 
*/
export default function CheckBoxCard(props) {
    const leftText =
      props.leftText && props.leftText.toString().length > 12
        ? props.leftText.toString().substring(0, 12) + "..."
        : props.leftText;
    const rightText =
      props.rightText && props.rightText.toString().length > 12
        ? props.rightText.toString().substring(0, 12) + "..."
        : props.rightText;
    const isSmall = props.size === "sm";
  
    return (
      <div
        className={
          "border rounded shadow-sm overflow-hidden checkbox-card" +
          (isSmall ? " sm" : "") + (props.disabled ? " disabled" : "")
        }
      >
        <label>
          <input
            type="checkbox"
            name={props.name}
            checked={props.checked}
            value={props.value}
            onChange={props.onChange}
            disabled={props.disabled}
          />
          <div className="left">
            <svg viewBox={isSmall ? "-7 -6 36 36" : "0 0 24 24"}>
              <path
                fill={props.checked ? "#199292" : "#d4d4d4"}
                d="M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z"
              />
            </svg>
          </div>
          <div className="right">
            <span>{props.label}</span>
          </div>
        </label>
        <div className="bg-white d-inline-block w-100 border-top">
          <button
            className="btn btn-link btn-sm text-muted float-left"
            disabled={!props.checked || props.disabled}
            onClick={props.onLeftClick}
            title={props.leftText}
          >
            {leftText}
          </button>
          <button
            className="btn btn-link btn-sm text-muted float-right"
            disabled={!props.checked || props.disabled || !props.onRightClick}
            onClick={props.onRightClick}
            title={props.rightText}
          >
            {rightText}
          </button>
        </div>
      </div>
    );
  }
  