import React, { useState } from "react";
import axios from "axios";
import Swal from "sweetalert2";
import { Navigate } from "react-router-dom";
import LoaderButton from "../../../components/LoaderButton/LoaderButton";
import Toast from "../../../components/Toast/Toast";
import { URLS } from "./../../../constants/urls";
import useStateManagement from "./../../../StateManagement/StateManagement";
import Actions from './../../../StateManagement/actions';

export default function Advanced(props) {
  const [loading, setLoading] = useState(false);
  const [done, setDone] = useState(false);
  const { state, dispatch } = useStateManagement();

  function onDeleteOrRestore(restore) {
    Swal.fire({
      title: "Are you sure?",
      type: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes",
    }).then((result) => {
      if (result.value) {
        setLoading(true);
        axios
          .post(
            (restore ? URLS.RENTABLES.RESTORE : URLS.RENTABLES.DELETE) +
              props.rentable.id
          )
          .then(function (response) {
            if (response.data.success) {
              Swal.fire("Done", response.data.message, "success").then(() => {
                setDone(true);
              });

              const rentables = state.rentables;
              const setRentables = (data) =>
                dispatch(Actions.setRentables(data));

              if (
                restore ||
                (!restore &&
                  props.rentable.bookingEntries &&
                  props.rentable.bookingEntries.length > 0)
              ) {
                setRentables(
                  rentables.data.map((r) => {
                    if (r.id === props.rentable.id) {
                      r.isDeleted = restore ? false : true;
                    }
                    return r;
                  })
                );
              } else {
                setRentables(
                  rentables.data.filter((r) => r.id !== props.rentable.id)
                );
              }
            } else if (response.data.errors) {
              Toast.error(response.data.errors);
            }
          })
          .catch(function (response) {
            Toast.error(response.message, "Error processing rentable");
          })
          .then(function () {
            setLoading(false);
          });
      }
    });
  }

  function render() {
    if (done) {
      return <Navigate to="/App/Rentables" />;
    } else if (props.rentable.isDeleted) {
      return (
        <div>
          <p>
            <strong className="pr-1">Restoring rentable:</strong>
            Rentable will be available to be to booked again.
          </p>
          <LoaderButton
            onClick={() => onDeleteOrRestore(true)}
            className="btn btn-outline-warning btn-sm"
            loading={loading}
            loadingText="Restoring..."
          >
            Restore Rentable
          </LoaderButton>
        </div>
      );
    } else {
      return (
        <div>
          <p>
            <strong className="pr-1">Deleting rentable:</strong>
            If there is any upcoming active bookings associated with this
            rentable then you will not be able to delete it. If there is no
            booking associated with this rentable then it will be deleted
            permanently, otherwise it will be archived and it will be possible
            to restore it back.
          </p>
          <LoaderButton
            disabled={props.disabled}
            onClick={() => onDeleteOrRestore(false)}
            className="btn btn-outline-danger btn-sm"
            loading={loading}
            loadingText="Deleting..."
          >
            Delete Rentable
          </LoaderButton>
        </div>
      );
    }
  }
  return render();
}
