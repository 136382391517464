import React from "react";
import axios from "axios";
import { BOOKING_DURATION_UNIT } from "../../../constants/enums";
import { URLS } from "./../../../constants/urls";
import Toast from "../../../components/Toast/Toast";
import {
  FloatingLabelInput,
  FloatingLabelSelect,
  FloatingLabelTextArea,
} from "./../../../components/FloatingLabelFormElements/FloatingLabelFormElements";
import Spinner from "./../../../components/Spinner/Spinner";

export default class General extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
    };
  }

  componentDidMount() {
    if (
      !this.props.rentableTypeOptions ||
      this.props.rentableTypeOptions.length === 0
    ) {
      const root = this;
      root.setState({ loading: true });
      axios
        .get(URLS.RENTABLE_TYPES_FEATURES.TYPES)
        .then(function (response) {
          if (
            response.data.success === true &&
            response.data.data &&
            response.data.data.length > 0
          ) {
            const rentableTypes = response.data.data.map((x) => ({
              name: x.name,
              value: x.id,
            }));
            root.props.onUpdateRentableTypesOptions(rentableTypes);
          }
        })
        .catch(function (response) {
          Toast.error(response.message, "Error getting types");
        })
        .then(function () {
          root.setState({ loading: false });
        });
    }
  }

  render() {
    return this.state.loading ? (
      <Spinner />
    ) : (
      <fieldset disabled={this.props.isDisabled}>
        <div className="row">
          <div className="col-md-6">
            <FloatingLabelInput
              type="text"
              name="name"
              placeholder="Name"
              onChange={this.props.onChange}
              value={this.props.rentable.name}
              required={true}
            />
          </div>

          <div className="col-md-3">
            <FloatingLabelSelect
              name="typeId"
              placeholder="Type"
              onChange={this.props.onChange}
              value={this.props.rentable.typeId}
              required={true}
            >
              {this.props.rentableTypeOptions.map(({ name, value }) => (
                <option key={value} value={value}>
                  {name}
                </option>
              ))}
            </FloatingLabelSelect>
          </div>

          <div className="col-md-3">
            <FloatingLabelSelect
              name="bookingDurationUnit"
              placeholder="Booking duration unit"
              onChange={this.props.onChange}
              value={this.props.rentable.bookingDurationUnit}
              required={true}
            >
              {Object.values(BOOKING_DURATION_UNIT).map(({ name, value }) => (
                <option key={value} value={value}>
                  {name}
                </option>
              ))}
            </FloatingLabelSelect>
          </div>

          <div className="col-md-6">
            <FloatingLabelTextArea
              name="description"
              placeholder="Description"
              onChange={this.props.onChange}
              value={this.props.rentable.description}
              help="General information about this rentable that could be available to public"
            />
          </div>

          <div className="col-md-6">
            <FloatingLabelTextArea
              name="secretDetails"
              placeholder="Secret details / Instructions"
              onChange={this.props.onChange}
              value={this.props.rentable.secretDetails}
              help="Sensitive information or special instructions that will be sent to client after a booking is created. For example: wifi password or instructions to set up audio system"
            />
          </div>
        </div>
      </fieldset>
    );
  }
}
