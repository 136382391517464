export const ENVIROMENTS = {
  local: "local",
  development: "development",
  test: "test",
  production: "production",
};

const baseConfig = {
  rollbarAccessToken: "efe8589f060b46269c0ffe43f9b7edc8",
};

const envConfigs = {
  [ENVIROMENTS.local]: {
    appHostnames: ["localhost"],
    baseApiUrl: "https://localhost:5001",
  },
  [ENVIROMENTS.development]: {
    appHostnames: ["dev-portal.centrel.app"],
    baseApiUrl: "https://centrel-api-dev.azurewebsites.net",
  },
  [ENVIROMENTS.test]: {
    appHostnames: ["test-portal.centrel.app"],
    baseApiUrl: "https://test-api.centrel.app",
  },
  [ENVIROMENTS.production]: {
    appHostnames: ["portal.centrel.app"],
    baseApiUrl: "https://api.centrel.app",
  },
};

export class Environment {
  static value = Object.keys(envConfigs).find((env) =>
    envConfigs[env].appHostnames.includes(window.location.hostname)
  );

  static isLocal() {
    return this.value === ENVIROMENTS.local;
  }
  static isDevelopment() {
    return this.value === ENVIROMENTS.development;
  }
  static isTest() {
    return this.value === ENVIROMENTS.test;
  }
  static isProduction() {
    return this.value === ENVIROMENTS.production;
  }
}

const CONFIG = { ...baseConfig, ...envConfigs[Environment.value] };
export default CONFIG;
