import React from "react";
import RentablesGrid from './RentablesGrid';

export default class DeletedRentables extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      rentables: [],
    };
  }

  componentDidMount() {
    this.setState({ rentables: this.props.rentables });
  }

  render() {
    return (
      <div>
        <div className="row">
          <div className="col-md-12 mb-4">
            {`Your organization has ${this.state.rentables.length} archived rentables. `}
            Archived rentables are the ones that have at least one booking
            associated with them.
          </div>
        </div>
        <RentablesGrid rentables={this.state.rentables} />
      </div>
    );
  }
}
