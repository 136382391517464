import React, { useState, useEffect } from "react";
import BetterSelect from "./../BetterSelect/BetterSelect";

/*
  <Paging
    numberOfPages={numberOfPages}
    page={searchModel.page}
    pageSize={searchModel.pageSize}
    totalItemsCount={searchModel.totalItemsCount}
    onChange={onPagingChange}
    className="float-right"
  />
*/

const sizeOptions = [10, 25, 50, 100, 250];

export default function Paging(props) {
  const [pageNumbers, setPageNumbers] = useState([]);

  useEffect(() => {
    const _pageNumbers = [];
    if (props.numberOfPages > 1) {
      if (props.numberOfPages <= 7) {
        for (let n = 1; n <= props.numberOfPages; n++) {
          _pageNumbers.push(n);
        }
      } else {
        // first page
        _pageNumbers.push(1);
        // 3 previous pages
        if (props.page > 1) {
          for (let n = props.page - 3; n < props.page; n++) {
            if (n > 1) {
              _pageNumbers.push(n);
            }
          }
        }
        // page itself
        if (_pageNumbers[_pageNumbers.length - 1] !== props.page) {
          _pageNumbers.push(props.page);
        }
        // 3 next pages
        if (props.page < props.numberOfPages) {
          for (
            let n = props.page + 1;
            n <= props.page + 3 && n < props.numberOfPages;
            n++
          ) {
            _pageNumbers.push(n);
          }
        }
        // last page
        if (_pageNumbers[_pageNumbers.length - 1] !== props.numberOfPages) {
          _pageNumbers.push(props.numberOfPages);
        }
      }
    }
    setPageNumbers(_pageNumbers);
  }, [props.numberOfPages, props.page]);

  function render() {
    return (
      <nav className={props.className || ""}>
        <ul className="pagination d-inline-flex m-0">
          {!(
            (props.totalItemsCount && props.totalItemsCount <= sizeOptions[0]) // hide single page with minimum page size
          ) && (
            <li
              className="page-item"
              title="Number of rows to display in a page"
            >
              <BetterSelect
                text={props.pageSize}
                value={props.pageSize}
                className="page-link rounded-right p-2"
                onChange={(e) => {
                  props.onChange({
                    page: 1,
                    pageSize: parseInt(e.target.value),
                  });
                }}
              >
                {sizeOptions.map((s) => (
                  <option key={s} value={s}>
                    {s}
                  </option>
                ))}
              </BetterSelect>
            </li>
          )}
          {props.numberOfPages > 1 &&
            pageNumbers.map((p, index) => {
              const current = p === props.page;
              const first = index === 0;
              const last = index === pageNumbers.length - 1;
              return (
                <li
                  key={p}
                  className={"page-item" + (current ? " active" : "")}
                  style={{ marginLeft: index === 0 ? "2rem" : "" }}
                  title="Page numbers to navigate"
                >
                  <a
                    onClick={() => {
                      props.onChange({ pageSize: props.pageSize, page: p });
                    }}
                    href="##"
                    className={
                      "page-link" +
                      (first ? " rounded-left" : "") +
                      ((first || last) && !current
                        ? " bg-light text-black-50"
                        : "")
                    }
                  >
                    {p}
                  </a>
                </li>
              );
            })}
        </ul>
      </nav>
    );
  }
  return render();
}
