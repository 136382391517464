import React, { useState, useEffect, useCallback } from "react";
import axios from "axios";
import { Link, NavLink } from "react-router-dom";
import Icon from "@mdi/react";
import {
  mdiMenu,
  mdiClose,
  mdiHome,
  mdiChartAreaspline,
  mdiDomain,
  mdiAccountCircleOutline,
  mdiCalendarMultipleCheck,
  mdiCalendar,
} from "@mdi/js";
import { Toasts } from "../../../components/Toast/Toast";
import DropDown from "../../../components/DropDown/DropDown";
import logo from "../../../assets/img/logo.svg";
import LoggedOut from "../LoggedOut";
import { URLS } from "./../../../constants/urls";
import Auth from "../../../helpers/auth";
import "./Layout.scss";
import { SESSION_STORAGE_KEYS } from "../../../constants/enums";
import moment from "moment";
import Storage from "../../../helpers/storage";
import useStateManagement from "./../../../StateManagement/StateManagement";
import { Outlet } from "react-router-dom";

/*
<Layout>
  <Route />
</Layout>
*/
export default function Layout() {
  const [signedOut, setSignedOut] = useState(false);
  const { state } = useStateManagement();

  function signOut() {
    axios.post(URLS.ACCOUNT.SIGN_OUT).then(function (response) {
      if (response.data.success) {
        Auth.signOut();
        setSignedOut(true);
      }
    });
  }

  function render() {
    if (signedOut) {
      return <LoggedOut />;
    }

    return (
      <div className="app">
        <Header currentUser={state.currentUser} onSignOut={signOut} />
        <Toasts />
        <Outlet />
        <Footer />
      </div>
    );
  }
  return render();
}

function Header(props) {
  const [showMobileMenu, setShowMobileMenu] = useState(false);
  return (
    <header className="d-flex shadow-sm">
      <nav className="navbar navbar-expand-md navbar-dark col-sm-12 py-0 px-0">
        <button
          onClick={() => setShowMobileMenu((smm) => !smm)}
          className="navbar-toggler collapsed ml-2 mt-1 border-0"
          type="button"
          data-toggle="collapse"
          data-target="#menu"
          aria-controls="menu"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <Icon
            path={showMobileMenu ? mdiClose : mdiMenu}
            size={1.2}
            color="currentColor"
            className="mdi"
          />
        </button>

        <Link
          className="navbar-brand ml-md-4 mr-md-5 mx-sm-auto"
          to="/App/Dashboard"
        >
          <img
            src={logo}
            width="30"
            height="30"
            className="d-inline-block align-top mr-2"
            alt="Logo"
          />
          Centrel Portal
        </Link>

        <div className="dropdown ml-md-5 order-sm-1">
          <DropDown
            btnClass="nav-link py-3"
            contentClass="px-0 py-2 profile-content"
            closeOnContentClick={false}
            text={
              <>
                <span className="d-none d-md-inline-block">
                  {`${props.currentUser.firstName} ${props.currentUser.lastName}`}
                </span>
                <Icon
                  path={mdiAccountCircleOutline}
                  size={1}
                  color="currentColor"
                  className="mdi ml-2"
                />
              </>
            }
          >
            <Link to="/App/Account" className="dropdown-item">
              Account Settings
            </Link>
            <div className="dropdown-divider" />
            <button className="dropdown-item" onClick={() => props.onSignOut()}>
              Sign Out
            </button>
          </DropDown>
        </div>

        <div
          className={
            "navbar-collapse collapse" + (showMobileMenu ? " show" : "")
          }
          id="menu"
        >
          <ul className="navbar-nav ml-auto">
            <li className="nav-item">
              <NavLink to="/App/Dashboard" className="nav-link py-3">
                <Icon
                  path={mdiChartAreaspline}
                  size={0.8}
                  color="currentColor"
                  className="mdi mr-2"
                />
                Dashboard
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink to="/App/Bookings" className="nav-link py-3">
                <Icon
                  path={mdiCalendarMultipleCheck}
                  size={0.8}
                  color="currentColor"
                  className="mdi mr-2"
                />
                Bookings
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink to="/App/Bookings/Calendar" className="nav-link py-3">
                <Icon
                  path={mdiCalendar}
                  size={0.8}
                  color="currentColor"
                  className="mdi mr-2"
                />
                Calendar
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink to="/App/Rentables" className="nav-link py-3">
                <Icon
                  path={mdiHome}
                  size={0.8}
                  color="currentColor"
                  className="mdi mr-2"
                />
                Rentables
              </NavLink>
            </li>
            <li className="nav-item">
              <DropDown
                closeOnContentClick={false}
                btnClass="nav-link py-3"
                contentClass="px-0 py-2"
                text={
                  <>
                    <Icon
                      path={mdiDomain}
                      size={0.8}
                      color="currentColor"
                      className="mdi mr-2"
                    />
                    Organization
                  </>
                }
              >
                <Link to="/App/Organization/Users" className="dropdown-item">
                  User Management
                </Link>
                <Link to="/App/Organization/Settings" className="dropdown-item">
                  Organization Settings
                </Link>
              </DropDown>
            </li>
          </ul>
        </div>
      </nav>
    </header>
  );
}

function Footer() {
  const [version, setVersion] = useState("");
  const [loading, setLoading] = useState(false);

  const getVersion = useCallback(() => {
    if (loading) return;

    setLoading(true);
    axios
      .get(URLS.SETTINGS.APP_VERSION)
      .then(function (response) {
        if (response.data.success === true && response.data.data) {
          setVersion(response.data.data);
          Storage.setInSession(SESSION_STORAGE_KEYS.appVersion, {
            version: response.data.data,
            date: new Date(),
          });
        }
      })
      .catch(function () {
        setVersion("Error");
      })
      .then(function () {
        setLoading(false);
      });
  }, [loading]);

  useEffect(() => {
    const cachedData = Storage.getFromSession(SESSION_STORAGE_KEYS.appVersion);

    if (cachedData) {
      const hourDiff = moment(cachedData.date).diff(moment(), "hours");
      if (hourDiff <= 1) {
        // have cached data from less 1 hour ago
        setVersion(cachedData.version);
      } else {
        getVersion();
      }
    } else {
      getVersion();
    }
  }, [getVersion]);

  return (
    <footer className="px-md-4 px-3 text-center">
      <div className="row py-2">
        <div className="col-6 col-md text-md-left">
          <a
            href="##"
            onClick={getVersion}
            className={loading ? "disabled" : ""}
          >
            <small>API App Version: {version}</small>
            <small className="d-block">
              App Version: {process.env.REACT_APP_VERSION}
            </small>
          </a>
        </div>
        <div className="col-6 col-md">
          <span>403 888 1234</span>
        </div>
        <div className="col-6 col-md">
          <span>info@centrel.app</span>
        </div>
        <div className="col-6 col-md text-md-right">
          <span>&copy; 2021</span>
        </div>
      </div>
    </footer>
  );
}
