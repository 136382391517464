import React from "react";
import { onFormChange } from "./../../../helpers/utilities";
import { BOOKING_STATUS } from "../../../constants/enums";
import { FloatingLabelInput } from "./../../../components/FloatingLabelFormElements/FloatingLabelFormElements";

export default function Fees(props) {
  function onChange(e) {
    let { name, value } = onFormChange(e);
    const booking = { ...props.booking };

    value = !value || isNaN(value) ? 0 : parseFloat(value);
    if (name === "discount" && value < 0) {
      value = 0;
    }
    booking.payment[name] = value;

    props.updateBooking(booking);
  }

  function render() {
    const { payment } = props.booking;
    const { rentable, rentableDailyBookable } = props;
    const isCancelled = props.booking.status === BOOKING_STATUS.cancelled.value;
    const isDraft = props.booking.status === BOOKING_STATUS.draft.value;
    const isDraftOrNew = isDraft || props.isNewBooking;
    return (
      <div className="row">
        <div className={isDraftOrNew ? "col-md-3" : "col-md-4"}>
          <p className="mb-2">
            <small className="text-secondary d-block">
              Fee (per {rentableDailyBookable ? "day" : "hour"})
            </small>
            ${rentable.fee}
          </p>
        </div>
        <div className={isDraftOrNew ? "col-md-3" : "col-md-4"}>
          <p className="mb-2">
            <small className="text-secondary d-block">
              Deposit (per booking)
            </small>
            ${rentable.deposit || 0}
          </p>
        </div>
        <div className="col-md-4">
          <p className="mb-2">
            <small className="text-secondary d-block">
              Feature fees (per booking)
            </small>
            ${payment.featureFees || 0}
          </p>
        </div>
        {isDraftOrNew && (
          <div className="col-md-2">
            <FloatingLabelInput
              name="discount"
              value={payment.discount || 0}
              placeholder="Discount ($)"
              disabled={isCancelled}
              onChange={onChange}
              type="number"
            />
          </div>
        )}

        {!isDraftOrNew && (
          <>
            <div className="col-12 border-top pt-2 mt-1">
              <h6>Amounts paid</h6>
            </div>

            <div className="col-md-2">
              <FloatingLabelInput
                name="fee"
                value={payment.fee}
                placeholder="Fee"
                disabled={true}
                type="number"
              />
            </div>
            <div className="col-md-2">
              <FloatingLabelInput
                name="deposit"
                value={payment.deposit}
                placeholder="Deposit"
                disabled={true}
                type="number"
              />
            </div>
            <div className="col-md-3">
              <FloatingLabelInput
                name="featurefees"
                value={payment.featureFees || 0}
                placeholder="Feature fees"
                disabled={true}
                type="number"
              />
            </div>
            <div className="col-md-2">
              <FloatingLabelInput
                name="discount"
                value={payment.discount || 0}
                placeholder="Discount ($)"
                disabled={isCancelled}
                onChange={onChange}
                type="number"
              />
            </div>
            <div className="col-md-3">
              <FloatingLabelInput
                name="totalPaid"
                value={payment.totalPaid}
                disabled={true}
                placeholder="Total paid"
                type="number"
              />
            </div>
          </>
        )}

        {isCancelled && (
          <>
            <div className="col-12 border-top pt-2 mt-1">
              <h6>Amounts returned</h6>
            </div>
            <div className="col-md-2">
              <FloatingLabelInput
                name="returnedFee"
                value={payment.returnedFee}
                placeholder="Fee"
                disabled={true}
                type="number"
              />
            </div>
            <div className="col-md-2">
              <FloatingLabelInput
                name="returnedDeposit"
                value={payment.returnedDeposit}
                placeholder="Deposit"
                disabled={true}
                type="number"
              />
            </div>
            <div className="col-md-3">
              <FloatingLabelInput
                name="returnedFeatureFees"
                value={payment.returnedFeatureFees || 0}
                placeholder="Feature fees"
                disabled={true}
                type="number"
              />
            </div>
            <div className="offset-md-2 col-md-3">
              <FloatingLabelInput
                name="totalRefunded"
                disabled={true}
                value={payment.totalRefunded}
                placeholder="Total refund"
                type="number"
              />
            </div>
          </>
        )}
      </div>
    );
  }
  return render();
}
