import React from "react";
import {
  BOOKING_CANCELLATION_BASE,
  BOOKING_CANCELLATION_UNIT,
  BOOKING_DURATION_UNIT,
} from "./../../../constants/enums";
import {
  FloatingLabelInput,
  FloatingLabelSelect,
} from "./../../../components/FloatingLabelFormElements/FloatingLabelFormElements";

export default class FeesCancellation extends React.Component {
  render() {
    const rentableDailyBookable =
      this.props.rentable.bookingDurationUnit ===
      BOOKING_DURATION_UNIT.day.value;
    return (
      <fieldset disabled={this.props.isDisabled}>
        <div className="row">
          <div className="col-md-3">
            <FloatingLabelInput
              type="number"
              name="fee"
              placeholder={`Fee (per ${
                rentableDailyBookable ? "day" : "hour"
              })`}
              autoComplete={false}
              onChange={this.props.onChange}
              value={this.props.rentable.fee}
            />
          </div>
          <div className="col-md-3">
            <FloatingLabelInput
              type="number"
              name="deposit"
              placeholder="Deposit (per booking)"
              autoComplete={false}
              onChange={this.props.onChange}
              value={this.props.rentable.deposit}
            />
          </div>
          <div className="col-md-12 py-3">
            <strong className="pr-1">Booking cancellation:</strong>
            Bookings that made online can be cancelled online if cancellation is
            allowed and there is still time to do so. Otherwise these settings
            will not block admins or users to cancel a booking, but will display
            a warning message about cancellation deadline.
          </div>
          <div className="col-md-3">
            <FloatingLabelSelect
              name="bookingCancellationAllowed"
              placeholder="Booking cancellation"
              onChange={this.props.onChange}
              value={this.props.rentable.bookingCancellationAllowed}
            >
              <option value={true}>Allowed</option>
              <option value={false}>Not allowed</option>
            </FloatingLabelSelect>
          </div>
          <div className="col-md-3">
            <FloatingLabelInput
              type="number"
              name="bookingCancellationQuantity"
              placeholder="Time frame value"
              autoComplete={false}
              onChange={this.props.onChange}
              value={this.props.rentable.bookingCancellationQuantity}
              disabled={!this.props.rentable.bookingCancellationAllowed}
            />
          </div>
          <div className="col-md-3">
            <FloatingLabelSelect
              name="bookingCancellationUnit"
              placeholder="Time frame unit"
              onChange={this.props.onChange}
              value={this.props.rentable.bookingCancellationUnit}
              disabled={!this.props.rentable.bookingCancellationAllowed}
            >
              {Object.values(BOOKING_CANCELLATION_UNIT).map(
                ({ name, value }) => (
                  <option key={value} value={value}>
                    {name}
                  </option>
                )
              )}
            </FloatingLabelSelect>
          </div>
          <div className="col-md-3">
            <FloatingLabelSelect
              name="bookingCancellationBase"
              placeholder="Time frame base"
              onChange={this.props.onChange}
              value={this.props.rentable.bookingCancellationBase}
              disabled={!this.props.rentable.bookingCancellationAllowed}
            >
              {Object.values(BOOKING_CANCELLATION_BASE).map(
                ({ name, value }) => (
                  <option key={value} value={value}>
                    {name}
                  </option>
                )
              )}
            </FloatingLabelSelect>
          </div>
          <div className="col-md-3">
            <FloatingLabelInput
              type="number"
              name="feeRefund"
              placeholder={`Fee refund (per ${
                rentableDailyBookable ? "day" : "hour"
              })`}
              autoComplete={false}
              onChange={this.props.onChange}
              value={this.props.rentable.feeRefund}
              disabled={!this.props.rentable.bookingCancellationAllowed}
            />
          </div>
          <div className="col-md-3">
            <FloatingLabelInput
              type="number"
              name="depositRefund"
              placeholder="Deposit refund (per booking)"
              autoComplete={false}
              onChange={this.props.onChange}
              value={this.props.rentable.depositRefund}
              disabled={!this.props.rentable.bookingCancellationAllowed}
            />
          </div>
        </div>
      </fieldset>
    );
  }
}
