import React from "react";

/*
<Radio
  label="All features"
  name="displayAll"
  className="d-inline-block ml-4"
  checked={this.state.displayAll}
  value={true}
  onChange={this.onFilter}
  disabled={true}
  required={true}
/>
*/
export default function Radio(props) {
    const id = `radio-${props.name}-${props.value}-${Math.floor(0, 100)}`;
    const required = props.required === true;
    const disabled = props.disabled === true;
    return (
      <div className={"custom-control custom-radio " + (props.className || "")}>
        <input
          type="radio"
          id={id}
          name={props.name}
          className="custom-control-input"
          checked={props.checked}
          value={props.value}
          onChange={props.onChange}
          required={required}
          disabled={disabled}
        />
        <label className="custom-control-label" htmlFor={id}>
          {required && <span>*</span>}
          {props.label}
        </label>
      </div>
    );
  }