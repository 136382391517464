import React, { useState, useEffect, useRef } from "react";
import "./MultiSelect.css";

/*
<MultiSelect
  name="bookingType"
  placeholder="Select type"
  onChange={this.onChange}
  options={[{name: "Test 1", value: 1, checked: true}, {name: "Test 2", value: 2, checked: false}]}
  required={true}
  disabled={false}
  showSelectAll={false}
  showMoreButton={true}
  moreButtonClick={props.fetchExtraRecords}
  moreButtonTexts={["Show archived", "Hide archived"]}
  moreOptions={[{name: "Test 1", value: 1, checked: true}, {name: "Test 2", value: 2, checked: false}]}
/>
*/
export default function MultiSelect(props) {
  const [open, setOpen] = useState(false);
  const contentRef = useRef(null);
  const btnRef = useRef(null);
  const [showMore, setShowMore] = useState(false);

  useEffect(() => {
    document.addEventListener("mousedown", onClickOutside); // Bind the event listener
    return () => {
      document.removeEventListener("mousedown", onClickOutside); // Unbind the event listener on clean up
    };
  }, []);

  function onClickOutside(event) {
    if (
      contentRef.current &&
      !btnRef.current.contains(event.target) &&
      !contentRef.current.contains(event.target)
    ) {
      setOpen(false);
    }
  }

  function showMoreClick() {
    setShowMore((sm) => !sm);
    if (props.moreButtonClick) {
      props.moreButtonClick();
    }
  }

  function render() {
    const selectedOptions = props.options.filter((o) => o.checked);
    const selectedMoreOptions = props.moreOptions
      ? props.moreOptions.filter((o) => o.checked)
      : [];
    const selectedCount = selectedOptions.length + selectedMoreOptions.length;
    const placeholder =
      selectedCount > 0
        ? selectedOptions.map((o) => o.name).join("; ") +
          selectedMoreOptions.map((o) => o.name).join("; ")
        : props.placeholder;
    return (
      <div className="multi-select">
        <div className="form-label-group" title={placeholder}>
          <button
            className="form-control"
            ref={btnRef}
            onClick={() => setOpen(!open)}
            disabled={props.disabled === true}
          >
            {selectedCount > 0 && (
              <span className="count">{selectedCount}</span>
            )}
            {placeholder}
          </button>
          <label>
            {props.required === true && <span>*</span>}
            {props.placeholder}
          </label>
        </div>
        {open && (
          <div ref={contentRef} className="options shadow animate-dropdown">
            {props.options &&
              props.options.length > 0 &&
              (props.showSelectAll && props.options.length > 1
                ? [
                    {
                      name: "Select all",
                      value: "all",
                      checked: props.options.every((o) => o.checked),
                    },
                    ...props.options,
                  ]
                : props.options
              ).map((option, index) => {
                const id = "ms-o-" + index + "-" + option.value;
                return (
                  <label
                    key={index}
                    className="custom-control custom-checkbox option"
                  >
                    <input
                      type="checkbox"
                      className="custom-control-input"
                      id={id}
                      name={props.name}
                      onChange={props.onChange}
                      value={option.value}
                      checked={option.checked}
                    />
                    <label className="custom-control-label" htmlFor={id}>
                      {option.name}
                    </label>
                  </label>
                );
              })}
            {props.showMoreButton && (
              <div className="more">
                <span onClick={showMoreClick}>
                  {showMore
                    ? props.moreButtonTexts[1] || "Show less"
                    : props.moreButtonTexts[0] || "Show more"}
                </span>
              </div>
            )}
            {showMore &&
              (!props.moreOptions || props.moreOptions.length === 0 ? (
                <div className="nothing">Nothing to show</div>
              ) : (
                (props.showSelectAll && props.moreOptions.length > 1
                  ? [
                      {
                        name: "Select all",
                        value: "all-more",
                        checked: props.moreOptions.every((o) => o.checked),
                      },
                      ...props.moreOptions,
                    ]
                  : props.moreOptions
                ).map((option, index) => {
                  const id = "ms-mo-" + index + "-" + option.value;
                  return (
                    <label
                      key={index}
                      className="custom-control custom-checkbox option"
                    >
                      <input
                        type="checkbox"
                        className="custom-control-input"
                        id={id}
                        name={props.name}
                        onChange={props.onChange}
                        value={option.value}
                        checked={option.checked}
                      />
                      <label className="custom-control-label" htmlFor={id}>
                        {option.name}
                      </label>
                    </label>
                  );
                })
              ))}
          </div>
        )}
      </div>
    );
  }
  return render();
}
