import React from "react";
import Page from "../../../components/_Others/Page";
import NavTab from "../../../components/NavTab/NavTab";
import DeletedRentables from './DeletedRentables';
import ActiveRentables from './ActiveRentables';
import { MainContext } from './../../../StateManagement/StateManagement';

export default class Rentables extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
    };

    this.currentUser = {};
    this.activeRentables = [];
    this.deletedRentables = [];
  }

  static contextType = MainContext;

  componentDidMount() {
    this.currentUser = this.context.state.currentUser;
    this.activeRentables = this.context.state.rentables.getDeleted(false);
    this.deletedRentables = this.context.state.rentables.getDeleted(true);
    this.setState({ loading: false });
  }

  render() {
    return (
      <Page title="Rentables" loading={this.state.loading} type="tabs">
        <NavTab
          tabs={[
            {
              name: "Active Rentables",
              content: (
                <ActiveRentables
                  rentables={this.activeRentables}
                  currentUser={this.currentUser}
                />
              ),
            },
            {
              name: "Archived Rentables",
              hide: this.deletedRentables.length === 0,
              content: (
                <DeletedRentables
                  rentables={this.deletedRentables}
                  currentUser={this.currentUser}
                />
              ),
            },
          ]}
        />
      </Page>
    );
  }
}
