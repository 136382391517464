import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  isValidEmail,
  isValidPhoneNumber,
  getDateWithoutTimeZone,
} from "./../../../helpers/utilities";
import Toast from "../../../components/Toast/Toast";
import {
  BOOKING_STATUS,
  BOOKING_DURATION_UNIT,
} from "../../../constants/enums";
import { URLS } from "./../../../constants/urls";
import Review from "./Review";
import Copy from "./Copy";
import Cancellation from "./Cancellation";
import NavTab from "./../../../components/NavTab/NavTab";
import Details from "./Details";
import Client from "./Client";
import Features from "./Features";
import Fees from "./Fees";
import Activities from "./Activities";
import Modal from "./../../../components/Modal/Modal";
import DropDown from "./../../../components/DropDown/DropDown";
import BookingStatus from "../BookingStatus";
import LoaderButton from "./../../../components/LoaderButton/LoaderButton";

const VIEWS = {
  default: "default",
  review: "review",
  copy: "copy",
  cancellation: "cancellation",
};

/*
<BookingModal
    booking={this.state.currentBooking}
    rentable={this.state.selectedRentable}
    onUpdateBookingEntries={() => this.updateBookingEntries(booking)}  // pass single entry to either insert or update existing entry in master list of booking entries in parent
    onClose={() => this.setState({ currentBooking: null })}
/>
*/
export default function BookingModal(props) {
  const [booking, setBooking] = useState(null);
  const [loading, setLoading] = useState(false);
  const [view, setView] = useState(VIEWS.default);

  useEffect(() => {
    setBooking(props.booking);
  }, [props.booking]);

  function isNewBooking() {
    return booking && booking.id ? false : true;
  }

  function updateBooking(_booking, changed = true) {
    _booking.changed = changed;
    setBooking(_booking);
  }

  function createOrReviewOrUpdate() {
    if (!isValidEmail(booking.client.emailAddress)) {
      Toast.error("Client email address is not valid");
      return;
    }
    if (!isValidPhoneNumber(booking.client.phoneNumber)) {
      Toast.error("Client phone number is not valid");
      return;
    }

    if (isNewBooking()) {
      create();
    } else {
      if (booking.changed) {
        update();
      } else if (booking.status === BOOKING_STATUS.draft.value) {
        review();
      } else {
        return;
      }
    }
  }

  function create() {
    setLoading(true);

    const data = { ...booking };
    // as string (despite js object that has timezone) so that api doesn't convert it to UTC (or server's local) in post data
    data.start = data.start && getDateWithoutTimeZone(data.start);
    data.end = data.end && getDateWithoutTimeZone(data.end);

    axios
      .post(URLS.BOOKING_ENTRIES.BASE, data)
      .then(function (response) {
        if (response.data.success === true && response.data.data) {
          props.onUpdateBookingEntries(response.data.data);
          setView(VIEWS.review);
        } else {
          Toast.error(response.data.errors);
        }
      })
      .catch(function (response) {
        Toast.error(response.message, "Error creating booking");
      })
      .then(function () {
        setLoading(false);
      });
  }

  function update() {
    setLoading(true);

    axios
      .post(URLS.BOOKING_ENTRIES.UPDATE + booking.id, booking)
      .then(function (response) {
        if (response.data.success === true && response.data.data) {
          props.onUpdateBookingEntries(response.data.data);
          updateBooking(response.data.data, false);
          toastBookingMessage("Booking updated", booking);
          setView(VIEWS.review);
        } else {
          Toast.error(response.data.errors);
        }
      })
      .catch(function (response) {
        Toast.error(response.message, "Error updating booking");
      })
      .then(function () {
        setLoading(false);
      });
  }

  function review() {
    setLoading(true);

    axios
      .get(URLS.BOOKING_ENTRIES.REVIEW + booking.id)
      .then(function (response) {
        if (response.data.success === true && response.data.data) {
          updateBooking(response.data.data);
          setView(VIEWS.review);
        } else {
          Toast.error(response.data.errors);
        }
      })
      .catch(function (response) {
        Toast.error(response.message, "Error reviewing booking");
      })
      .then(function () {
        setLoading(false);
      });
  }

  function deleteBooking() {
    // "delete" is reserved word, so deleteBooking
    setLoading(true);

    axios
      .post(URLS.BOOKING_ENTRIES.DELETE + booking.id)
      .then(function (response) {
        if (response.data.success === true) {
          const _booking = { ...booking };
          _booking.isDeleted = true;
          props.onUpdateBookingEntries(_booking);

          toastBookingMessage("Booking deleted", _booking);
        } else {
          Toast.error(response.data.errors);
        }
      })
      .catch(function (response) {
        Toast.error(response.message, "Error deleting booking");
      })
      .then(function () {
        setLoading(false);
      });
  }

  function approve() {
    setLoading(true);

    axios
      .post(URLS.BOOKING_ENTRIES.APPROVE + booking.id)
      .then(function (response) {
        if (response.data.success === true && response.data.data) {
          const _booking = response.data.data;
          props.onUpdateBookingEntries(_booking);
          toastBookingMessage("Booking approved (activated)", _booking);
        } else {
          Toast.error(response.data.errors);
        }
      })
      .catch(function (response) {
        Toast.error(response.message, "Error approving booking");
      })
      .then(function () {
        setLoading(false);
      });
  }

  function decline() {
    setLoading(true);

    axios
      .post(URLS.BOOKING_ENTRIES.DECLINE + booking.id, {
        reason: "reason text for declining",
      })
      .then(function (response) {
        if (response.data.success === true && response.data.data) {
          const _booking = response.data.data;
          props.onUpdateBookingEntries(_booking);
          toastBookingMessage("Booking declined", _booking);
        } else {
          Toast.error(response.data.errors);
        }
      })
      .catch(function (response) {
        Toast.error(response.message, "Error declining booking");
      })
      .then(function () {
        setLoading(false);
      });
  }

  function getViewContent() {
    const isNew = isNewBooking();
    const rentableDailyBookable =
      props.rentable.bookingDurationUnit === BOOKING_DURATION_UNIT.day.value;

    if (view === VIEWS.review) {
      return (
        <Review
          booking={booking}
          onUpdateBookingEntries={props.onUpdateBookingEntries}
          onClose={props.onClose}
        />
      );
    } else if (view === VIEWS.copy) {
      return (
        <Copy
          booking={booking}
          updateBooking={updateBooking}
          goBack={() => setView(VIEWS.default)}
          rentableFeatures={props.rentable.features}
        />
      );
    } else if (view === VIEWS.cancellation) {
      return (
        <Cancellation
          booking={booking}
          onUpdateBookingEntries={props.onUpdateBookingEntries}
          onClose={props.onClose}
        />
      );
    } else {
      return (
        <>
          {booking.status === BOOKING_STATUS.pending.value && (
            <div className="alert alert-warning" role="alert">
              This booking has been made online and is pending approval.
              <button
                className="btn btn-outline-primary btn-sm mx-3"
                onClick={() => approve()}
              >
                Approve
              </button>
              <button
                className="btn btn-outline-danger btn-sm"
                onClick={() => decline()}
              >
                Decline
              </button>
            </div>
          )}

          <NavTab
            className="mx-n3 mb-n3 mt-n1"
            tabs={[
              {
                name: "Details",
                content: (
                  <Details
                    booking={booking}
                    updateBooking={updateBooking}
                    rentable={props.rentable}
                  />
                ),
              },
              {
                name: "Client Info",
                content: (
                  <Client
                    booking={booking}
                    isNewBooking={isNew}
                    updateBooking={updateBooking}
                  />
                ),
              },
              {
                name: "Features",
                content: (
                  <Features
                    booking={booking}
                    updateBooking={updateBooking}
                    rentableFeatures={props.rentable.features}
                  />
                ),
              },
              {
                name: "Fees",
                content: (
                  <Fees
                    booking={booking}
                    updateBooking={updateBooking}
                    rentableDailyBookable={rentableDailyBookable}
                    rentable={props.rentable}
                    isNewBooking={isNew}
                  />
                ),
              },
              {
                name: "Activities",
                disabled: isNew,
                content: (
                  <Activities booking={booking} updateBooking={updateBooking} />
                ),
              },
            ]}
          />
        </>
      );
    }
  }

  function render() {
    if (!booking) {
      return null;
    }

    const isNew = isNewBooking();

    return (
      <Modal
        header={
          isNewBooking() ? (
            "New booking"
          ) : (
            <span>
              <span className="text-black-50 mr-2">
                {booking.referenceNumber}
              </span>
              {booking.title}
            </span>
          )
        }
        footer={
          view !== VIEWS.default ? (
            <div className="d-block w-100">
              <button
                className="btn btn-outline-secondary float-left"
                onClick={() => setView(VIEWS.default)}
              >
                Back
              </button>

              <button
                className="btn btn-outline-secondary float-right"
                onClick={props.onClose}
              >
                Close
              </button>
            </div>
          ) : (
            <div className="d-block w-100">
              {(isNew ||
                booking.status === BOOKING_STATUS.active.value ||
                booking.status === BOOKING_STATUS.draft.value) && (
                <DropDown
                  text="Options"
                  className="float-left"
                  btnClass="btn btn-outline-secondary"
                  contentClass="px-0"
                  direction="up"
                >
                  {isNew && (
                    <button
                      className="dropdown-item"
                      onClick={() => setView(VIEWS.copy)}
                    >
                      Copy from existing
                    </button>
                  )}
                  {booking.status === BOOKING_STATUS.active.value && (
                    <button
                      className="dropdown-item"
                      onClick={() => setView(VIEWS.cancellation)}
                    >
                      Cancel booking
                    </button>
                  )}
                  {booking.status === BOOKING_STATUS.draft.value && (
                    <button
                      className="dropdown-item"
                      onClick={() => deleteBooking()}
                    >
                      Delete draft booking
                    </button>
                  )}
                </DropDown>
              )}

              {!isNew && (
                <BookingStatus status={booking.status} className="ml-3" />
              )}

              {(isNew ||
                booking.status === BOOKING_STATUS.draft.value ||
                booking.status === BOOKING_STATUS.active.value ||
                booking.status === BOOKING_STATUS.pending.value) && (
                <LoaderButton
                  onClick={createOrReviewOrUpdate}
                  className="btn-primary float-right"
                  loading={loading}
                  loadingText="Loading..."
                  disabled={
                    !booking.changed &&
                    booking.status !== BOOKING_STATUS.draft.value
                  }
                >
                  {isNew || booking.status === BOOKING_STATUS.draft.value
                    ? "Review"
                    : "Update"}
                </LoaderButton>
              )}

              {!loading && (
                <button
                  className="btn btn-outline-secondary float-right mr-2"
                  onClick={props.onClose}
                >
                  Close
                </button>
              )}
            </div>
          )
        }
        open={true}
        onClose={props.onClose}
        showCancelButton={false}
        allowOutsideClick={false} //otherwise clicking on toast to useExistingClientInfo will close modal
        size="lg"
        bodyClass="bg-white"
      >
        {getViewContent()}
      </Modal>
    );
  }
  return render();
}

export const toastBookingMessage = (message, booking) => {
  Toast.success(
    [
      <>
        <span className="text-muted">Reference number: </span>{" "}
        {booking.referenceNumber}
      </>,
      <>
        <span className="text-muted">Title: </span> {booking.title}
      </>,
    ],
    message
  );
};
