import React from "react";
import { FullPage } from "./Page";

export default function LoggedOut() {
  return (
    <FullPage>
      <h5>You are logged out :(</h5>
      <br />
      <a href="/Auth/SignIn" className="btn btn-outline-secondary">
        Sign in
      </a>
      <a href="/Home" className="btn btn-outline-secondary mx-2">
        Website
      </a>
      <button
        onClick={() => window.location.reload()}
        className="btn btn-outline-secondary"
      >
        Reload
      </button>
    </FullPage>
  );
}
