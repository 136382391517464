import React, { useRef, useEffect } from "react";
import Chart from "chart.js";

/*
<Graph
  className="col-md-4"
  maxHeight="200px"
  type="bar"
  data={{...}}
  options={{...}}
/>
*/
export default function Graph({ className, maxHeight, type, data, options }) {
  const graphRef = useRef(null);
  const chartRef = useRef(null);

  useEffect(() => {
    if (graphRef.current) {
      if (!chartRef.current) {
        chartRef.current = new Chart(graphRef.current.getContext("2d"), {
          type,
          data,
          options,
        });
      } else {
        chartRef.current.type = type;
        chartRef.current.data = data;
        chartRef.current.options = options;
        chartRef.current.update();
      }
    }
  }, [data, options, type]);

  return (
    <div className={className || ""}>
      <canvas ref={graphRef} style={maxHeight ? { maxHeight } : null} />
    </div>
  );
}
