import React from "react";
import { BOOKING_STATUS } from "../../constants/enums";

/* 
  <BookingStatus 
    status={booking.status}
    className="ml-3" 
  /> 
*/
export default function BookingStatus(props) {
  function render() {
    const status = Object.values(BOOKING_STATUS).find(
      (bs) => bs.value === props.status
    );
    return !status ? null : (
      <div
        className={
          "d-inline-block position-relative p-1 " + (props.className || "")
        }
        style={{ marginTop: "2px" }}
      >
        <div
          className="d-inline-block rounded-circle position-absolute"
          style={{
            width: "7px",
            height: "7px",
            top: "13px",
            left: "-1px",
            background: status.color,
          }}
        ></div>
        <div className="d-inline-block ml-2">{status.name}</div>
      </div>
    );
  }

  return render();
}
