import React, { useEffect, useState, useMemo, useCallback } from "react";
import { FloatingLabelSelect } from "../FloatingLabelFormElements/FloatingLabelFormElements";
import axios from "axios";
import { LOCATION_TYPE, SESSION_STORAGE_KEYS } from "../../constants/enums";
import { URLS } from "./../../constants/urls";
import Toast from "../Toast/Toast";

// <LocationSelect
//     onChange={onChange}
//     valueProp="name" // value to be set in options, can be "id" or "name" of Location, default is "id". Use "name" if location is stored as string
//     countryName="countryId"      // default "country"
//     countryValue={client.country}
//     countryClass="col-6 col-md-4"
//     countryRequired={true}
//     countryDisabled={true}
//     provinceName="provinceId"    // default "province"
//     provinceValue={client.province}
//     provinceClass="col-6 col-md-4"
//     provinceRequired={true}
//     provinceDisabled={true}
//     cityName="cityId"            // default "city"
//     cityValue={client.city}
//     cityClass="col-6 col-md-4"
//     cityRequired={true}
//     cityDisabled={true}
// />

export default function LocationSelect(props) {
  const [loading, setLoading] = useState(false);
  const [countries, setCountries] = useState([]); // [{name: "Canada", id: 1, provinces: [{name: "Alberta", cities: []}]}, {name: "Azerbaijan", cities: []}]
  const valueProp = props.valueProp === "name" ? "name" : "id";

  const fetchLocations = useCallback((_countryId = null) =>{
    const countryId = _countryId ? parseInt(_countryId) : _countryId;
    let _countries =
      JSON.parse(sessionStorage.getItem(SESSION_STORAGE_KEYS.countries)) || [];
    let url = URLS.LOCATIONS.BASE;
    let _country = null;

    if (!countryId) {
      // load countries list
      // skip if countries already loaded
      if (_countries.length > 0) {
        if (countries.length === 0) {
          setCountries(_countries);
        }
        return;
      }
    } else {
      // load country's provinces & cities
      // skip if they are already loaded
      _country = _countries.find((a) => a.id === countryId);
      if (
        (_country.provinces && _country.provinces.length > 0) ||
        (_country.cities && _country.cities.length > 0)
      ) {
        return;
      }
      url = URLS.LOCATIONS.BY_COUNTRY + countryId;
    }

    setLoading(true);
    axios
      .get(url, { params: { isDeleted: false } })
      .then(function (response) {
        const locations = response.data.data;
        if (response.data.success === true && locations) {
          if (!countryId) {
            _countries = locations;
          } else {
            const provinces = locations.filter(
              (a) =>
                a.type === LOCATION_TYPE.province.value &&
                a.parentId === countryId
            );
            if (provinces.length > 0) {
              // get cities of each province
              provinces.forEach((province) => {
                province.cities = locations.filter(
                  (a) =>
                    a.type === LOCATION_TYPE.city.value &&
                    a.parentId === province.id
                );
              });
              _country.provinces = provinces;
            } else {
              // country doesn't have provinces but cities
              _country.cities = locations.filter(
                (a) => a.type === LOCATION_TYPE.province.value
              );
            }
          }

          sessionStorage.setItem(
            SESSION_STORAGE_KEYS.countries,
            JSON.stringify(_countries)
          );
          setCountries(_countries);
        }
      })
      .catch(function (response) {
        Toast.error(response.message, "Error getting locations");
      })
      .then(function () {
        setLoading(false);
      });
  }, [countries.length]);

  useEffect(() => {
    fetchLocations(); // load countries
  }, [fetchLocations]);

  const countryName = props.countryName || "country";
  const provinceName = props.provinceName || "province";
  const cityName = props.cityName || "city";

  function onChange(e) {
    const { name, value } = e.target;
    if (name === countryName && value) {
      let countryId = value;
      if (valueProp === "name") {
        countryId = countries.find((a) => a.name === value).id;
      }
      fetchLocations(countryId);
    }
    props.onChange(e);

    // reset city and province when country or province changed
    if (name !== cityName) {
      props.onChange({ target: { name: cityName, value: "" } });
      if (name === countryName) {
        props.onChange({ target: { name: provinceName, value: "" } });
      }
    }
  }

  const country = useMemo(() => {
    return countries.find((a) =>
      valueProp === "name"
        ? a.name === props.countryValue
        : a.id === parseInt(props.countryValue)
    );
  }, [countries, props.countryValue, valueProp]);

  const cities = useMemo(() => {
    if (!country) {
      return [];
    } else if (country.provinces && country.provinces.length > 0) {
      const province = country.provinces.find((a) =>
        valueProp === "name"
          ? a.name === props.provinceValue
          : a.id === parseInt(props.provinceValue)
      );
      return province && province.cities ? province.cities : [];
    } else {
      return country.cities || [];
    }
  }, [country, props.provinceValue, valueProp]);

  function render() {
    const provinces = country ? country.provinces : [];
    return (
      <>
        <div className={props.countryClass}>
          <FloatingLabelSelect
            name={countryName}
            placeholder="Country"
            onChange={onChange}
            value={props.countryValue}
            disabled={
              props.countryDisabled ||
              loading ||
              !countries ||
              countries.length === 0
            }
            required={props.countryRequired}
          >
            <option value="">Select country</option>
            {countries &&
              countries.map((c) => (
                <option key={c.id} value={c[valueProp]}>
                  {c.name}
                </option>
              ))}
          </FloatingLabelSelect>
        </div>

        <div className={props.provinceClass}>
          <FloatingLabelSelect
            name={provinceName}
            placeholder="Province"
            onChange={onChange}
            value={props.provinceValue}
            disabled={
              props.provinceDisabled ||
              loading ||
              !provinces ||
              provinces.length === 0
            }
            required={props.provinceRequired}
          >
            <option value="">Select province</option>
            {provinces &&
              provinces.map((p) => (
                <option key={p.id} value={p[valueProp]}>
                  {p.name}
                </option>
              ))}
          </FloatingLabelSelect>
        </div>

        <div className={props.cityClass}>
          <FloatingLabelSelect
            name={cityName}
            placeholder="City"
            onChange={onChange}
            value={props.cityValue}
            disabled={
              props.cityDisabled || loading || !cities || cities.length === 0
            }
            required={props.cityRequired}
          >
            <option value="">Select city</option>
            {cities &&
              cities.map((c) => (
                <option key={c.id} value={c[valueProp]}>
                  {c.name}
                </option>
              ))}
          </FloatingLabelSelect>
        </div>
      </>
    );
  }
  return render();
}
