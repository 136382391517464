// This is used to keep track of the user's sign in status.
// When user signs out in one tab/window, other open tabs/windows will need to know about it.
// This will be checked within Layout and Page when routing happens

const key = "isSignedIn";

export default class Auth {
  static isSignedIn() {
    var val = localStorage.getItem(key);
    return val && val === "true";
  }

  static signIn() {
    localStorage.setItem(key, true);
  }

  static signOut() {
    localStorage.removeItem(key);
  }
}
