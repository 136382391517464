import React, { useEffect, useState } from "react";
import axios from "axios";
import Auth from "./../../helpers/auth";
import { URLS } from "./../../constants/urls";
import { FullPage } from "./Page";
import Actions from "./../../StateManagement/actions";
import useStateManagement from "./../../StateManagement/StateManagement";
import RoutesList from "./RoutesList";
import { Link } from "react-router-dom";
import { USER_ROLES } from "./../../constants/enums";

export default function Init() {
  const [loading, setLoading] = useState(true);
  const [errors, setErrors] = useState([]);
  const { dispatch } = useStateManagement();

  useEffect(() => {
    if (loading) {
      document.title = "Initializing App...";
      Auth.signOut();

      (async () => {
        let fetchedCurrentUser = false;

        // Fetch current user
        try {
          const response = await axios.get(URLS.ACCOUNT.BASE);
          if (response.data.success && response.data.data) {
            const userRoles = response.data.data.roles;
            // To be Portal user, must have only either or both of Admin and User roles
            const isPortalUser =
              userRoles &&
              (userRoles.includes(USER_ROLES.admin) ||
                userRoles.includes(USER_ROLES.user));

            if (isPortalUser) {
              Auth.signIn();
              dispatch(Actions.setCurrentUser(response.data.data));
              fetchedCurrentUser = true;
            } else {
              setErrors([
                "You don't have sufficient privileges to access to the app",
              ]);
            }
          } else {
            setErrors(response.data.errors || ["Authentication failed"]);
          }
        } catch (err) {
          setErrors([err.message]);
        }

        // Fetch rentables
        if (fetchedCurrentUser) {
          try {
            const response = await axios.get(URLS.RENTABLES.BASE);
            if (response.data.success && response.data.data) {
              dispatch(Actions.setRentables(response.data.data));
            } else if (response.data.errors) {
              setErrors(response.data.errors);
            }
          } catch (err) {
            setErrors([err.message]);
          }
        }

        setLoading(false);
      })();
    }
  }, [loading, dispatch]);

  return loading ? (
    <LoadingScreen />
  ) : errors.length > 0 ? (
    <ErrorPage errors={errors} />
  ) : (
    <RoutesList />
  );
  // TODO: MAINTENANCE MODE
}

function LoadingScreen() {
  return (
    <FullPage>
      <div
        className="spinner-grow text-primary"
        role="status"
        style={{ width: "3rem", height: "3rem" }}
      ></div>
    </FullPage>
  );
}

/*
  <ErrorPage errors={["error1", "error2"]}>
*/
function ErrorPage(props) {
  useEffect(() => {
    document.title = "Something went wrong";
  }, []);

  return (
    <FullPage>
      <h5>Errors encountered</h5>
      {props.errors && props.errors.length > 0 && (
        <ul className="pl-0 my-3" style={{ listStyle: "inside" }}>
          {props.errors.map((error, index) => (
            <li key={index} className="text-danger">
              {error}
            </li>
          ))}
        </ul>
      )}
      <p>Contact support if you need help.</p>
      {/* This is outside of Router, so Link won't work */}
      <p>
        <Link to="/App" className="btn btn-outline-secondary">
          Dashboard
        </Link>
        <button
          onClick={() => window.location.reload()}
          className="btn btn-outline-secondary ml-2"
        >
          Reload
        </button>
        <a href="/Home" className="btn btn-outline-secondary ml-2">
          Website
        </a>
      </p>
    </FullPage>
  );
}
