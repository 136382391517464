import React from "react";
import Icon, { Stack } from "@mdi/react";
import { mdiLoading, mdiCircleOutline } from "@mdi/js";

/*
  <Spinner
    type="grow" // or "circle"
  />
*/
export default function Spinner(props) {
  const type = props.type && props.type === "circle" ? "circle" : "grow";

  return (
    <div className="d-flex">
      {type === "grow" ? (
        <div className="spinner-grow text-primary mx-auto" role="status">
          <span className="sr-only">Loading...</span>
        </div>
      ) : (
        <Stack size={1.5} spin={true} className="mdi mr-1 mx-auto">
          <Icon
            path={mdiCircleOutline}
            size={1.5}
            color="#0ed2d2"
            className="mdi"
          />
          <Icon path={mdiLoading} size={1.5} color="#d8fefe" className="mdi" />
        </Stack>
      )}
    </div>
  );
}
