import React from "react";
import Swal from "sweetalert2";
import axios from "axios";
import { FloatingLabelInput } from "../../../components/FloatingLabelFormElements/FloatingLabelFormElements";
import Modal from "../../../components/Modal/Modal";
import LoaderButton from "../../../components/LoaderButton/LoaderButton";
import { updateElementPropertyInList } from "../../../helpers/utilities";
import Toast from "../../../components/Toast/Toast";
import { URLS } from "../../../constants/urls";
import { onFormChange, isValidEmail } from "../../../helpers/utilities";
import UsersTable from "./UsersTable";

export default class InvitedUsers extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      open: false,
      firstName: "",
      lastName: "",
      email: "",
    };

    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.onReinvite = this.onReinvite.bind(this);
    this.onDelete = this.onDelete.bind(this);
  }

  onChange(e) {
    const { name, value } = onFormChange(e);
    this.setState({ [name]: value });
  }

  onSubmit() {
    const root = this;

    if (!isValidEmail(root.state.email)) {
      Toast.error("Email address is not valid");
      return;
    }

    root.setState({ loading: true });

    axios
      .post(URLS.USERS.BASE, {
        firstName: root.state.firstName,
        lastName: root.state.lastName,
        email: root.state.email,
      })
      .then(function (response) {
        if (response.data.success === true && response.data.data) {
          Toast.success("Account created, invitation email sent");

          let users = [...root.props.users];
          users.push(response.data.data);
          root.props.onUpdateUsers(users);
          root.setState({
            open: false,
            firstName: "",
            lastName: "",
            email: "",
          });
        } else if (response.data.errors) {
          Toast.error(response.data.errors);
        }
      })
      .catch(function (response) {
        Toast.error(response.message, "Error inviting user");
      })
      .then(function () {
        root.setState({ loading: false });
      });
  }

  onReinvite(user) {
    const root = this;
    let users = [...root.props.users];
    root.props.onUpdateUsers(
      updateElementPropertyInList(users, "id", user.id, "loading", true)
    );

    axios
      .post(URLS.USERS.REINVITE + user.id)
      .then(function (response) {
        if (response.data.success) {
          root.props.onUpdateUsers(
            updateElementPropertyInList(users, "id", user.id, "reinvited", true)
          );
        } else if (response.data.errors) {
          Toast.error(response.data.errors);
        }
      })
      .catch(function (response) {
        Toast.error(response.message, "Error reinviting user");
      })
      .then(function () {
        root.props.onUpdateUsers(
          updateElementPropertyInList(users, "id", user.id, "loading", false)
        );
      });
  }

  onDelete(user) {
    const root = this;
    Swal.fire({
      title: "Are you sure?",
      type: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes",
    }).then((result) => {
      if (result.value) {
        let users = [...root.props.users];
        root.props.onUpdateUsers(
          updateElementPropertyInList(users, "id", user.id, "loading", true)
        );
        axios
          .post(URLS.USERS.DELETE + user.id)
          .then(function (response) {
            if (response.data.success) {
              root.props.onUpdateUsers(
                updateElementPropertyInList(
                  users,
                  "id",
                  user.id,
                  "deleted",
                  true
                )
              );
            } else if (response.data.errors) {
              Toast.error(response.data.errors);
            }
          })
          .catch(function (response) {
            Toast.error(response.message, "Error deleting user");
          })
          .then(function () {
            root.props.onUpdateUsers(
              updateElementPropertyInList(
                users,
                "id",
                user.id,
                "loading",
                false
              )
            );
          });
      }
    });
  }

  render() {
    return (
      <div>
        <div className="row">
          <div className="col-md-9">
            {this.props.users.length > 0
              ? "These are users that have been invited to join your team by one of the admins and are still pending email confirmation"
              : "There is no invited user"}
          </div>
          <div className="col-md-3">
            {this.props.currentUser.isAdmin && (
              <button
                onClick={() => this.setState({ open: true })}
                type="button"
                className="btn btn-primary float-md-right btn-xs-block mt-2 mt-md-0 mb-4"
              >
                Invite New User
              </button>
            )}
          </div>

          {this.props.users.length > 0 && (
            <div className="col-md-12">
              <UsersTable
                onReinvite={this.onReinvite}
                onDelete={this.onDelete}
                users={this.props.users}
                type="invited"
                currentUser={this.props.currentUser}
              />
            </div>
          )}
        </div>

        <Modal
          header="Invite new user"
          open={this.state.open}
          onClose={() => this.setState({ open: false })}
          bodyClass="bg-light"
          footer={
            <LoaderButton
              onClick={this.onSubmit}
              className="btn-primary"
              loading={this.state.loading}
              loadingText="Sending email..."
            >
              Save & Invite
            </LoaderButton>
          }
        >
          <FloatingLabelInput
            type="text"
            name="firstName"
            placeholder="First name"
            autoComplete={false}
            onChange={this.onChange}
            value={this.state.firstName}
            required={true}
          />
          <FloatingLabelInput
            type="text"
            name="lastName"
            placeholder="Last name"
            autoComplete={false}
            onChange={this.onChange}
            value={this.state.lastName}
            required={true}
          />
          <FloatingLabelInput
            type="text"
            name="email"
            placeholder="Email address"
            autoComplete={false}
            onChange={this.onChange}
            value={this.state.email}
            required={true}
          />
        </Modal>
      </div>
    );
  }
}
