import React from "react";
import axios from "axios";
import { URLS } from "../../../constants/urls";
import Toast from "../../../components/Toast/Toast";
import Page from "../../../components/_Others/Page";
import NavTab from "../../../components/NavTab/NavTab";
import ActiveUsers from './ActiveUsers';
import InvitedUsers from './InvitedUsers';
import { MainContext } from './../../../StateManagement/StateManagement';

export default class UserManagement extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      activeUsers: [],
      invitedUsers: [],
      loading: true,
    };

    this.currentUser = {};
  }

  static contextType = MainContext;

  componentDidMount() {
    this.currentUser = this.context.state.currentUser;
    const root = this;

    axios
      .get(URLS.USERS.BASE)
      .then(function (response) {
        if (response.data.success === true && response.data.data) {
          root.setState({
            activeUsers: response.data.data.filter(
              (x) => x.emailConfirmed === true
            ),
            invitedUsers: response.data.data.filter(
              (x) => x.emailConfirmed !== true
            ),
          });
        } else if (response.data.errors) {
          Toast.error(response.data.errors);
        }
      })
      .catch(function (response) {
        Toast.error(response.message, "Error getting users");
      })
      .then(function () {
        root.setState({ loading: false });
      });
  }

  render() {
    return (
      <Page title="User Management" loading={this.state.loading} type="tabs">
        <NavTab
          tabs={[
            {
              name: "Active Users",
              content: (
                <ActiveUsers
                  currentUser={this.currentUser}
                  users={this.state.activeUsers}
                  onUpdateUsers={(users) =>
                    this.setState({ activeUsers: users })
                  }
                />
              ),
            },
            {
              name: "Invited Users",
              content: (
                <InvitedUsers
                  currentUser={this.currentUser}
                  users={this.state.invitedUsers}
                  onUpdateUsers={(users) =>
                    this.setState({ invitedUsers: users })
                  }
                />
              ),
            },
          ]}
        />
      </Page>
    );
  }
}
