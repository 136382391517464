import React, { useEffect, useRef } from "react";
import "./Modal.css";

/*
  <Modal
    header="Title goes here"
    footer={<><button>Add</>button><button>Add another</></>}
    open={this.state.open}
    onClose={this.onClose}
    showCancelButton={true}   // default true
    cancelButtonText="Cancel"
    allowOutsideClick={true}  // default true
    size="lg" //sm, md (default), lg, xl
    bodyClass="bg-white"
  >
    <p>Body here</p>
  </Modal>
*/
export default function Modal(props) {
  const showCancelButton = props.showCancelButton !== false;
  const cancelButtonText = props.cancelButtonText || "Cancel";
  const allowOutsideClick = props.allowOutsideClick !== false;
  const bodyClass = `modal-body ${props.bodyClass || ""}`;

  const modalDialogRef = useRef(null);

  useEffect(() => {
    // Bind the event listener
    document.addEventListener("mousedown", onClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", onClickOutside);
    };
  }, []);

  function onClickOutside(event) {
    if (allowOutsideClick) {
      if (
        modalDialogRef.current &&
        !modalDialogRef.current.contains(event.target)
      ) {
        props.onClose();
      }
    }
  }

  return (
    props.open && (
      <div className="modal d-block" style={{ background: "#00000057" }}>
        <div
          className={
            "modal-dialog modal-dialog-scrollable animate-modal " +
            (props.size ? "modal-" + props.size : "")
          }
          ref={modalDialogRef}
        >
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">{props.header}</h5>
              <button onClick={props.onClose} type="button" className="close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className={bodyClass}>{props.children}</div>
            <div className="modal-footer">
              {showCancelButton && (
                <button
                  onClick={props.onClose}
                  type="button"
                  className="btn btn-outline-secondary"
                >
                  {cancelButtonText}
                </button>
              )}
              {props.footer}
            </div>
          </div>
        </div>
      </div>
    )
  );
}
