import React from "react";
import axios from "axios";
import {
  BOOKING_DURATION_UNIT,
  BOOKING_STATUS,
} from "../../../constants/enums";
import { BOOKING_TYPE } from "../../../constants/enums";
import { URLS } from "../../../constants/urls";
import Toast from "../../../components/Toast/Toast";
import BetterSelect from "../../../components/BetterSelect/BetterSelect";
import BookingModal from "../BookingModal/BookingModal";
import BookingCalendar from "./BookingCalendar/BookingCalendar";
import Page from "../../../components/_Others/Page";
import { MainContext } from "./../../../StateManagement/StateManagement";
import { getUrlSearchParams } from "./../../../helpers/utilities";

export default class BookingsCalendarView extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedRentable: null,
      currentBooking: null,
      bookingEntries: [],
    };

    this.rentables = [];
    this.onRentableChange = this.onRentableChange.bind(this);
    this.onDateSelected = this.onDateSelected.bind(this);
    this.fetchBookingEntries = this.fetchBookingEntries.bind(this);
    this.onBookingEntrySelected = this.onBookingEntrySelected.bind(this);
    this.updateBookingEntries = this.updateBookingEntries.bind(this);
  }

  static contextType = MainContext;

  componentDidMount() {
    const root = this;
    root.rentables = root.context.state.rentables.getDeleted(false);

    const { rentableId } = getUrlSearchParams();

    let rentable = null;

    if (rentableId) {
      rentable = root.rentables.find((r) => r.id === parseInt(rentableId));
    }

    if (!rentable) {
      rentable = root.rentables[0];
    }

    root.setState({ selectedRentable: rentable });
  }

  isRentableDailyBookable(rentable) {
    return !rentable
      ? null
      : rentable.bookingDurationUnit === BOOKING_DURATION_UNIT.day.value;
  }

  onRentableChange(id) {
    const rentableId = parseInt(id);
    if (
      !this.state.selectedRentable ||
      rentableId !== this.state.selectedRentable.id
    ) {
      const rentable = this.rentables.find((r) => r.id === rentableId);

      this.setState({
        selectedRentable: rentable,
        bookingEntries: [],
      });

      // reset calendar dates for API fetch
      this.start = null;
      this.end = null;
    }
  }

  onDateSelected(info) {
    const rentable = this.state.selectedRentable;
    this.setState({
      currentBooking: {
        title: "",
        rentableId: rentable.id,
        start: info.startStr,
        end: info.endStr,
        client: {},
        type: BOOKING_TYPE.inHouse.value,
        payment: {
          discount: 0,
          featureFees: 0,
          features: [],
        },
      },
    });
  }

  updateBookingEntries(booking) {
    if (booking.isDeleted) {
      // delete
      const bookingEntries = this.state.bookingEntries.filter(
        (b) => b.id !== booking.id
      );
      this.setState({ bookingEntries, currentBooking: null });
    } else {
      let found = false;
      // update
      const bookingEntries = this.state.bookingEntries.map((b) => {
        if (b.id === booking.id) {
          found = true;
          return booking;
        }
        return b;
      });

      // add new
      if (!found) {
        bookingEntries.push(booking);
      }

      this.setState({ bookingEntries, currentBooking: booking });
    }
  }

  fetchBookingEntries(info) {
    const { activeStart, activeEnd } = info.view;
    // as string (despite js object that has timezone) so that api doesn't convert it to server's local timezone in post data
    const activeStartStr = activeStart.toDateString();
    const activeEndStr = activeEnd.toDateString();
    this.start = this.start || null;
    this.end = this.end || null;

    if (this.start && this.end) {
      // if same date time don't refetch from API in every render
      if (this.start === activeStartStr && this.end === activeEndStr) {
        return;
      }

      // if within previously fetched range don't refetch from API in every render
      if (
        activeStart.getTime() >= Date.parse(this.start) &&
        activeEnd.getTime() <= Date.parse(this.end)
      ) {
        return;
      }
    }

    this.start = activeStartStr;
    this.end = activeEndStr;

    const root = this;
    axios
      .get(URLS.BOOKING_ENTRIES.BY_RENTABLE + root.state.selectedRentable.id, {
        params: {
          start: activeStartStr,
          end: activeEndStr,
        },
      })
      .then(function (response) {
        if (response.data.success) {
          root.setState({ bookingEntries: response.data.data });
        } else {
          Toast.error(response.data.errors);
        }
      })
      .catch(function (response) {
        Toast.error(response.message, "Error getting bookings");
      });
  }

  onBookingEntrySelected(info) {
    if (info && info.event.id) {
      const currentBooking = this.state.bookingEntries.find(
        (a) => a.id === info.event.id
      );
      if (currentBooking) {
        this.setState({
          currentBooking,
        });
      }
    }
  }

  render() {
    const root = this;
    return (
      <Page
        title="Calendar"
        extras={
          this.rentables.length > 1 && (
            <BetterSelect
              onChange={(e) => this.onRentableChange(e.target.value)}
              className="btn-link"
              defaultText="Select Rentable"
              text={this.state.selectedRentable.name}
            >
              {root.rentables.length > 0 &&
                root.rentables.map((rentable) => (
                  <option key={rentable.id} value={rentable.id}>
                    {rentable.name}
                  </option>
                ))}
            </BetterSelect>
          )
        }
      >
        {this.state.currentBooking && (
          <BookingModal
            booking={this.state.currentBooking}
            rentable={this.state.selectedRentable}
            onUpdateBookingEntries={this.updateBookingEntries}
            onClose={() => this.setState({ currentBooking: null })}
          />
        )}

        {this.state.selectedRentable && (
          <BookingCalendar
            rentable={this.state.selectedRentable}
            rentableDailyBookable={this.isRentableDailyBookable(
              this.state.selectedRentable
            )}
            bookingEntries={this.state.bookingEntries}
            onDateSelected={this.onDateSelected}
            onDatesRender={this.fetchBookingEntries}
            onEventClicked={this.onBookingEntrySelected}
            bookingStatusEnum={BOOKING_STATUS}
          />
        )}

        <div className="mt-5">
          <p className="mb-2">Color codes:</p>
          <div className="row">
            {Object.values(BOOKING_STATUS).map((bs) => (
              <div key={bs.value} className="col-6 col-md-2 mb-1">
                <div
                  className="px-3"
                  style={{
                    border: `1px solid ${bs.color}`,
                    borderLeftWidth: "10px",
                  }}
                >
                  {bs.name}
                </div>
              </div>
            ))}
            <div className="col-6 col-md-2">
              <div
                className="px-3"
                style={{ border: "1px solid #ffff8f", borderLeftWidth: "10px" }}
              >
                Non-Working
              </div>
            </div>
          </div>
        </div>
      </Page>
    );
  }
}
