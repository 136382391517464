import React, { useState } from "react";
import axios from "axios";
import { URLS } from "./../../../constants/urls";
import Toast from "../../../components/Toast/Toast";
import { FloatingLabelInput } from "./../../../components/FloatingLabelFormElements/FloatingLabelFormElements";
import LoaderButton from "./../../../components/LoaderButton/LoaderButton";

export default function Copy(props) {
  const [referenceNumber, setReferenceNumber] = useState("");
  const [existingBooking, setExistingBooking] = useState(null);
  const [loading, setLoading] = useState(false);

  function search() {
    // find existing booking using reference number
    setExistingBooking();

    setLoading(true);
    axios
      .get(URLS.BOOKING_ENTRIES.BY_REFERENCE_NUMBER + referenceNumber)
      .then(function (response) {
        if (response.data.success === true && response.data.data) {
          setExistingBooking(response.data.data);
        } else {
          setExistingBooking(response.data.data);
          Toast.error(response.data.errors);
        }
      })
      .catch(function (response) {
        Toast.error(response.message, "Error searching booking");
      })
      .then(function () {
        setLoading(false);
      });
  }

  function copy() {
    const booking = { ...props.booking };

    // details
    booking.title = existingBooking.title;
    booking.description = existingBooking.description;

    // client details
    const existingClient = existingBooking.client;
    const client = (booking.client = { ...booking.client });

    client.firstName = existingClient.firstName;
    client.lastName = existingClient.lastName;
    client.emailAddress = existingClient.emailAddress;
    client.phoneNumber = existingClient.phoneNumber;
    client.company = existingClient.company;
    client.country = existingClient.country;
    client.province = existingClient.province;
    client.city = existingClient.city;
    client.addressLine1 = existingClient.addressLine1;
    client.addressLine2 = existingClient.addressLine2;
    client.postalCode = existingClient.postalCode;

    // payment / discount
    booking.payment = {
      ...booking.payment,
      discount: existingBooking.payment.discount,
    };

    // payment / features
    if (
      booking.rentableId === existingBooking.rentableId &&
      existingBooking.payment.features
    ) {
      existingBooking.payment.features.forEach((bf) => {
        const rentableFeature = props.rentableFeatures.find(
          (rf) => rf.id === bf.rentableFeatureId
        );

        if (rentableFeature) {
          booking.payment.features.push({
            rentableFeatureId: rentableFeature.id,
            fee: rentableFeature.fee,
          });

          booking.payment.featureFees += rentableFeature.fee || 0;
        }
      });
    }

    props.updateBooking(booking);
    props.goBack();
    Toast.success("Existing booking's details copied", "Copied");
  }

  function render() {
    return (
      <div className="row bg-light my-n3 py-3">
        <div className="offset-md-3 col-12 col-md-6 d-flex">
          <FloatingLabelInput
            name="referenceNumber"
            placeholder="Reference number"
            value={referenceNumber}
            className="mr-2 d-flex flex-fill"
            onChange={(e) => {
              setReferenceNumber(e.target.value);
            }}
          />

          <LoaderButton
            onClick={() => search()}
            className="btn btn-outline-primary h-75"
            loading={loading}
            loadingText="Searching..."
            disabled={referenceNumber.trim().length === 0}
          >
            Search
          </LoaderButton>
        </div>
        {existingBooking && (
          <>
            <div className="col-6">
              <h6 className="border-bottom pb-2">Booking found</h6>
              <p className="mb-2">
                <small className="text-secondary d-block">
                  Reference Number
                </small>
                {existingBooking.referenceNumber}
              </p>
              <p className="mb-2">
                <small className="text-secondary d-block">Title</small>
                {existingBooking.title}
              </p>
              <p className="mb-2">
                <small className="text-secondary d-block">Client Name</small>
                {existingBooking.client.fullName}
              </p>
              <p className="mb-2">
                <small className="text-secondary d-block">Client Email</small>
                {existingBooking.client.emailAddress}
              </p>
              <p className="mb-2">
                <small className="text-secondary d-block">
                  Full Payment Amount
                </small>
                ${existingBooking.payment.totalPaid}
              </p>
            </div>
            <div className="col-6">
              <h6 className="border-bottom pb-2">Things to copy</h6>
              <ul className="pl-4">
                <li>Title</li>
                <li>Description</li>
                <li>Client Details</li>
                <li>Discount</li>
                <li>Features (If same rentable. Fees may vary)</li>
              </ul>
            </div>
            <div className="col-12">
              <button
                className="btn btn-primary float-right"
                onClick={() => copy()}
              >
                Copy
              </button>
            </div>
          </>
        )}
      </div>
    );
  }
  return render();
}
