export function isValidEmail(email) {
  // eslint-disable-next-line no-useless-escape
  var re =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
}

export function isValidPhoneNumber(phoneNumber) {
  var re = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
  return re.test(String(phoneNumber));
}

// Create an indexed object from list of objects where key is the iteratee for element.
// e.g: indexBy(list, "id")
export function indexBy(list, iteratee, context) {
  return list.reduce((map, obj) => {
    const key =
      typeof iteratee === "string"
        ? obj[iteratee]
        : iteratee.call(context, obj);
    map[key] = obj;
    return map;
  }, {});
}

// Create a key/value mapped (indexed) object of list of objects where key is the iteratee and value is a single prop value of element.
// Similar to indexBy() but value part is a property of object instead of object itself
// e.g indexKeyValue(list, "id", "name")
export function indexKeyValue(list, iteratee, valueProp) {
  return list.reduce((map, obj) => {
    map[obj[iteratee]] = obj[valueProp];
    return map;
  }, {});
}

// Find a specific element in list, update its property, then return updated list
// updateElementPropertyInList(users, "id", 123, "loading", true)
export function updateElementPropertyInList(
  list,
  elementKeyName,
  elementKeyValue,
  propertyName,
  propertyValue
) {
  let element = list.find((it) => it[elementKeyName] === elementKeyValue);
  if (element) element[propertyName] = propertyValue;
  return list;
}

// Compare if two arrays are equal
export function areEqualArrays(array1, array2, orderMatters = false) {
  if (array1 === array2) return true;
  if (array1.length !== array2.length) return false;

  if (orderMatters)
    return array1.every(function (value, index) {
      return value === array2[index];
    });
  else
    return array1.sort().every(function (value, index) {
      return value === array2.sort()[index];
    });
}

// Convert object to FormData. Supports Array, Object, Date, File types as well
export function convertToFormData(data, formData, parentKey) {
  if (data === null || data === undefined) return null;

  formData = formData || new FormData();

  if (
    typeof data === "object" &&
    !(data instanceof Date) &&
    !(data instanceof File)
  ) {
    for (const key in data) {
      convertToFormData(
        data[key],
        formData,
        !parentKey
          ? key
          : data[key] instanceof File
          ? parentKey
          : `${parentKey}[${key}]`
      );
    }
  } else {
    formData.append(parentKey, data);
  }

  return formData;
}

// OnChange method to detect form element changes
export function onFormChange(event) {
  var result = { name: null, value: null /* checked: false */ };

  if (!event) return result;

  result.name = event.target.name;

  let value;
  if (event.target.type === "checkbox") {
    result.checked = event.target.checked;
    value = event.target.value;
  } else if (event.target.type === "file") {
    value = event.target.files;
  } else {
    value = event.target.value;
  }

  if (typeof value === "string") {
    const lowVal = value.toLowerCase();
    if (lowVal === "true" || lowVal === "false") {
      value = lowVal === "true";
    }
  }

  result.value = value;

  return result;
}

// sorts the given array and returns itself (without creating new one)
export function orderBy(list, propertyName = null) {
  if (!propertyName) {
    return list.sort();
  }

  return list.sort(function (a, b) {
    let valA = a[propertyName];
    let valB = b[propertyName];
    if (typeof valA === "string" && typeof valB === "string") {
      valA = valA.toLocaleLowerCase();
      valB = valB.toLocaleLowerCase();

      if (valA < valB) {
        return -1;
      } else if (valA > valB) {
        return 1;
      } else return 0;
    } else {
      return valA - valB;
    }
  });
}

// Takes date object (ideally) or converts string to date object
// then returns a string including date and time part of it, ignoring the rest (timezone).
// Use this to clear the timezone part of date before sending to API
// which will accept DateTime object with "unspecified" kind part, otherwise kind will be "local".
// If only date part needed just use JS' toDateString() function.
export function getDateWithoutTimeZone(date) {
  let strDate;
  if (date instanceof Date) {
    strDate = date.toString();
  } else if (typeof date === "string") {
    const dateObj = new Date(date);
    // has time part
    if (/\d\d:\d\d$/.test(date)) {
      strDate = dateObj.toString();
    } else {
      // just date without time
      strDate = dateObj.toUTCString(); // new Date(date) will parse string as UTC date, then convert it local. Therefore need to get UTC string to get the original date
    }
  }

  return strDate ? strDate.substring(0, 24) : date;
}

// Lighten or darken hex color with percentage.
// E.g: lightenOrDarkenColor("#123456", 30, true|false)
export function lightenOrDarkenColor(color, percentage, lighten) {
  let degree = (percentage / 100) * (lighten ? 1 : -1);
  let hexColor = color.substring(1, color.length); // remove # symbol
  hexColor = String(hexColor).replace(/[^0-9a-f]/gi, ""); // validate hex string
  if (hexColor.length < 6) {
    hexColor =
      hexColor[0] +
      hexColor[0] +
      hexColor[1] +
      hexColor[1] +
      hexColor[2] +
      hexColor[2];
  }
  degree = degree || 0;
  var rgb = "#",
    c,
    i;
  for (i = 0; i < 3; i++) {
    c = parseInt(hexColor.substr(i * 2, 2), 16);
    c = Math.round(Math.min(Math.max(0, c + c * degree), 255)).toString(16);
    rgb += ("00" + c).substr(c.length);
  }
  return rgb;
}

// Convert hex color into RGBA color with transparency
// E.g: convertHexToRGBA("#123456", 30)
// Less percentage more transparency
export function convertHexToRGBA(hex, percentage) {
  const tempHex = hex.replace("#", "");
  const r = parseInt(tempHex.substring(0, 2), 16);
  const g = parseInt(tempHex.substring(2, 4), 16);
  const b = parseInt(tempHex.substring(4, 6), 16);

  return `rgba(${r},${g},${b},${percentage / 100})`;
}

// Will take url search params (query strings) and create an object from it
// Will take current url none given
export function getUrlSearchParams(url) {
  if (!url) {
    url = window.location.search;
  }
  return Object.fromEntries(new URLSearchParams(url));
}
