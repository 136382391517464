import React from "react";
import { Link, Route, Routes, Outlet, NavLink } from "react-router-dom";
import axios from "axios";
// import { isValidEmail } from "../Helpers/Utilities.js";
// import { LoaderButton, FloatingLabelInput } from "./CustomElements.js";
// import { LayoutNavless } from "./Layouts.js";
// import { URL_SIGN_IN } from "../Services/Constants.js";
import { URLS } from "../constants/urls";
import { FloatingLabelInput } from "../components/FloatingLabelFormElements/FloatingLabelFormElements";
import LoaderButton from "../components/LoaderButton/LoaderButton";
import Auth from "../helpers/auth";
import "./Website.scss";
import logo from "../assets/img/logo.svg";
import { Icon } from "@mdi/react";
import { mdiChevronLeft } from "@mdi/js";
import { getUrlSearchParams, isValidEmail } from "./../helpers/utilities";

export default function Website() {
  return (
    <Routes>
      <Route path="/" element={<Layout />}>
        <Route index element={<Home />} />
        <Route path="/Website" element={<Home />} />
        <Route path="Home" element={<Home />} />
        <Route path="ContactUs" element={<ContactUs />} />
        <Route path="About" element={<About />} />
        <Route path="Features" element={<Features />} />
        <Route path="Pricing" element={<Pricing />} />
        <Route path="SignUp" element={<SignUp />} />
        <Route path="*" element={<Page404 />} />
      </Route>

      <Route path="/Auth" element={<AuthLayout />}>
        <Route index element={<SignIn />} />
        <Route path="SignIn" element={<SignIn />} />
        <Route path="ForgotPassword" element={<ForgotPassword />} />
        {/* class component with url param "?code=" */}
        <Route path="ResetPassword" element={<ResetPassword />} />
        {/* class component with url param "?userId=&code=" */}
        <Route path="ConfirmEmail" element={<ConfirmEmail />} />
      </Route>
    </Routes>
  );
}

function Layout() {
  return (
    <div className="website bg-light">
      <Header />
      <Outlet />
      <Footer />
    </div>
  );
}

export function Page(props) {
  if (props.title) document.title = props.title;

  return (
    <main className="container">
      {props.card === false ? props.children : <Card>{props.children}</Card>}
    </main>
  );
}

function Header() {
  return (
    <header className="bg-white shadow-sm mb-3 ">
      <div className="container p-2 px-md-3">
        <div className="d-flex flex-column flex-md-row align-items-center">
          <Link to="/Home" className="navbar-brand text-info">
            Centrel Portal
          </Link>
          <nav className="my-2 my-md-0 mr-md-3">
            <NavLink to="/Home" className="pb-1 pt-2 px-1 navlink mx-md-2 mx-1">
              Home
            </NavLink>
            <NavLink
              to="/Features"
              className="pb-1 pt-2 px-1 navlink mx-md-2 mx-1"
            >
              Features
            </NavLink>
            <NavLink
              to="/Pricing"
              className="pb-1 pt-2 px-1 navlink mx-md-2 mx-1"
            >
              Pricing
            </NavLink>
            <NavLink
              to="/About"
              className="pb-1 pt-2 px-1 navlink mx-md-2 mx-1"
            >
              About
            </NavLink>
            <NavLink
              to="/ContactUs"
              className="pb-1 pt-2 px-1 navlink mx-md-2 mx-1"
            >
              Contact
            </NavLink>
          </nav>
          <nav className="mt-2 mt-md-0 ml-md-auto">
            {Auth.isSignedIn() ? (
              <a href="/App" className="btn btn-outline-info ml-2">
                Go to Dashboard
              </a>
            ) : (
              <>
                <Link
                  style={{ borderWidth: "0.08rem" }}
                  to="/SignUp"
                  className="btn btn-sm btn-info mr-3"
                >
                  Sign Up
                </Link>
                <Link
                  style={{ borderWidth: "0.08rem" }}
                  to="/Auth/SignIn"
                  className="btn btn-sm btn-outline-info font-weight-500"
                >
                  Sign In
                </Link>
              </>
            )}
          </nav>
        </div>
      </div>
    </header>
  );
}

function Footer() {
  const thisYear = new Date().getFullYear();
  const years = thisYear > 2019 ? `2019 - ${thisYear}` : 2019;

  return (
    <footer className="bg-white">
      <div className="py-4 container">
        <div className="row">
          <div className="col-6 col-md">
            <h5 className="text-info">Features</h5>
            <ul className="list-unstyled text-small">
              <li>
                <a className="text-muted" href="##">
                  Cool stuff
                </a>
              </li>
              <li>
                <a className="text-muted" href="##">
                  Random feature
                </a>
              </li>
              <li>
                <a className="text-muted" href="##">
                  Team feature
                </a>
              </li>
              <li>
                <a className="text-muted" href="##">
                  Stuff for developers
                </a>
              </li>
            </ul>
          </div>
          <div className="col-6 col-md">
            <h5 className="text-info">Resources</h5>
            <ul className="list-unstyled text-small">
              <li>
                <a className="text-muted" href="##">
                  Resource
                </a>
              </li>
              <li>
                <a className="text-muted" href="##">
                  Resource name
                </a>
              </li>
              <li>
                <a className="text-muted" href="##">
                  Another resource
                </a>
              </li>
              <li>
                <a className="text-muted" href="##">
                  Final resource
                </a>
              </li>
            </ul>
          </div>
          <div className="col-6 col-md">
            <h5 className="text-info">About</h5>
            <ul className="list-unstyled text-small">
              <li>
                <a className="text-muted" href="##">
                  Team
                </a>
              </li>
              <li>
                <a className="text-muted" href="##">
                  Locations
                </a>
              </li>
              <li>
                <a className="text-muted" href="##">
                  Privacy
                </a>
              </li>
              <li>
                <a className="text-muted" href="##">
                  Terms
                </a>
              </li>
            </ul>
          </div>
          <div className="col-6 col-md">
            <img className="mb-2" src={logo} alt="" width="42" height="42" />
            <small className="d-block mb-3 text-muted">&copy; {years}</small>
          </div>
        </div>
      </div>
    </footer>
  );
}

export function Card(props) {
  return (
    <div
      className={"p-3 bg-white rounded shadow-sm " + (props.className || "")}
    >
      {props.children}
    </div>
  );
}

/*
<LayoutNavless 
  title="Sign in"
  text="To access dashboard" 
  errors={this.state.errors}
  backButtonTo="/Home"
  backButtonText="Home Page">
Children
</LayoutNavless>
*/
export function LayoutNavless(props) {
  if (props.title) document.title = props.title;

  return (
    <div className="website">
      <div className="h-100 w-100 fixed-top bg-light">
        <div className="row h-100 m-0">
          <div className="col-md-4 col-xs-12 my-auto p-sm-5 text-center">
            <img alt="Logo" src={logo} className="mx-auto" width="80" />
            <h4 className="text-black-50 font-weight-light">{props.title}</h4>
            <p className="text-black-50 mb-4">{props.text}</p>
            {props.errors && props.errors.length > 0 && (
              <ul className="pl-0" style={{ listStyle: "inside" }}>
                {props.errors.map((error, index) => (
                  <li key={index} className="text-danger">
                    {error}
                  </li>
                ))}
              </ul>
            )}

            {props.children}

            <Link
              to={props.backButtonTo}
              className="small text-black-50 mt-5 float-left"
            >
              <Icon
                path={mdiChevronLeft}
                size={0.8}
                className="mdi mr-1 text-black-50"
              />
              {props.backButtonText}
            </Link>
          </div>
          <div
            className="col-md-8 d-none d-md-block"
            style={{
              background:
                "url(https://picsum.photos/1200/800/?random&gravity=center) center no-repeat",
              backgroundSize: "cover",
            }}
          >
            <div className="d-inline-block float-right mt-3 px-2 py-1 rounded random-pic">
              Just a random picture
              <small className="d-block text-black-50">
                To make this page less boring :)
              </small>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export function AuthLayout() {
  return (
    <LayoutNavless
      title="Sign in"
      text="To access dashboard"
      // errors={this.state.errors}
      backButtonTo="/Home"
      backButtonText="Home Page"
    >
      <Outlet />
    </LayoutNavless>
  );
}

function Home() {
  return (
    <>
      <div className="jumbotron jumbotron-fluid bg-info text-white mt-n3">
        <div className="container">
          <h1 className="text-uppercase">Made faster, easier</h1>
          <p className="lead">
            This is a modified jumbotron that occupies the entire horizontal
            space of its parent.
          </p>
        </div>
      </div>
      <Page title="Welcome!">
        <h5>Home</h5>
        <p>
          Some quick example text to build on the card title and make up the
          bulk of the card's content.
        </p>
      </Page>
    </>
  );
}

function Page404() {
  return (
    <Page title="Page Not Found" card={false}>
      <Card className="text-center">
        <h1 className="mb-3">404</h1>
        <h3 className="text-danger">Page Not Found</h3>
      </Card>
    </Page>
  );
}

function Pricing() {
  return (
    <Page title="Pricing">
      <h5>Pricing</h5>
      <p>
        Some quick example text to build on the card title and make up the bulk
        of the card's content.
      </p>
    </Page>
  );
}

function Features() {
  return (
    <Page title="Features">
      <h5>Features</h5>
      <p>
        Some quick example text to build on the card title and make up the bulk
        of the card's content.
      </p>
    </Page>
  );
}

function ContactUs() {
  return (
    <Page title="Contact us">
      <h5>Contact Us</h5>
      <p>
        Some quick example text to build on the card title and make up the bulk
        of the card's content.
      </p>
    </Page>
  );
}
function About() {
  return (
    <Page title="About">
      <h5>About</h5>
      <h1>Continous deployment testing here</h1>
      <p>
        What is Lorem Ipsum? Lorem Ipsum is simply dummy text of the printing
        and typesetting industry. Lorem Ipsum has been the industry's standard
        dummy text ever since the 1500s, when an unknown printer took a galley
        of type and scrambled it to make a type specimen book. It has survived
        not only five centuries, but also the leap into electronic typesetting,
        remaining essentially unchanged. It was popularised in the 1960s with
        the release of Letraset sheets containing Lorem Ipsum passages, and more
        recently with desktop publishing software like Aldus PageMaker including
        versions of Lorem Ipsum. Why do we use it? It is a long established fact
        that a reader will be distracted by the readable content of a page when
        looking at its layout. The point of using Lorem Ipsum is that it has a
        more-or-less normal distribution of letters, as opposed to using
        'Content here, content here', making it look like readable English. Many
        desktop publishing packages and web page editors now use Lorem Ipsum as
        their default model text, and a search for 'lorem ipsum' will uncover
        many web sites still in their infancy. Various versions have evolved
        over the years, sometimes by accident, sometimes on purpose (injected
        humour and the like). Where does it come from? Contrary to popular
        belief, Lorem Ipsum is not simply random text. It has roots in a piece
        of classical Latin literature from 45 BC, making it over 2000 years old.
        Richard McClintock, a Latin professor at Hampden-Sydney College in
        Virginia, looked up one of the more obscure Latin words, consectetur,
        from a Lorem Ipsum passage, and going through the cites of the word in
        classical literature, discovered the undoubtable source. Lorem Ipsum
        comes from sections 1.10.32 and 1.10.33 of "de Finibus Bonorum et
        Malorum" (The Extremes of Good and Evil) by Cicero, written in 45 BC.
        This book is a treatise on the theory of ethics, very popular during the
        Renaissance. The first line of Lorem Ipsum, "Lorem ipsum dolor sit
        amet..", comes from a line in section 1.10.32.
      </p>
    </Page>
  );
}

class ResetPassword extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      errors: [],
      success: false,
      email: "",
      code: "",
      password: "",
      repassword: "",
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {
    const { code } = getUrlSearchParams();
    this.setState({ code });
  }

  handleChange(e) {
    this.setState({
      [e.target.name]:
        e.target.type === "checkbox" ? e.target.checked : e.target.value,
    });
  }

  handleSubmit() {
    const root = this;

    if (!isValidEmail(root.state.email)) {
      root.setState({ errors: ["Email address is not valid"] });
      return;
    }

    if (root.state.password !== root.state.repassword) {
      root.setState({ errors: ["Passwords did not match"] });
      return;
    }

    root.setState({ loading: true, errors: [] });

    axios
      .post(URLS.ACCOUNT.RESET_PASSWORD, {
        email: root.state.email,
        password: root.state.password,
        repassword: root.state.repassword,
        code: root.state.code,
      })
      .then(function (response) {
        if (response.data.success) {
          root.setState({ success: true });
        } else {
          root.setState({ errors: response.data.errors });
        }
      })
      .catch(function (response) {
        root.setState({ errors: [response.message] });
      })
      .then(function () {
        root.setState({ loading: false });
      });
  }

  render() {
    if (!this.state.code) return <ErrorPage errors={["Invalid page url!"]} />;

    return (
      <LayoutNavless
        title={this.state.success ? "Password updated" : "Reset password"}
        text={
          this.state.success
            ? "You can now sign in using your new password"
            : "Enter your email address and new password"
        }
        errors={this.state.errors}
        backButtonTo="/Auth/SignIn"
        backButtonText="Sign In"
      >
        {!this.state.success && (
          <div>
            <FloatingLabelInput
              type="text"
              name="email"
              onChange={this.handleChange}
              value={this.state.email}
              placeholder="Email address"
            />

            <FloatingLabelInput
              type="password"
              name="password"
              onChange={this.handleChange}
              value={this.state.password}
              placeholder="New password"
            />

            <FloatingLabelInput
              type="password"
              name="repassword"
              onChange={this.handleChange}
              value={this.state.repassword}
              placeholder="New password again"
            />

            <LoaderButton
              onClick={this.handleSubmit}
              className="btn-lg btn-info btn-block"
              loading={this.state.loading}
              loadingText="Saving..."
            >
              Save
            </LoaderButton>
          </div>
        )}
      </LayoutNavless>
    );
  }
}

class ForgotPassword extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      errors: [],
      success: false,
      email: "",
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChange(e) {
    this.setState({
      [e.target.name]:
        e.target.type === "checkbox" ? e.target.checked : e.target.value,
    });
  }

  handleSubmit() {
    const root = this;

    if (!isValidEmail(root.state.email)) {
      root.setState({ errors: ["Email address is not valid"] });
      return;
    }

    root.setState({ loading: true, errors: [] });

    axios
      .post(URLS.ACCOUNT.FORGOT_PASSWORD, {
        email: root.state.email,
      })
      .then(function (response) {
        if (response.data.success) {
          root.setState({ success: true });
        } else {
          root.setState({ errors: response.data.errors });
        }
      })
      .catch(function (response) {
        root.setState({ errors: [response.message] });
      })
      .then(function () {
        root.setState({ loading: false });
      });
  }

  render() {
    return (
      <LayoutNavless
        title={this.state.success ? "Email sent!" : "Forgot password ?"}
        text={
          this.state.success
            ? "Check you inbox to find email with instructions"
            : "Enter your email address to receive instructions to reset it"
        }
        errors={this.state.errors}
        backButtonTo="/Auth/SignIn"
        backButtonText="Sign In"
      >
        {!this.state.success && (
          <div>
            <FloatingLabelInput
              type="text"
              name="email"
              onChange={this.handleChange}
              value={this.state.email}
              placeholder="Email address"
            />

            <LoaderButton
              onClick={this.handleSubmit}
              className="btn-lg btn-info btn-block"
              loading={this.state.loading}
              loadingText="Loading..."
            >
              Send
            </LoaderButton>
          </div>
        )}
      </LayoutNavless>
    );
  }
}

/*
  <ErrorPage errors={["error1", "error2"]}>
*/
export function ErrorPage(props) {
  document.title = props.title ? props.title : "Something went wrong";
  return (
    <div className="h-100 w-100 fixed-top">
      <div className="row h-100 m-0">
        <div className="my-auto mx-auto text-center text-secondary">
          <h5>Errors encountered</h5>
          {props.errors && props.errors.length > 0 && (
            <ul className="pl-0 my-3" style={{ listStyle: "inside" }}>
              {props.errors.map((error, index) => (
                <li key={index} className="text-danger">
                  {error}
                </li>
              ))}
            </ul>
          )}
          <p>Contact support if you need help.</p>
          {/* This is outside of Router, so Link won't work */}
          <p>
            <a href="/" className="btn btn-outline-info">
              Home Page
            </a>
          </p>
        </div>
      </div>
    </div>
  );
}
class ConfirmEmail extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      errors: [],
      userId: "",
      code: "",
    };
  }

  componentDidMount() {
    const { userId, code } = getUrlSearchParams();
    const root = this;
    root.setState({ userId, code });

    axios
      .post(URLS.ACCOUNT.CONFIRM_EMAIL, { userId, code })
      .then(function (response) {
        if (!response.data.success && response.data.errors) {
          root.setState({ errors: response.data.errors });
        }
      })
      .catch(function (response) {
        root.setState({ errors: [response.message] });
      })
      .then(function () {
        root.setState({ loading: false });
      });
  }

  render() {
    if (this.state.loading) return <LoadingScreen />;
    if (!this.state.code || !this.state.userId)
      return <ErrorPage errors={["Invalid page url!"]} />;
    if (this.state.errors.length > 0)
      return <ErrorPage errors={this.state.errors} />;

    return (
      <LayoutNavless
        title="Email confirmed!"
        text="If your organization is already approved, you can sign in to your account"
        backButtonTo="/Auth/SignIn"
        backButtonText="Sign In"
      ></LayoutNavless>
    );
  }
}

export function LoadingScreen() {
  return (
    <div className="h-100 w-100 fixed-top">
      <div className="row h-100 m-0">
        <div className="my-auto mx-auto text-center">
          <div
            className="spinner-grow text-info"
            role="status"
            style={{ width: "3rem", height: "3rem" }}
          >
            <span className="sr-only">Loading...</span>
          </div>
        </div>
      </div>
    </div>
  );
}

class SignIn extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      errors: [],
      success: false,
      email: "",
      password: "",
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChange(e) {
    this.setState({
      [e.target.name]:
        e.target.type === "checkbox" ? e.target.checked : e.target.value,
    });
  }

  handleSubmit() {
    const root = this;

    if (!isValidEmail(root.state.email)) {
      root.setState({
        errors: ["Email address is not valid"],
      });
      return;
    }

    root.setState({
      loading: true,
    });

    axios
      .post(URLS.ACCOUNT.SIGN_IN, {
        email: root.state.email,
        password: root.state.password,
      })
      .then(function (response) {
        if (response.data.success === true) {
          Auth.signIn();
          var { redirect } = getUrlSearchParams();
          window.location.href = redirect || "/App";
        } else {
          root.setState({
            errors: response.data.errors,
          });
        }
      })
      .catch(function (response) {
        root.setState({ errors: [response.message] });
      })
      .then(function () {
        root.setState({ loading: false });
      });
  }

  render() {
    return (
      <LayoutNavless
        title="Sign in"
        text="To access dashboard"
        errors={this.state.errors}
        backButtonTo="/Home"
        backButtonText="Home Page"
      >
        <FloatingLabelInput
          type="text"
          name="email"
          onChange={this.handleChange}
          value={this.state.email}
          placeholder="Email address"
        />
        <FloatingLabelInput
          type="password"
          name="password"
          onChange={this.handleChange}
          value={this.state.password}
          placeholder="Password"
        />
        <LoaderButton
          onClick={this.handleSubmit}
          className="btn-lg btn-info btn-block"
          loading={this.state.loading}
          loadingText="Loading..."
        >
          Sign In
        </LoaderButton>
        <div
          className="mt-5 text-black-50"
          style={{
            fontSize: "15px",
          }}
        >
          <p>
            Forgot password ?{" "}
            <Link to="/Auth/ForgotPassword" className="text-info my-1">
              Reset
            </Link>
          </p>
          <p>
            Don't have account ?{" "}
            <Link to="/SignUp" className="text-info">
              {" "}
              Sign up{" "}
            </Link>
          </p>
        </div>
      </LayoutNavless>
    );
  }
}

class SignUp extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      errors: [],
      success: false,
      user: {
        email: "",
        password: "",
        rePassword: "",
        firstName: "",
        lastName: "",
      },
      organization: {
        name: "",
        // country: "",
        // province: "",
        // city: "",
        addressLine1: "",
        addressLine2: "",
        postalCode: "",
        phoneNumber: "",
        emailAddress: "",
      },
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChange(e) {
    const names = e.target.name.split(".");
    const value =
      e.target.type === "checkbox" ? e.target.checked : e.target.value;
    this.setState((state) => {
      state[names[0]][names[1]] = value;
      return { [names[0]]: state[names[0]] };
    });
  }

  handleSubmit() {
    const root = this;

    if (!isValidEmail(root.state.user.email)) {
      root.setState({ errors: ["User email address is not valid"] });
      return;
    }
    if (!isValidEmail(root.state.organization.emailAddress)) {
      root.setState({ errors: ["Organization email address is not valid"] });
      return;
    }
    if (root.state.user.password !== root.state.user.rePassword) {
      root.setState({ errors: ["Passwords did not match"] });
      return;
    }

    root.setState({ loading: true, errors: [] });

    let user = root.state.user;
    user.organization = root.state.organization;

    axios
      .post(URLS.ACCOUNT.SIGN_UP, user)
      .then(function (response) {
        if (response.data.success) {
          root.setState({ success: true });
        } else {
          root.setState({ errors: response.data.errors });
        }
      })
      .catch(function (response) {
        root.setState({ errors: [response.message] });
      })
      .then(function () {
        root.setState({ loading: false });
      });
  }

  render() {
    const title = this.state.success ? "Signed up successfully!" : "Sign Up";
    return (
      <Page title={title} card={false}>
        <h5 className="mt-4">{title}</h5>
        <p>
          {this.state.success
            ? "Please, check your inbox to confirm your email adress now. After you confirm your email address your organization account will be activated by one of the administrators. Meanwhile if you have any questions do not hesitate to reach us out."
            : "Please, fill the required fields below to get your account started"}
        </p>

        {this.state.errors && this.state.errors.length > 0 && (
          <ul className="pl-0 my-3" style={{ listStyle: "inside" }}>
            {this.state.errors.map((error, index) => (
              <li key={index} className="text-danger">
                {error}
              </li>
            ))}
          </ul>
        )}

        {!this.state.success && (
          <div className="row mt-4">
            <div className="col-md-6 pr-md-5">
              <h5 className="text-muted mb-4">User information</h5>
              <div className="row">
                <div className="col-6">
                  <FloatingLabelInput
                    type="text"
                    name="user.firstName"
                    onChange={this.handleChange}
                    value={this.state.user.firstName}
                    placeholder="First name"
                  />
                </div>
                <div className="col-6">
                  <FloatingLabelInput
                    type="text"
                    name="user.lastName"
                    onChange={this.handleChange}
                    value={this.state.user.lastName}
                    placeholder="Last name"
                  />
                </div>

                <div className="col-md-12">
                  <FloatingLabelInput
                    type="text"
                    name="user.email"
                    onChange={this.handleChange}
                    value={this.state.user.email}
                    placeholder="Email address"
                  />
                </div>
                <div className="col-6">
                  <FloatingLabelInput
                    type="password"
                    name="user.password"
                    onChange={this.handleChange}
                    value={this.state.user.password}
                    placeholder="Password"
                  />
                </div>

                <div className="col-6">
                  <FloatingLabelInput
                    type="password"
                    name="user.rePassword"
                    onChange={this.handleChange}
                    value={this.state.user.rePassword}
                    placeholder="Password again"
                  />
                </div>
              </div>
            </div>
            <div className="col-md-6 pl-md-5 border-left">
              <h5 className="text-muted mb-4">Organization information</h5>
              <div className="row">
                {/* <FloatingLabelInput
                    type="text"
                    name="organization.country"
                    onChange={this.handleChange}
                    value={this.state.organization.country}
                    placeholder="Country"
                  />

                  <FloatingLabelInput
                    type="text"
                    name="organization.province"
                    onChange={this.handleChange}
                    value={this.state.organization.province}
                    placeholder="Province"
                  />

                  <FloatingLabelInput
                    type="text"
                    name="organization.city"
                    onChange={this.handleChange}
                    value={this.state.organization.city}
                    placeholder="City"
                  /> */}

                <div className="col-md-12">
                  <FloatingLabelInput
                    type="text"
                    name="organization.name"
                    onChange={this.handleChange}
                    value={this.state.organization.name}
                    placeholder="Organization name"
                  />
                </div>
                <div className="col-md-8">
                  <FloatingLabelInput
                    type="text"
                    name="organization.addressLine1"
                    onChange={this.handleChange}
                    value={this.state.organization.addressLine1}
                    placeholder="Address line 1"
                  />
                </div>

                <div className="col-6 col-md-4">
                  <FloatingLabelInput
                    type="text"
                    name="organization.addressLine2"
                    onChange={this.handleChange}
                    value={this.state.organization.addressLine2}
                    placeholder="Address line 2"
                  />
                </div>
                <div className="col-6 col-md-4">
                  <FloatingLabelInput
                    type="text"
                    name="organization.postalCode"
                    onChange={this.handleChange}
                    value={this.state.organization.postalCode}
                    placeholder="Postal code"
                  />
                </div>
                <div className="col-6 col-md-4">
                  <FloatingLabelInput
                    type="text"
                    name="organization.phoneNumber"
                    onChange={this.handleChange}
                    value={this.state.organization.phoneNumber}
                    placeholder="Phone number"
                  />
                </div>
                <div className="col-6 col-md-4">
                  <FloatingLabelInput
                    type="text"
                    name="organization.emailAddress"
                    onChange={this.handleChange}
                    value={this.state.organization.emailAddress}
                    placeholder="Email address"
                  />
                </div>
              </div>
            </div>

            <div className="offset-md-4 col-md-4 mt-md-3">
              <LoaderButton
                onClick={this.handleSubmit}
                className="btn-lg btn-info btn-block"
                loading={this.state.loading}
                loadingText="Loading..."
              >
                Sign Up
              </LoaderButton>
            </div>
          </div>
        )}
      </Page>
    );
  }
}
