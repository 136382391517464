import { USER_ROLES } from "../constants/enums";

export default function reducer(state, action) {
  switch (action.type) {
    case "setCurrentUser":
      const currentUser = action.payload;
      currentUser.isAdmin =
        currentUser.roles && currentUser.roles.includes(USER_ROLES.admin);
      return { ...state, currentUser };
    case "setRentables":
      const rentables = { ...state.rentables, data: action.payload };
      return { ...state, rentables };
    default:
      return state;
  }
}
