import React from "react";
import FileInput from "../../../components/FileInput/FileInput";

const MAX_IMAGE_FILE_COUNT = 8; // same constant name as backend

export default class Images extends React.Component {
  constructor(props) {
    super(props);

    this.onDeleteImage = this.onDeleteImage.bind(this);
    this.onRestoreImage = this.onRestoreImage.bind(this);
  }

  onDeleteImage(originalUrl) {
    this.deleteOrRestore(originalUrl, true);
  }

  onRestoreImage(originalUrl) {
    this.deleteOrRestore(originalUrl, false);
  }

  deleteOrRestore(originalUrl, val) {
    let rentable = { ...this.props.rentable };
    let image = rentable.images.find((img) => img.image.url === originalUrl);
    image.isDeleted = val;

    this.props.onUpdateRentable(rentable);
  }

  render() {
    return (
      <div className="row">
        <FileInput
          disabled={this.props.isDisabled}
          name="imageFiles"
          accept="image"
          maxSize="10"
          maxFileCount={MAX_IMAGE_FILE_COUNT}
          multiple
          files={this.props.rentable.imageFiles}
          onChange={this.props.onChange}
          existingFileUrls={
            this.props.rentable.images &&
            this.props.rentable.images.map((i) => i.image.url)
          }
          onDeleteExistingFileUrl={this.onDeleteImage}
          onRestoreExistingFileUrl={this.onRestoreImage}
          outerClassName="col-md-3 mb-3"
        />
      </div>
    );
  }
}
