import React from "react";

/*
<CheckBox
  label="Accept rules"
  name="acceptRules"
  className="d-inline-block ml-4"
  checked={this.state.acceptRules}
  value={1}
  onChange={this.onAccept}
  disabled={true}
  required={true}
/>
*/
export default function CheckBox(props) {
    const id = `cb-${props.name}-${props.value}-${Math.floor(0, 100)}`;
    const required = props.required === true;
    const disabled = props.disabled === true;
  
    return (
      <div
        className={"custom-control custom-checkbox " + (props.className || "")}
      >
        <input
          type="checkbox"
          className="custom-control-input"
          id={id}
          name={props.name}
          onChange={props.onChange}
          value={props.value}
          checked={props.checked}
          required={required}
          disabled={disabled}
        />
        <label className="custom-control-label" htmlFor={id}>
          {required && <span>*</span>}
          {props.label}
        </label>
      </div>
    );
  }
  