import CONFIG from "./envConfig";

let urls = {
  ACCOUNT: {
    BASE: "Account",
    SIGN_OUT: "Account/SignOut",
    SIGN_IN: "Account/SignIn",
    SIGN_UP: "Account/SignUp",
    RESET_PASSWORD: "Account/ResetPassword",
    FORGOT_PASSWORD: "Account/ForgotPassword",
    CONFIRM_EMAIL: "Account/ConfirmEmail",
    UPDATE: "Account/Update",
    CHANGE_PASSWORD: "Account/ChangePassword",
  },
  USERS: {
    BASE: "Users",
    REINVITE: "Users/Reinvite",
    DELETE: "Users/Delete",
    ROLES: "Users/Roles",
  },
  ORGANIZATIONS: {
    BASE: "Organizations",
    CURRENT: "Organizations/Current",
  },
  RENTABLES: {
    BASE: "Rentables",
    DELETE: "Rentables/Delete",
    RESTORE: "Rentables/Restore",
  },
  RENTABLE_TYPES_FEATURES: {
    BASE: "RentableTypeFeatures",
    TYPES: "RentableTypeFeatures/Types",
    FEATURES: "RentableTypeFeatures/Features",
    FEATURES_BY_TYPE: "RentableTypeFeatures/Features/Type",
  },
  SETTINGS: {
    BASE: "Settings",
    TIMEZONES: "Settings/TimeZones",
    APP_VERSION: "Settings/AppVersion",
  },
  BOOKING_CLIENTS: {
    BASE: "BookingClients",
    BY_EMAIL: "BookingClients/Email",
  },
  BOOKING_ENTRIES: {
    BASE: "BookingEntries",
    BY_RENTABLE: "BookingEntries/Rentable",
    DELETE: "BookingEntries/Delete",
    UPDATE: "BookingEntries/Update",
    BY_REFERENCE_NUMBER: "BookingEntries/ReferenceNumber",
    REVIEW: "BookingEntries/Review",
    REVIEW_CANCEL: "BookingEntries/ReviewCancel",
    CANCEL: "BookingEntries/Cancel",
    ACTIVATE: "BookingEntries/Activate",
    APPROVE: "BookingEntries/Approve",
    DECLINE: "BookingEntries/Decline",
    SEARCH: "BookingEntries/Search",
  },
  LOCATIONS: {
    BASE: "Locations",
    BY_COUNTRY: "Locations/Country",
  },
  DASHBOARD: {
    BASE: "Dashboard",
  },
};

function addRootToUrls(obj) {
  Object.entries(obj).forEach(function ([key, value]) {
    if (value) {
      if (typeof value === "object") return addRootToUrls(value);
      else obj[key] = `${CONFIG.baseApiUrl}/${value}/`;
    }
  });
  return obj;
}

export const URLS = addRootToUrls(urls);
