import React from "react";
import { Link } from "react-router-dom";
import { BOOKING_DURATION_UNIT } from "./../../../constants/enums";

export default function RentablesGrid(props) {
  return (
    <div className="row">
      {props.rentables.map((rentable) => (
        <div
          key={rentable.id}
          className="col-12 col-sm-6 col-md-4 col-lg-3 mb-4"
        >
          <div className="card overflow-hidden">
            {rentable.isDeleted && (
              <div className="alert alert-warning position-absolute py-1 rounded-0 w-100">
                {`Archived on ${new Date(rentable.deletedDate).toDateString()}`}
              </div>
            )}
            <div
              className="d-flex bg-secondary justify-content-center"
              style={{ height: "150px" }}
            >
              {rentable.images && rentable.images[0] ? (
                <img
                  src={rentable.images[0].image.url}
                  style={{ objectFit: "cover" }}
                  className="card-img-top height-100"
                  alt={rentable.name}
                />
              ) : (
                <div className="align-self-center">No Image</div>
              )}
            </div>
            <div className="card-body">
              <h5 className="card-title">{rentable.name}</h5>
              <p className="card-text">
                ${rentable.fee} /{" "}
                {rentable.bookingDurationUnit ===
                BOOKING_DURATION_UNIT.day.value
                  ? "day"
                  : "hour"}
              </p>
              <Link
                to={"/App/Bookings/Calendar?rentableId=" + rentable.id}
                className="btn btn-primary"
              >
                Calendar
              </Link>
              <Link
                to={"/App/Rentables/Settings?id=" + rentable.id}
                className="btn btn-outline-secondary float-right"
              >
                Settings
              </Link>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
}
