import React from "react";
import axios from "axios";
import { URLS } from "../../constants/urls";
import Toast from "../../components/Toast/Toast";
import { orderBy, convertToFormData } from "../../helpers/utilities";
import { onFormChange, isValidEmail } from "../../helpers/utilities";
import LoaderButton from "../../components/LoaderButton/LoaderButton";
import NavTab from "../../components/NavTab/NavTab";
import FileInput from "../../components/FileInput/FileInput";
import {
  FloatingLabelInput,
  FloatingLabelTextArea,
  FloatingLabelSelect,
} from "../../components/FloatingLabelFormElements/FloatingLabelFormElements";
import Page from "../../components/_Others/Page";
import LocationSelect from "../../components/LocationSelect/LocationSelect";
import { MainContext } from './../../StateManagement/StateManagement';
import Actions from './../../StateManagement/actions';

export default class OrganizationSettings extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      saving: false,
      disabled: true,
      timeZones: [],
      organization: {
        id: null,
        name: "",
        description: "",
        countryId: "",
        provinceId: "",
        cityId: "",
        addressLine1: "",
        addressLine2: "",
        postalCode: "",
        phoneNumber: "",
        emailAddress: "",
        timeZoneId: "",
        logo: {}, // used to display and delete exiting file
        logoFile: null, // used to upload file
      },
    };

    this.currentUser = {};

    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  static contextType = MainContext;

  componentDidMount() {
    const root = this;
    root.currentUser = root.context.state.currentUser;

    if (root.currentUser) {
      root.setState((state) => {
        return {
          organization: {
            ...state.organization,
            ...root.currentUser.organization,
          },
        };
      });
    }

    axios
      .get(URLS.SETTINGS.TIMEZONES)
      .then(function (response) {
        if (response.data.success === true && response.data.data) {
          let timeZones = response.data.data.map((tz) => {
            return { value: tz[0], name: tz[1] };
          });
          orderBy(timeZones, "name");
          root.setState({ timeZones });
        } else if (response.data.errors) {
          Toast.error(response.data.errors);
        }
      })      
      .catch(function (response) {
        Toast.error(response.message, "Error fetching timezones");
      })
      .then(function () {
        root.setState({ loading: false });
      });
  }

  onChange(e) {
    const { name, value } = onFormChange(e);
    this.setState((state) => {
      let organization = { ...state.organization };
      organization[name] = value;
      return { organization };
    });
  }

  onSubmit() {
    const root = this;

    if (!isValidEmail(root.state.organization.emailAddress)) {
      Toast.error("Email address is not valid");
      return;
    }

    root.setState({ saving: true });

    const data = convertToFormData(this.state.organization);

    axios
      .post(URLS.ORGANIZATIONS.BASE + this.state.organization.id, data, {
        headers: { "Content-Type": "multipart/form-data" },
      })
      .then(function (response) {
        if (response.data.success === true) {
          if (response.data.data) {
            const organization = response.data.data;
            root.setState({ organization });

            // update current user's organization as well (in context)
            root.context.dispatch(
              Actions.setCurrentUser({ ...root.currentUser, organization })
            );
          }
          Toast.success("Changes saved");
        } else if (response.data.errors) {
          Toast.error(response.data.errors);
        }
      })
      .catch(function (response) {
        Toast.error(response.message, "Error updating organization");
      })
      .then(function () {
        root.setState({ saving: false });
      });
  }

  render() {
    return (
      <Page
        title="Organization Settings"
        loading={this.state.loading}
        type="tabs"
        extras={
          !this.currentUser.isAdmin ? (
            <div className="alert alert-info m-0 py-1">
              Only admins are allowed to make changes to organization settings.
            </div>
          ) : this.state.disabled ? (
            <button
              type="button"
              className="btn btn-outline-primary btn-block"
              onClick={() => this.setState({ disabled: false })}
            >
              Edit Settings
            </button>
          ) : (
            <LoaderButton
              onClick={this.onSubmit}
              className="btn-primary btn-block"
              loading={this.state.saving}
              loadingText="Saving..."
            >
              Save Changes
            </LoaderButton>
          )
        }
      >
        <NavTab
          tabs={[
            {
              name: "General",
              content: (
                <div className="row">
                  <div className="col-12 mb-md-3">
                    General settings for your organization
                  </div>

                  <div className="col-md-4 mb-3">
                    <FileInput
                      name="logoFile"
                      accept="image"
                      maxSize="10"
                      files={this.state.organization.logoFile}
                      onChange={this.onChange}
                      existingFileUrls={
                        this.state.organization.logo
                          ? this.state.organization.logo.url
                          : ""
                      }
                      onDeleteExistingFileUrl={() => {
                        this.setState((state) => {
                          let organization = { ...state.organization };
                          organization.logo.isDeleted = true;
                          return { organization };
                        });
                      }}
                      onRestoreExistingFileUrl={() => {
                        this.setState((state) => {
                          let organization = { ...state.organization };
                          organization.logo.isDeleted = false;
                          return { organization };
                        });
                      }}
                      disabled={this.state.disabled}
                      innerClassName="h-100"
                      outerClassName="h-100"
                    />
                  </div>

                  <fieldset disabled={this.state.disabled} className="col-md-8">
                    <div className="row">
                      <div className="col-md-4">
                        <FloatingLabelInput
                          type="text"
                          name="name"
                          placeholder="Organization name"
                          onChange={this.onChange}
                          value={this.state.organization.name}
                          required={true}
                        />
                      </div>

                      <LocationSelect
                        onChange={this.onChange}
                        countryName="countryId"
                        countryValue={this.state.organization.countryId}
                        countryClass="col-md-4"
                        countryRequired={true}
                        provinceName="provinceId"
                        provinceValue={this.state.organization.provinceId}
                        provinceClass="col-md-4"
                        cityName="cityId"
                        cityValue={this.state.organization.cityId}
                        cityClass="col-md-4"
                        cityRequired={true}
                      />

                      <div className="col-md-4">
                        <FloatingLabelInput
                          type="text"
                          name="addressLine1"
                          placeholder="Address line 1"
                          onChange={this.onChange}
                          value={this.state.organization.addressLine1}
                          required={true}
                        />
                      </div>
                      <div className="col-6 col-md-4">
                        <FloatingLabelInput
                          type="text"
                          name="addressLine2"
                          placeholder="Address line 2"
                          onChange={this.onChange}
                          value={this.state.organization.addressLine2}
                        />
                      </div>
                      <div className="col-6 col-md-2">
                        <FloatingLabelInput
                          type="text"
                          name="postalCode"
                          placeholder="Postal code"
                          onChange={this.onChange}
                          value={this.state.organization.postalCode}
                          required={true}
                        />
                      </div>
                      <div className="col-6 col-md-2">
                        <FloatingLabelInput
                          type="text"
                          name="phoneNumber"
                          placeholder="Phone number"
                          onChange={this.onChange}
                          value={this.state.organization.phoneNumber}
                          required={true}
                        />
                      </div>
                      <div className="col-6 col-md-3">
                        <FloatingLabelInput
                          name="emailAddress"
                          placeholder="Email address"
                          onChange={this.onChange}
                          value={this.state.organization.emailAddress}
                          required={true}
                        />
                      </div>
                      <div className="col-md-5">
                        <FloatingLabelSelect
                          name="timeZoneId"
                          placeholder="Timezone"
                          onChange={this.onChange}
                          value={this.state.organization.timeZoneId}
                        >
                          <option value="">Select timezone</option>
                          {this.state.timeZones.map((tz) => (
                            <option key={tz.value} value={tz.value}>
                              {tz.name}
                            </option>
                          ))}
                        </FloatingLabelSelect>
                      </div>
                      <div className="col-md-12">
                        <FloatingLabelTextArea
                          name="description"
                          placeholder="Description"
                          onChange={this.onChange}
                          value={this.state.organization.description}
                        />
                      </div>
                    </div>
                  </fieldset>
                </div>
              ),
            },
          ]}
        />
      </Page>
    );
  }
}
