import React from "react";
import axios from "axios";
import { onFormChange } from "../../helpers/utilities";
import Toast from "../../components/Toast/Toast";
import { URLS } from "../../constants/urls";
import { FloatingLabelInput } from "../../components/FloatingLabelFormElements/FloatingLabelFormElements";
import LoaderButton from "../../components/LoaderButton/LoaderButton";

export default class ChangePassword extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      oldPassword: "",
      password: "",
      rePassword: "",
    };

    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  onChange(e) {
    const { name, value } = onFormChange(e);
    this.setState({ [name]: value });
  }

  onSubmit() {
    const root = this;

    if (root.state.password !== root.state.rePassword) {
      Toast.error("Passwords did not match");
      return;
    }

    root.setState({ loading: true });

    axios
      .post(URLS.ACCOUNT.CHANGE_PASSWORD, {
        oldPassword: root.state.oldPassword,
        password: root.state.password,
        rePassword: root.state.rePassword,
      })
      .then(function (response) {
        if (response.data.success) {
          root.setState({
            oldPassword: "",
            password: "",
            rePassword: "",
          });

          Toast.success("Password updated successfully");
        } else {
          Toast.error(response.data.errors);
        }
      })
      .catch(function (response) {
        Toast.error(response.message, "Error updating password");
      })
      .then(function () {
        root.setState({ loading: false });
      });
  }

  render() {
    return (
      <div className="row">
        <fieldset disabled={this.props.disabled} className="col-md-4">
          <FloatingLabelInput
            type="password"
            name="oldPassword"
            placeholder="Old password"
            onChange={this.onChange}
            value={this.state.oldPassword}
          />
          <FloatingLabelInput
            type="password"
            name="password"
            placeholder="New password"
            onChange={this.onChange}
            value={this.state.password}
          />
          <FloatingLabelInput
            type="password"
            name="rePassword"
            placeholder="New password again"
            onChange={this.onChange}
            value={this.state.rePassword}
          />
          <div className="offset-md-8 col-md-4 px-0">
            <LoaderButton
              onClick={this.onSubmit}
              className="btn-primary btn-block"
              loading={this.state.loading}
              loadingText="Loading..."
            >
              Save
            </LoaderButton>
          </div>
        </fieldset>
      </div>
    );
  }
}
