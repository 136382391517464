import React from "react";
import Toast from "../../components/Toast/Toast";
import Page from "../../components/_Others/Page";
import Help from "../../components/Help/Help";
import { FloatingLabelInput } from "../../components/FloatingLabelFormElements/FloatingLabelFormElements";
import Graph from "../../components/Graph/Graph";

export default class Test extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showError: false,
    };
  }
  fireToast() {
    Toast.success("Toast successfully fired");
  }
  throwError() {
    console.log(test.id);
  }
  throwError2() {
    this.setState({ showError: true });
  }

  render() {
    return (
      <Page
        title="Dashboard"
        extras={
          <button
            className="btn btn-outline-info btn-block"
            onClick={() =>
              Toast.fire(
                "An info toast",
                [
                  "Some text may be here as line 1",
                  "This toast has 10 seconds delay",
                ],
                "info",
                10
              )
            }
          >
            Fire toast danger
          </button>
        }
      >
        <div className="row">
          <Graph
            className="col-md-4"
            maxHeight="200px"
            type="bar"
            data={{
              labels: ["Red", "Blue", "Yellow", "Green", "Purple", "Orange"],
              datasets: [
                {
                  label: "# of Votes",
                  data: [12, 19, 3, 5, 2, 3],
                  backgroundColor: [
                    "rgba(255, 99, 132, 0.2)",
                    "rgba(54, 162, 235, 0.2)",
                    "rgba(255, 206, 86, 0.2)",
                    "rgba(75, 192, 192, 0.2)",
                    "rgba(153, 102, 255, 0.2)",
                    "rgba(255, 159, 64, 0.2)",
                  ],
                  borderColor: [
                    "rgba(255, 99, 132, 1)",
                    "rgba(54, 162, 235, 1)",
                    "rgba(255, 206, 86, 1)",
                    "rgba(75, 192, 192, 1)",
                    "rgba(153, 102, 255, 1)",
                    "rgba(255, 159, 64, 1)",
                  ],
                  borderWidth: 1,
                },
              ],
            }}
            options={{
              scales: {
                yAxes: [
                  {
                    ticks: {
                      beginAtZero: true,
                    },
                  },
                ],
              }
            }}
          />
        </div>
        {this.state.showError && <p>{this.aaaaaa.id}</p>}
        Some quick example text to build on the card title and make up the bulk
        of the card's content. relsese test
        <Help>
          dustry. Lorem Ipsum has been the industry's standard dummy text ever
          since the 1500s, when an unknown printer took a galley of type and
          scrambled it to make a type specimen book. It has survived not only
          five centuries, but also the leap into electronic typesetting,
          remaining essentially unchanged. It was popularised in the 1960s with
          the release of Letraset sheets containing Lorem Ipsum passages, and
          more recently with desktop
          <button className="btn btn-link btn-sm">Read more</button>
        </Help>
        <div className="float-right">
          <Help>
            dustry. Lorem Ipsum has been the industry's standard dummy text ever
            since the 1500s, when an unknown printer took a galley of type and
            scrambled it to make a type specimen book. It has survived not only
            five centuries, but also the leap into electronic typesetting,
            remaining essentially unchanged. It was popularised in the 1960s
            with the release of Letraset sheets containing Lorem Ipsum passages,
            and more recently with desktop
          </Help>
        </div>
        <br />
        <button className="btn btn-success" onClick={() => this.fireToast()}>
          Fire toast
        </button>
        <br />
        <br />
        <button className="btn btn-danger" onClick={() => this.throwError()}>
          Throw js error
        </button>
        <br />
        <br />
        <button className="btn btn-danger" onClick={() => this.throwError2()}>
          Throw React error
        </button>
        <div style={{ position: "absolute", bottom: "50px", left: "10px" }}>
          <Help>
            dustry. Lorem Ipsum has been the industry's standard dummy text ever
            since the 1500s, when an unknown printer took a galley of type and
            scrambled it to make a type specimen book. It has survived not only
            five centuries, but also the leap into electronic typesetting,
            remaining essentially unchanged. It was popularised in the 1960s
            with the release of Letraset sheets containing Lorem Ipsum passages,
            and more recently with desktop
          </Help>
        </div>
        <div className="row">
          <div className="col-md-4 mt-5">
            <FloatingLabelInput
              type="text"
              name="name"
              placeholder="Name"
              help="Test one more thing here"
              required={true}
            />
          </div>
        </div>
      </Page>
    );
  }
}
