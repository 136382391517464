import React, { useRef, useEffect, useCallback } from "react";
import moment from "moment";
import { groupBy } from "underscore";
import { orderBy } from "../../../../helpers/utilities";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import "@fullcalendar/common/main.css";
import "@fullcalendar/daygrid/main.css";
import "@fullcalendar/timegrid/main.css";
import "./BookingCalendar.css";

/*
<BookingCalendar
  rentable={this.state.selectedRentable}
  rentableDailyBookable={true}
  bookingEntries={[]}
  onDateSelected={info=>onDateSelect(info)}
  onDatesRender={info=>onDateRender(info)}
  onEventClicked={info=>openBookingModal(info)}
  bookingStatusEnum={BOOKING_STATUS} // needed for coloring and slot availabibility bookings with different statuses
/>
*/
export default function BookingCalendar(props) {
  const calendarRef = useRef(null);

  useEffect(() => {
    // change calendar view on rentable change
    if (calendarRef && calendarRef.current) {
      const calendarApi = calendarRef.current.getApi();
      calendarApi.changeView(
        props.rentableDailyBookable ? "dayGridMonth" : "timeGridWeek"
      );
    }
  }, [props.rentableDailyBookable]);

  const combineAllEvents = useCallback(() => {
    const offDates = { events: [], color: "#ffff8f", offDate: true };
    if (
      props.rentable &&
      props.rentable.offDates &&
      props.rentable.offDates.length > 0
    ) {
      // non-working dates
      offDates.events = props.rentable.offDates.map((od) => {
        var date = moment(od.date)
          .set("year", moment().get("year"))
          .set("hour", 0)
          .set("minute", 0);
        const start = date.toDate();
        date.set("hour", 24).set("minute", 0);
        const end = date.toDate();
        return {
          start,
          end,
          title: od.reason,
          allDay: true,
        };
      });
    }

    const combined = [offDates];

    if (props.bookingEntries && props.bookingEntries.length > 0) {
      const allDay = props.rentableDailyBookable;
      const groupedBookings = groupBy(props.bookingEntries, "status");
      for (const status in groupedBookings) {
        const bookings = {
          events: groupedBookings[status].map((be) => {
            return {
              ...be,
              start: new Date(be.start),
              end: new Date(be.end),
              allDay,
            };
          }),
          color: Object.values(props.bookingStatusEnum).find(
            (bs) => bs.value === parseInt(status)
          ).color,
        };

        combined.push(bookings);
      }
    }

    return combined;
  }, [
    props.rentable,
    props.bookingEntries,
    props.rentableDailyBookable,
    props.bookingStatusEnum,
  ]);

  const getMinMaxTime = useCallback(() => {
    const minMax = ["00:00", "24:00"];
    if (
      props.rentable &&
      !props.rentableDailyBookable &&
      props.rentable.dayTimes &&
      props.rentable.dayTimes.length > 0
    ) {
      const dayTimes = props.rentable.dayTimes.map((dt) => {
        if (dt.endTime.startsWith("00:00")) {
          const _dt = { ...dt };
          _dt.endTime = "24:00"; // otherwise max time will not be correct
          return _dt;
        }
        return dt;
      });

      orderBy(dayTimes, "startTime");
      minMax[0] = dayTimes[0].startTime;
      orderBy(dayTimes, "endTime");
      minMax[1] = dayTimes[dayTimes.length - 1].endTime;
    }
    return minMax;
  }, [props.rentable, props.rentableDailyBookable]);

  const getHiddenDays = useCallback(() => {
    if (
      props.rentable &&
      props.rentable.dayTimes &&
      props.rentable.dayTimes.length > 0
    ) {
      let hiddenDays = [0, 1, 2, 3, 4, 5, 6];
      props.rentable.dayTimes.forEach((dt) => {
        if (hiddenDays.includes(dt.weekDay)) {
          hiddenDays = hiddenDays.filter((hd) => hd !== dt.weekDay);
        }
      });
      return hiddenDays;
    } else {
      alert("This rentable do not have working day/times set");
    }
    return [];
  }, [props.rentable]);

  const getBusinessHours = useCallback(() => {
    if (
      props.rentable &&
      !props.rentableDailyBookable &&
      props.rentable.dayTimes &&
      props.rentable.dayTimes.length > 0
    ) {
      return props.rentable.dayTimes.map((dt) => {
        return {
          daysOfWeek: [dt.weekDay],
          startTime: dt.startTime,
          endTime: dt.endTime.startsWith("00:00") ? "24:00" : dt.endTime, // otherwise last time slot won't be available
        };
      });
    }
    return null;
  }, [props.rentable, props.rentableDailyBookable]);

  const getMinMaxIntervals = useCallback(() => {
    // earliest and latest bookable dates
    let minInterval = moment().add(props.rentable.minInterval, "days");
    let maxInterval = moment().add(props.rentable.maxInterval, "days");

    if (props.rentableDailyBookable) {
      minInterval = minInterval
        .set("hour", 0)
        .set("minute", 0)
        .set("seconds", 0);
      maxInterval = maxInterval
        .set("hour", 24)
        .set("minute", 0)
        .set("seconds", 0);
    }

    minInterval.subtract(1, "minutes");
    maxInterval.add(1, "minutes");

    return [minInterval.toDate(), maxInterval.toDate()];
  }, [
    props.rentable.maxInterval,
    props.rentable.minInterval,
    props.rentableDailyBookable,
  ]);

  function isSlotAvailable(info) {
    let result = true;
    const eventSources = combineAllEvents();
    const start = info.start.getTime();
    const end = info.end.getTime();

    // earliest and latest bookable date
    const minMaxIntervals = getMinMaxIntervals();
    if (
      start < minMaxIntervals[0].getTime() ||
      end > minMaxIntervals[1].getTime()
    ) {
      return false;
    }

    // check offdate, exisiting booking status within given date time range
    for (let i = 0; i < eventSources.length; i++) {
      if (
        eventSources[i].events.find((e) => {
          const eStart = e.start.getTime();
          const eEnd = e.end.getTime();
          return (
            (e.status === props.bookingStatusEnum.active.value ||
              e.status === props.bookingStatusEnum.pending.value ||
              eventSources[i].offDate) &&
            ((start >= eStart && end <= eEnd) ||
              (eStart >= start && eEnd <= end))
          );
        })
      ) {
        result = false;
        break;
      }
    }

    return result;
  }

  function render() {
    return (
      props.rentable && (
        <FullCalendar
          ref={calendarRef}
          header={{
            left: "prev,next,today",
            center: "title",
            right: props.rentableDailyBookable
              ? "dayGridMonth,dayGridWeek"
              : "timeGridWeek,timeGridDay",
          }}
          defaultView={
            props.rentableDailyBookable ? "dayGridMonth" : "timeGridWeek"
          }
          plugins={[
            dayGridPlugin,
            interactionPlugin,
            timeGridPlugin,
            interactionPlugin,
          ]}
          contentHeight={500}
          longPressDelay={400} // for touch devices, the amount of time the user most hold down before an event becomes draggable or a date becomes selectable.
          nowIndicator={true}
          selectable={true} // multiple days selection via dragging
          select={props.onDateSelected} // select multiple dates start & end
          datesSet={props.onDatesRender} // called with every render - check time and fetch events from API
          eventClick={props.onEventClicked} // on existing event on calendar clicked
          eventSources={combineAllEvents()}
          minTime={getMinMaxTime()[0]} // min time to displayed in time grid
          maxTime={getMinMaxTime()[1]} // max time to displayed in time grid
          hiddenDays={getHiddenDays()}
          businessHours={getBusinessHours()}
          selectConstraint={
            // limit time selection to be only within business hours
            props.rentableDailyBookable ? null : "businessHours"
          }
          selectAllow={(info) => isSlotAvailable(info)} // define if slot selection/overlapping allowed in a custom way
          dayRender={(info) => {
            // render for every date/day slot
            if (
              // check if date is earlier than min interval and grey out background, can still be navigated to
              info.date.getTime() <= getMinMaxIntervals()[0].getTime()
            ) {
              info.el.bgColor = "#eaeaea";
            }
          }}
          validRange={() => {
            // define dates after max interval as invalid range so that can't be navigated and grayed out
            let end = getMinMaxIntervals()[1];
            if (props.rentableDailyBookable) {
              // end valid range will take the date as starting point therefore -1 days
              end = moment(end).subtract(1, "days").toDate();
            }
            return { end };
          }}
        />
      )
    );
  }

  return render();
}
