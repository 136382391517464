import React from "react";
import axios from "axios";
import { BrowserRouter } from "react-router-dom";
import "./assets/css/customizedBootstrap.scss";
import "./assets/css/style.scss";
import ErrorBoundary from "./components/_Others/ErrorBoundary";
import Init from "./components/_Others/Init";
import { StateManagement } from "./StateManagement/StateManagement";
import Auth from "./helpers/auth";
import Website from "./Website/Website";

// Authentication won't work without this. This will add auth cookies to requests.
axios.defaults.withCredentials = true;

const isApp = detectApp();

export default function App() {
  return (
    <StateManagement>
      <ErrorBoundary>
        <BrowserRouter>{isApp ? <Init /> : <Website />}</BrowserRouter>
      </ErrorBoundary>
    </StateManagement>
  );
}

function detectApp() {
  // if landed with url that starts with "app" in path name
  let isApp = window.location.pathname.toLocaleLowerCase().startsWith("/app");

  // if app but not signed in then redirect to SignIn page with redirect url
  if (isApp && !Auth.isSignedIn()) {
    window.location.href = `/Auth/SignIn?redirect=${window.location.pathname}`;
  }
  return isApp;
}
