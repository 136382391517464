import React from "react";
import Spinner from "../../../components/Spinner/Spinner";
import { USER_ROLES } from "../../../constants/enums";

export default function UsersTable(props) {
  return (
    <div className="table-responsive-sm">
      <table className="table table-hover no-wrap">
        <thead>
          <tr>
            <th scope="col">#</th>
            <th scope="col">First name</th>
            <th scope="col">Last name</th>
            <th scope="col">Email</th>
            <th scope="col">Roles</th>
            {(props.currentUser.isAdmin || props.type === "invited") && (
              <th scope="col" className="text-right">
                Action
              </th>
            )}
          </tr>
        </thead>
        <tbody>
          {props.users.map((user, index) => (
            <tr
              key={user.id}
              style={{ textDecoration: user.deleted ? "line-through" : "none" }}
            >
              <th scope="row">{index + 1}</th>
              <td>{user.firstName}</td>
              <td>{user.lastName}</td>
              <td>{user.email}</td>
              <td>
                {user.roles.map((role) => (
                  <span
                    key={role}
                    className={
                      "mr-1 badge badge-" +
                      (role === USER_ROLES.admin ? "warning" : "secondary")
                    }
                  >
                    {role}
                  </span>
                ))}
              </td>
              {user.deleted || user.loading ? (
                <td>{user.deleted ? "Deleted" : <Spinner />}</td>
              ) : (
                <td className="text-right">
                  {props.currentUser.isAdmin &&
                    user.id !== props.currentUser.id &&
                    props.type === "active" && (
                      <button
                        onClick={() => props.onRolesChange(user)}
                        className={
                          "btn btn-sm btn-sm-block " +
                          (user.roles.includes(USER_ROLES.admin)
                            ? "btn-outline-primary"
                            : "btn-outline-warning")
                        }
                        style={{ width: "100px" }}
                      >
                        {user.roles.includes(USER_ROLES.admin)
                          ? "Make User"
                          : "Make Admin"}
                      </button>
                    )}

                  {props.type === "invited" && (
                    <button
                      onClick={() => props.onReinvite(user)}
                      className="btn btn-sm btn-outline-primary"
                      disabled={user.reinvited}
                    >
                      {user.reinvited ? "Email Sent" : "Reinvite"}
                    </button>
                  )}

                  {props.currentUser.isAdmin &&
                    user.id !== props.currentUser.id && (
                      <button
                        onClick={() => props.onDelete(user)}
                        className="btn btn-sm btn-outline-secondary ml-2"
                      >
                        {props.type === "active" ? "Delete" : "Cancel"}
                      </button>
                    )}
                </td>
              )}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}
