import React, { useEffect, useMemo, useState } from "react";
import { BOOKING_TYPE } from "./../../../constants/enums";
import BookingStatus from "./../BookingStatus";
import { Icon } from "@mdi/react";
import { mdiHomeOutline } from "@mdi/js";
import { mdiWeb } from "@mdi/js";
import BookingStartEndTimes from "../BookingStartEndTimes";
import { indexKeyValue } from "../../../helpers/utilities";
import useStateManagement from './../../../StateManagement/StateManagement';

export default function Table({ bookings, viewBooking }) {
  const { state } = useStateManagement();
  const [rentableIdNameMappings, setRentableIdNameMappings] = useState({}); // rentable id/name key value pair

  useEffect(() => {
    setRentableIdNameMappings(
      indexKeyValue(state.rentables.data, "id", "name")
    );
  }, [state.rentables.data]);

  const tableBody = useMemo(() => {
    return bookings.map((b) => {
      const inHouseBooking = b.type === BOOKING_TYPE.inHouse.value;
      const rentableName = rentableIdNameMappings[b.rentableId];
      return (
        <tr key={b.id}>
          <td>{b.referenceNumber}</td>
          <td>{b.title}</td>
          <td>
            <BookingStartEndTimes start={b.start} end={b.end} />
          </td>
          <td>
            <BookingStatus status={b.status} className="m-0 my-n1" />
          </td>
          <td title={inHouseBooking ? "In house booking" : "Booked online"}>
            <Icon
              path={inHouseBooking ? mdiHomeOutline : mdiWeb}
              size={1}
              color={inHouseBooking ? "#818181" : "0ed2d2"}
              className="mdi"
            />
          </td>
          <td title={rentableName}>{rentableName}</td>
          <td title={b.client.emailAddress}>{b.client.fullName}</td>
          <td className="py-1 text-right">
            <button
              onClick={() => viewBooking(b)}
              className="btn btn-outline-primary view-btn"
            >
              View
            </button>
          </td>
        </tr>
      );
    });
  }, [bookings, viewBooking, rentableIdNameMappings]);

  function render() {
    return (
      <div className="table-responsive-sm" style={{ marginTop: ".85rem" }}>
        <table className="table table-hover no-wrap">
          <thead>
            <tr>
              <th width="115px">Reference #</th>
              <th>Title</th>
              <th>Date/Time</th>
              <th>Status</th>
              <th>Type</th>
              <th>Rentable</th>
              <th>Client</th>
              <th className="text-right">Action</th>
            </tr>
          </thead>
          <tbody>{tableBody}</tbody>
        </table>
      </div>
    );
  }
  return render();
}
