import React from "react";
import { FloatingLabelSelect } from "../../../components/FloatingLabelFormElements/FloatingLabelFormElements";

export default function OnlineBooking(props) {
  return (
    <fieldset disabled={props.isDisabled}>
      <div className="row">
        <div className="col-md-3">
          <FloatingLabelSelect
            name="onlineBookingAllowed"
            placeholder="Online booking"
            onChange={props.onChange}
            value={props.rentable.onlineBookingAllowed}
          >
            <option value={true}>Allowed</option>
            <option value={false}>Not allowed</option>
          </FloatingLabelSelect>
        </div>
        <div className="col-md-3">
          <FloatingLabelSelect
            name="bookingApprovalRequired"
            placeholder="Booking approval"
            onChange={props.onChange}
            value={props.rentable.bookingApprovalRequired}
            disabled={!props.rentable.onlineBookingAllowed}
          >
            <option value={true}>Required</option>
            <option value={false}>Not required</option>
          </FloatingLabelSelect>
        </div>
      </div>
    </fieldset>
  );
}
