import React, { useState, useRef } from "react";
import Icon from "@mdi/react";
import { mdiHelpCircle } from "@mdi/js";
import "./Help.css";

/*
<Help
  className="mt-4"
  style={{marginTop: "10px"}}
  size={1} // mdi icon size
  >
  Text here
</Help>
*/
export default function Help(props) {
  const [helpRect, sethelpRect] = useState({});
  const [helpTextRect, sethelpTextRect] = useState({});
  const helpTextRef = useRef();

  function onHover(e) {
    if (e.currentTarget && helpTextRef.current) {
      sethelpRect(e.currentTarget.getBoundingClientRect());
      sethelpTextRect(helpTextRef.current.getBoundingClientRect());
    }
  }

  function render() {
    let left = helpRect.left;
    let top = helpRect.top;
    if (left + helpTextRect.width > window.innerWidth) {
      left = window.innerWidth - helpTextRect.width - 10 + "px";
    }
    if (top + helpTextRect.height > window.innerHeight) {
      top = window.innerHeight - helpTextRect.height - 10 + "px";
    }

    return (
      <div
        className={"help " + (props.className || "")}
        style={props.style}
        onMouseOver={onHover}
      >
        <Icon path={mdiHelpCircle} size={props.size || 1} color="#848484" />
        <div
          className="help-text"
          ref={helpTextRef}
          style={{
            top: top,
            left: left,
          }}
        >
          {props.children}
        </div>
      </div>
    );
  }

  return render();
}
