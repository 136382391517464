import React, { useMemo, useReducer, useContext } from "react";
import reducer from "./reducer";

const initialState = {
  currentUser: null, // { id, email, firstName, lastName, roles, organization, isAdmin }
  rentables: {
    data: [],
    getDeleted: function (deleted) {
      return this.data.filter((r) => r.isDeleted === deleted);
    },
  },
};

// Export MainContext so that it can be used in class components or hook
export const MainContext = React.createContext();

export function StateManagement(props) {
  const [state, dispatch] = useReducer(reducer, initialState);

  const contextValue = useMemo(() => {
    return { state, dispatch };
  }, [state, dispatch]);

  return (
    <MainContext.Provider value={contextValue}>
      {props.children}
    </MainContext.Provider>
  );
}

export default function useStateManagement() {
  const context = useContext(MainContext);
  return { state: context.state, dispatch: context.dispatch };
}
