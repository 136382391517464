import React, { useState, useEffect } from "react";
import Swal from "sweetalert2";
import { onFormChange } from "./../../../helpers/utilities";
import Radio from "./../../../components/Radio/Radio";
import CheckBox from "./../../../components/CheckBox/CheckBox";
import CheckBoxCard from "./../../../components/CheckBoxCard/CheckBoxCard";

export default function Features(props) {
  const [rentableFeatures, setRentableFeatures] = useState([]);
  const [displayAll, setDisplayAll] = useState(true);

  useEffect(() => {
    if (props.rentableFeatures) {
      const booking = { ...props.booking };
      // as default add all free features to new booking
      if (!booking.id) {
        props.rentableFeatures.forEach((rf) => {
          if (!rf.fee) {
            booking.payment.features.push({
              rentableFeatureId: rf.id,
              fee: rf.fee,
            });
          }
        });
        props.updateBooking(booking);
      }

      // map existing booking.features so that they are displayed checked on load
      setRentableFeatures(
        props.rentableFeatures.map((rf) => {
          const _rf = { ...rf };
          if (
            booking.payment.features &&
            booking.payment.features.some((a) => a.rentableFeatureId === _rf.id)
          ) {
            _rf.checked = true;
          }
          return _rf;
        })
      );
    }
  }, []);

  function onChange(e) {
    let { value, checked } = onFormChange(e);
    const _rentableFeatures = rentableFeatures.map((rf) => {
      if (rf.id === parseInt(value)) {
        rf.checked = checked;
      }
      return rf;
    });
    updateBooking(_rentableFeatures);
  }

  function toggleFreeFeatures(e) {
    const _rentableFeatures = rentableFeatures.map((rf) => {
      if (!rf.fee) {
        rf.checked = e.target.checked;
      }
      return rf;
    });
    updateBooking(_rentableFeatures);
  }

  function updateBooking(_rentableFeatures) {
    setRentableFeatures(_rentableFeatures);

    const booking = { ...props.booking };
    booking.payment.featureFees = 0;
    booking.payment.features = [];
    _rentableFeatures.forEach((rf) => {
      if (rf.checked) {
        booking.payment.features.push({
          rentableFeatureId: rf.id,
          fee: rf.fee,
        });
        booking.payment.featureFees += rf.fee || 0;
      }
    });

    props.updateBooking(booking);
  }

  function render() {
    return (
      <div className="row">
        <div className="col-12 mb-3">
          <Radio
            label="Show selected"
            name="displayAll"
            className="d-inline-block ml-4 float-right"
            checked={!displayAll}
            value={false}
            onChange={() => setDisplayAll(false)}
          />
          <Radio
            label="Show all"
            name="displayAll"
            className="d-inline-block ml-4 float-right"
            checked={displayAll}
            value={true}
            onChange={() => setDisplayAll(true)}
          />
          <CheckBox
            label="Select all free features"
            name="checkFree"
            className="d-inline-block float-right"
            checked={rentableFeatures.every((a) => a.fee || a.checked)}
            value=""
            onChange={toggleFreeFeatures}
          />
        </div>
        {rentableFeatures.map((rf) => {
          return displayAll || rf.checked ? (
            <div key={rf.id} className="col-md-4 mb-3">
              <CheckBoxCard
                label={rf.feature.name}
                checked={rf.checked}
                value={rf.id}
                name="features"
                onChange={onChange}
                onLeftClick={() => Swal.fire(rf.feature.name, rf.description)}
                leftText={rf.description || "Description"}
                rightText={rf.fee ? "$" + rf.fee : "Free"}
                size="sm"
              />
            </div>
          ) : null;
        })}
      </div>
    );
  }
  return render();
}
