import React, { useState } from "react";
import Page from "./../../components/_Others/Page";
import NavTab from "./../../components/NavTab/NavTab";
import ChangePassword from "./ChangePassword";
import EditAccount from "./EditAccount";

export default function Account() {
  const [disabled, setDisabled] = useState(true);
  return (
    <Page
      title="Account"
      type="tabs"
      extras={
        disabled && (
          <button
            type="button"
            className="btn btn-outline-primary btn-block"
            onClick={() => setDisabled(false)}
          >
            Edit Account Settings
          </button>
        )
      }
    >
      <NavTab
        tabs={[
          {
            name: "Edit Acount",
            content: <EditAccount disabled={disabled} />,
          },
          {
            name: "Change Password",
            content: <ChangePassword disabled={disabled} />,
          },
        ]}
      />
    </Page>
  );
}
