import React from "react";
import moment from "moment";

/*
  <BookingStartEndTimes
    start={b.start}
    end={b.end}
    className="d-block"
  />
*/
const currentYear = moment().get("years");
export default function BookingStartEndTimes({ start, end, className }) {
  function render() {
    const mmtStart = moment(start);
    let mmtEnd = moment(end);
    const hourDiff = mmtEnd.diff(mmtStart, "hours");
    let showYear = currentYear !== mmtStart.get("years");
    let text = "";
    if (hourDiff < 24) {
      // hourly booked
      text = `${mmtStart.format(
        `DD MMM${showYear ? " YYYY" : ""} HH:mm`
      )} - ${mmtEnd.format("HH:mm ddd")}`;
    } else if (hourDiff === 24) {
      // single day booked
      text = mmtStart.format(`DD MMM${showYear ? " YYYY" : ""} ddd`);
    } else {
      // multiple days booked
      mmtEnd = mmtEnd.subtract(1, "minutes"); // make it to the end of last day, instead of next day's beginning
      showYear = showYear || currentYear !== mmtEnd.get("years");
      text = `${mmtStart.format(
        `DD MMM${showYear ? " YYYY" : ""} ddd`
      )} - ${mmtEnd.format(`DD MMM${showYear ? "YYYY" : ""} ddd`)}`;
    }
    return <span className={className || ""}>{text}</span>;
  }
  return render();
}
