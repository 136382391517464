import React from "react";
import MultiSelect from "./../../../components/MultiSelect/MultiSelect";
import { BOOKING_TYPE, BOOKING_STATUS } from "./../../../constants/enums";
import {
  FloatingLabelSelect,
  FloatingLabelInput,
} from "../../../components/FloatingLabelFormElements/FloatingLabelFormElements";
import DatePicker from "../../../components/DatePicker/DatePicker";
import moment from "moment";
import { onFormChange } from "../../../helpers/utilities";

const dateRangeOptions = {
  last30Days: { name: "Last 30 days", amount: -30, type: "days" },
  last7Days: { name: "Last 7 days", amount: -7, type: "days" },
  last24Hours: { name: "Last 24 hours", amount: -24, type: "hours" },
  next24Hours: { name: "Next 24 hours", amount: 24, type: "hours" },
  next7Days: { name: "Next 7 days", amount: 7, type: "days" },
  next30Days: { name: "Next 30 days", amount: 30, type: "days" },
  allTimes: { name: "All times", amount: null, type: "" },
};

export default function FilterArea({
  showAdvanced,
  searchModel,
  setSearchModel,
  activeRentables,
  deletedRentables,
}) {
  function onRentablesChange(e) {
    let { value, checked } = onFormChange(e);
    const _searchModel = { ...searchModel };

    if (value === "all") {
      // keep selected deleted rentables' ids
      const selectedDeleted = _searchModel.rentableIds.filter((rid) =>
        deletedRentables.find((r) => r.id === rid)
      );
      // select/unselect all active rentables' ids
      _searchModel.rentableIds = checked
        ? selectedDeleted.concat(activeRentables.map((r) => r.id))
        : selectedDeleted;
    } else if (value === "all-more") {
      // keep selected active rentables' ids
      const selectedActive = _searchModel.rentableIds.filter((rid) =>
        activeRentables.find((r) => r.id === rid)
      );
      // select/unselect all deleted rentables' ids
      _searchModel.rentableIds = checked
        ? selectedActive.concat(deletedRentables.map((r) => r.id))
        : selectedActive;
    } else {
      value = parseInt(value);
      _searchModel.rentableIds = checked
        ? _searchModel.rentableIds.concat(value)
        : _searchModel.rentableIds.filter((rid) => rid !== value);
    }
    setSearchModel(_searchModel);
  }

  function onTypesChange(e) {
    let { value, checked } = onFormChange(e);
    const _searchModel = { ...searchModel };

    value = parseInt(value);
    _searchModel.types = checked
      ? _searchModel.types.concat(value)
      : (_searchModel.types = _searchModel.types.filter((t) => t !== value));

    setSearchModel(_searchModel);
  }

  function onStatusesChange(e) {
    let { value, checked } = onFormChange(e);
    const _searchModel = { ...searchModel };

    if (value === "all") {
      _searchModel.statuses = checked
        ? Object.values(BOOKING_STATUS).map((s) => s.value)
        : [];
    } else {
      value = parseInt(value);
      _searchModel.statuses = checked
        ? _searchModel.statuses.concat(value)
        : (_searchModel.statuses = _searchModel.statuses.filter(
            (s) => s !== value
          ));
    }
    setSearchModel(_searchModel);
  }

  function onDateRangeChange(e) {
    let { value } = e.target;
    const _searchModel = { ...searchModel, dateRange: value };
    if (value) {
      const { amount, type } = dateRangeOptions[value];
      let now = null;
      let then = null;
      if (amount) {
        now = new Date();
        then = moment(now).add(amount, type).toDate();
      }

      if (now < then) {
        _searchModel.start = now;
        _searchModel.end = then;
      } else {
        _searchModel.start = then;
        _searchModel.end = now;
      }
    }
    setSearchModel(_searchModel);
  }

  function onDatesChange(selectedDate, kind = "start") {
    setSearchModel({ ...searchModel, dateRange: "", [kind]: selectedDate });
  }

  function onAdvancedFiltersChange(e) {
    const { name, value } = e.target;
    const _searchModel = { ...searchModel, [name]: value };
    setSearchModel(_searchModel);
  }

  function render() {
    return (
      <>
        <div className="row">
          <div className="col-md-3">
            <MultiSelect
              name="rentableIds"
              showSelectAll={true}
              onChange={onRentablesChange}
              options={activeRentables.map((r) => {
                return {
                  name: r.name,
                  value: r.id,
                  checked: searchModel.rentableIds.includes(r.id),
                };
              })}
              placeholder="Rentables"
              showMoreButton={deletedRentables.length > 0}
              moreButtonClick={() => {}}
              moreButtonTexts={["Show archived", "Hide archived"]}
              moreOptions={deletedRentables.map((r) => {
                return {
                  name: r.name,
                  value: r.id,
                  checked: searchModel.rentableIds.includes(r.id),
                };
              })}
            />
          </div>
          <div className="col-md-2">
            <MultiSelect
              name="types"
              onChange={onTypesChange}
              options={Object.values(BOOKING_TYPE).map((t) => {
                return {
                  ...t,
                  checked: searchModel.types.includes(t.value),
                };
              })}
              placeholder="Types"
            />
          </div>
          <div className="col-md-2">
            <MultiSelect
              name="statuses"
              showSelectAll={true}
              onChange={onStatusesChange}
              options={Object.values(BOOKING_STATUS).map((s) => {
                return {
                  ...s,
                  checked: searchModel.statuses.includes(s.value),
                };
              })}
              placeholder="Statuses"
            />
          </div>
          <div className="col-md-5">
            <div className="row">
              <div className="col-md-4">
                <FloatingLabelSelect
                  name="dateRangeOptions"
                  placeholder="Date range"
                  onChange={onDateRangeChange}
                  value={searchModel.dateRange}
                >
                  <option value="">Not set</option>
                  {Object.keys(dateRangeOptions).map((key) => (
                    <option key={key} value={key}>
                      {dateRangeOptions[key].name}
                    </option>
                  ))}
                </FloatingLabelSelect>
              </div>
              <div className="col-6 col-md-4">
                <DatePicker
                  onDatesSelect={(date) => onDatesChange(date, "start")}
                  selectedDates={searchModel.start}
                  label="From date"
                  maxDate={searchModel.end}
                />
              </div>
              <div className="col-6 col-md-4">
                <DatePicker
                  onDatesSelect={(date) => onDatesChange(date, "end")}
                  selectedDates={searchModel.end}
                  label="To date"
                  minDate={searchModel.start}
                />
              </div>
            </div>
          </div>
        </div>
        {showAdvanced && (
          <div className="row">
            <div className="col-6 col-md-3">
              <FloatingLabelInput
                type="text"
                placeholder="Reference number"
                name="referenceNumber"
                value={searchModel.referenceNumber}
                onChange={onAdvancedFiltersChange}
                autoComplete={false}
                help="Enter exact reference number to search. Case of the letters doesn't matter. If a reference number is set to be searched, then all other filter options will be ignored"
              />
            </div>
            <div className="col-6 col-md-3">
              <FloatingLabelInput
                type="text"
                placeholder="Title"
                name="title"
                value={searchModel.title}
                onChange={onAdvancedFiltersChange}
                autoComplete={false}
              />
            </div>
            <div className="col-6 col-md-3">
              <FloatingLabelInput
                type="text"
                placeholder="Client name"
                name="clientName"
                value={searchModel.clientName}
                onChange={onAdvancedFiltersChange}
                autoComplete={false}
              />
            </div>
            <div className="col-6 col-md-3">
              <FloatingLabelInput
                type="text"
                placeholder="Client email"
                name="clientEmail"
                value={searchModel.clientEmail}
                onChange={onAdvancedFiltersChange}
                autoComplete={false}
              />
            </div>
          </div>
        )}
        <div className="border-bottom mb-3 pb-0 d-none d-md-block"></div>
      </>
    );
  }
  return render();
}
