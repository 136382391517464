import React from "react";
import { Routes, Route } from "react-router-dom";
import Dashboard from "../../pages/Dashboard/Dashboard";
import Account from "../../pages/Account/Account";
import Page404 from "../../pages/Page404/Page404";
import OrganizationSettings from "../../pages/Organization/OrganizationSettings";
import Rentables from "../../pages/Rentables/Rentables/Rentables";
import RentableSettings from "../../pages/Rentables/RentableSettings/RentableSettings";
import RentableAdd from "../../pages/Rentables/RentableAdd";
import UserManagement from "../../pages/Organization/UserManagement/UserManagement";
import BookingsCalendarView from "../../pages/Bookings/BookingsCalendarView/BookingsCalendarView";
import BookingsListView from "../../pages/Bookings/BookingsListView/BookingsListView";
import Test from "../../pages/Test/Test";
import Layout from "./Layout/Layout";

export default function RoutesList() {
  return (
    <Routes>
      <Route path="/App" element={<Layout />}>
        <Route index element={<Dashboard />} />
        <Route path="Dashboard" element={<Dashboard />} />
        <Route path="Organization">
          <Route index element={<OrganizationSettings />} />
          <Route path="Settings" element={<OrganizationSettings />} />
          <Route path="Users" element={<UserManagement />} />
        </Route>
        <Route path="Rentables">
          <Route index element={<Rentables />} />
          <Route path="Add" element={<RentableAdd />} />
          {/* class component with url param "?id=" */}
          <Route path="Settings" element={<RentableSettings />} />
        </Route>
        <Route path="Bookings">
          <Route index element={<BookingsListView />} />
          <Route path=":referenceNumber?" element={<BookingsListView />} />
          {/* class component with url param "?rentableId=" */}
          <Route path="Calendar" element={<BookingsCalendarView />} />
        </Route>
        <Route path="Account" element={<Account />} />
        <Route path="Test" element={<Test />} />
        <Route path="Page404" element={<Page404 />} />
        <Route path="*" element={<Page404 />} />
      </Route>
    </Routes>
  );
}
