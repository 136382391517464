import React from "react";
import {
  FloatingLabelInput,
  FloatingLabelSelect,
} from "./../../../components/FloatingLabelFormElements/FloatingLabelFormElements";
import Modal from "./../../../components/Modal/Modal";
import {
  updateElementPropertyInList,
  orderBy,
} from "../../../helpers/utilities";
import Toast from "../../../components/Toast/Toast";
import { onFormChange } from "./../../../helpers/utilities";

export default class OffDates extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedOffDate: {},
      open: false,
    };

    this.onChange = this.onChange.bind(this);
    this.onEdit = this.onEdit.bind(this);
    this.onDelete = this.onDelete.bind(this);

    this.months = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];

    this.defaultOffDate = {
      month: 1,
      day: "",
      reason: "",
      date: null,
      isDeleted: false,
    };
  }

  componentDidMount() {
    this.feedDaysAndMonths();
  }

  componentDidUpdate() {
    // this will be needed when changes saved and new returned offdates do not have their days/months
    if (
      this.props.rentable.offDates.some((a) => isNaN(a.day) || isNaN(a.month))
    ) {
      this.feedDaysAndMonths();
    }
  }

  feedDaysAndMonths() {
    const rentable = { ...this.props.rentable };
    let offDates = rentable.offDates;

    offDates.forEach((od) => {
      const date = new Date(od.date);
      od.day = date.getDate();
      od.month = date.getMonth() + 1;
    });

    offDates = orderBy(offDates, "date");
    this.props.onUpdateRentable(rentable);
  }

  onChange(e) {
    let { name, value } = onFormChange(e);
    let selectedOffDate = { ...this.state.selectedOffDate };

    if (name === "reason") {
      selectedOffDate[name] = value.substring(0, 50);
    } else {
      selectedOffDate[name] = parseInt(value);
      const lastDayOfMonth = new Date(2000, selectedOffDate.month, 0).getDate(); // February had 29 days in 2000
      if (selectedOffDate.day !== "") {
        if (selectedOffDate.day < 1) {
          selectedOffDate.day = 1;
        } else if (selectedOffDate.day > lastDayOfMonth) {
          selectedOffDate.day = lastDayOfMonth;
        }
      }
    }
    this.setState({ selectedOffDate });
  }

  onEdit(offDate) {
    this.setState({ selectedOffDate: { ...offDate }, open: true });
  }

  onSave(closeModal) {
    const rentable = { ...this.props.rentable };
    let offDates = rentable.offDates;
    let selectedOffDate = { ...this.state.selectedOffDate };
    const existing = offDates.find(
      (a) =>
        a.month === selectedOffDate.month &&
        a.day === selectedOffDate.day &&
        a.date !== selectedOffDate.date &&
        !a.isDeleted
    );
    if (existing) {
      Toast.error(
        `This date already exists in records ${
          existing.reason ? "with reason: " + existing.reason : ""
        }`
      );
      return;
    }

    const index = offDates.findIndex(
      (d) => d.date === selectedOffDate.date && !d.isDeleted
    );

    // February had 29 days in 2000
    // Make sure there are preceding 0's
    selectedOffDate.date = `2000-${("0" + selectedOffDate.month).slice(-2)}-${(
      "0" + selectedOffDate.day
    ).slice(-2)}`;

    if (index > -1) {
      offDates.splice(index, 1);
    }

    offDates.push(selectedOffDate);
    offDates = orderBy(offDates, "date");

    // update parent component
    this.props.onUpdateRentable(rentable);

    // reset selected
    this.setState({ selectedOffDate: { ...this.defaultOffDate } });

    if (closeModal) {
      this.setState({ open: false });
    }
  }

  onDelete(offDate) {
    const rentable = { ...this.props.rentable };
    rentable.offDates = updateElementPropertyInList(
      rentable.offDates,
      "date",
      offDate.date,
      "isDeleted",
      true
    );

    // update parent component
    this.props.onUpdateRentable(rentable);
  }

  render() {
    const offDates = this.props.rentable.offDates;

    return (
      <fieldset disabled={this.props.isDisabled}>
        <div className="row">
          <div className="col-md-9">
            Holidays and other dates that this rentable can not be booked
          </div>
          <div className="col-md-3">
            {/* show only if admin */}

            <button
              onClick={() =>
                this.setState({
                  selectedOffDate: { ...this.defaultOffDate },
                  open: true,
                })
              }
              type="button"
              className="btn btn-primary float-md-right btn-xs-block mt-2 mt-md-0 mb-4"
            >
              Add New Date
            </button>

            <Modal
              header="Add new date"
              open={this.state.open}
              onClose={() => this.setState({ open: false })}
              bodyClass="bg-light"
              footer={
                <>
                  {!this.state.selectedOffDate.date && (
                    <button
                      onClick={() => this.onSave(false)}
                      className="btn btn-outline-primary"
                      disabled={
                        !this.state.selectedOffDate.day ||
                        isNaN(this.state.selectedOffDate.day) ||
                        isNaN(this.state.selectedOffDate.month)
                      }
                    >
                      Add Next
                    </button>
                  )}

                  <button
                    onClick={() => this.onSave(true)}
                    className="btn btn-primary"
                    disabled={
                      !this.state.selectedOffDate.day ||
                      isNaN(this.state.selectedOffDate.day) ||
                      isNaN(this.state.selectedOffDate.month)
                    }
                  >
                    {this.state.selectedOffDate.date ? "Update" : "Add"}
                  </button>
                </>
              }
            >
              <div className="row">
                <div className="col-8">
                  <FloatingLabelSelect
                    name="month"
                    placeholder="Month"
                    onChange={this.onChange}
                    value={this.state.selectedOffDate.month}
                  >
                    {this.months.map((month, index) => {
                      const value = index + 1;
                      return (
                        <option key={value} value={value}>
                          {month}
                        </option>
                      );
                    })}
                  </FloatingLabelSelect>
                </div>
                <div className="col-4">
                  <FloatingLabelInput
                    type="number"
                    name="day"
                    placeholder="Day"
                    autoComplete={false}
                    onChange={this.onChange}
                    value={this.state.selectedOffDate.day}
                  />
                </div>
                <div className="col-md-12">
                  <FloatingLabelInput
                    type="text"
                    name="reason"
                    placeholder="Reason"
                    autoComplete={false}
                    onChange={this.onChange}
                    value={this.state.selectedOffDate.reason}
                  />
                  <small className="font-italic ml-2 mt-n3 position-absolute text-muted">
                    For example: "New Years Eve"
                  </small>
                </div>
              </div>
            </Modal>
          </div>

          <div className="col-md-12">
            {offDates.length === 0 ? (
              <div className="alert alert-secondary">
                No dates have been added yet
              </div>
            ) : (
              <div className="table-responsive-sm">
                <table className="table table-hover no-wrap">
                  <thead>
                    <tr>
                      <th scope="col" width="50px">
                        Day
                      </th>
                      <th scope="col" width="150px">
                        Month
                      </th>
                      <th scope="col">Reason</th>
                      <th scope="col" className="text-right">
                        Action {/* show only if admin */}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {offDates.map((offDate) => (
                      <tr
                        key={offDate.date}
                        style={{
                          textDecoration: offDate.isDeleted
                            ? "line-through"
                            : "none",
                        }}
                      >
                        <td>{offDate.day}</td>
                        <td>{this.months[offDate.month - 1]}</td>
                        <td>{offDate.reason}</td>
                        <td className="text-right">
                          {/* show only if admin */}
                          {!offDate.isDeleted && (
                            <>
                              <button
                                onClick={() => this.onEdit(offDate)}
                                className="btn btn-sm btn-outline-primary"
                              >
                                Edit
                              </button>
                              <button
                                onClick={() => this.onDelete(offDate)}
                                className="btn btn-sm btn-outline-secondary ml-2"
                              >
                                Delete
                              </button>
                            </>
                          )}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            )}
          </div>
        </div>
      </fieldset>
    );
  }
}
