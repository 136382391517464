import React from "react";
import { FloatingLabelInput } from "./../../../components/FloatingLabelFormElements/FloatingLabelFormElements";

export default function Intervals(props) {
  return (
    <fieldset disabled={props.isDisabled}>
      <div className="row">
        <div className="col-12 mb-3">
          Intervals define the date range that rentable will be allowed to be booked. Use
          negative numbers to allow past dates to be booked
        </div>
        <div className="col-md-3">
          <FloatingLabelInput
            type="number"
            name="maxInterval"
            placeholder="Maximum interval"
            autoComplete={false}
            onChange={props.onChange}
            value={props.rentable.maxInterval}
            help="Maximum number of days from current date that bookings can be made. For example, if this value is set to be 15 and today's date is June 15, latest bookable date available will be June 30"
          />
        </div>
        <div className="col-md-3">
          <FloatingLabelInput
            type="number"
            name="minInterval"
            placeholder="Minumum interval"
            autoComplete={false}
            onChange={props.onChange}
            value={props.rentable.minInterval}
            help="Minimum number of days from current date that bookings can be made. For example, if this value is set to be 1 and today's date is June 15, ealiest bookable date available will be June 16"
          />
        </div>
      </div>
    </fieldset>
  );
}
