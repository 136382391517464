import React from "react";
import axios from "axios";
import Swal from "sweetalert2";
import { Navigate } from "react-router-dom";
import { onFormChange } from "../../helpers/utilities";
import { URLS } from "../../constants/urls";
import Toast from "../../components/Toast/Toast";
import Page from "../../components/_Others/Page";
import LoaderButton from "../../components/LoaderButton/LoaderButton";
import NavTab from "../../components/NavTab/NavTab";
import General from "./RentableSettings/General";
import { MainContext } from './../../StateManagement/StateManagement';
import Actions from './../../StateManagement/actions';

export default class RentableAdd extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      saving: false,
      rentableTypeOptions: [],
      rentable: {
        name: "",
        typeId: "",
        description: "",
        secretDetails: "",
        bookingDurationUnit: 0,
        interval: 90,
      },
    };

    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.onUpdateRentableTypesOptions = this.onUpdateRentableTypesOptions.bind(
      this
    );
  }

  static contextType = MainContext;

  onChange(e) {
    const { name, value } = onFormChange(e);
    this.setState((state) => {
      let rentable = { ...state.rentable };
      rentable[name] = value;
      return { rentable };
    });
  }

  onSubmit() {
    const root = this;

    root.setState({ saving: true });

    axios
      .post(URLS.RENTABLES.BASE, root.state.rentable)
      .then(function (response) {
        if (response.data.success === true && response.data.data) {
          const rentablesData = root.context.state.rentables.data.concat(
            response.data.data
          );
          root.context.dispatch(Actions.setRentables(rentablesData));

          Swal.fire(
            "Done",
            "Rentable created. You can now add more details to it",
            "success"
          ).then(() => root.setState({ rentable: response.data.data }));
        } else if (response.data.errors) {
          Toast.error(response.data.errors);
        }
      })
      .catch(function (response) {
        Toast.error(response.message, "Error adding rentable");
      })
      .then(function () {
        root.setState({ saving: false });
      });
  }

  onUpdateRentableTypesOptions(options) {
    const state = { rentableTypeOptions: options };
    if (!this.state.rentable.typeId) {
      state.rentable = { ...this.state.rentable, typeId: options[0].value }; //default selected type
    }
    this.setState(state);
  }

  render() {
    if (this.state.rentable.id) {
      return <Navigate to={"/App/Rentables/Settings?id=" + this.state.rentable.id} />;
    }

    return (
      <Page
        title="Add New Rentable"
        adminOnly
        type="tabs"
        extras={
          <LoaderButton
            onClick={this.onSubmit}
            className="btn-primary btn-block"
            loading={this.state.saving}
            loadingText="Saving..."
          >
            Save Changes
          </LoaderButton>
        }
      >
        <NavTab
          tabs={[
            {
              name: "General",
              content: (
                <General
                  onChange={this.onChange}
                  rentable={this.state.rentable}
                  rentableTypeOptions={this.state.rentableTypeOptions}
                  onUpdateRentableTypesOptions={
                    this.onUpdateRentableTypesOptions
                  }
                />
              ),
            },
          ]}
        />
      </Page>
    );
  }
}
