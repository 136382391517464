export default class Storage {
  // Strores given data in storage under given key
  // Will convert array or object into string before storing
  static set(storage, key, data) {
    storage.setItem(
      key,
      typeof data === "object" || Array.isArray(data)
        ? JSON.stringify(data)
        : data
    );
  }

  // Fetches data from storage with given key
  // Will convert data into array or object if needed
  static get(storage, key) {
    const data = storage.getItem(key);
    if (data && (data.startsWith("{") || data.startsWith("["))) {
      return JSON.parse(data);
    }
    return data;
  }

  static setInSession(key, data) {
    this.set(sessionStorage, key, data);
  }

  static getFromSession(key) {
    return this.get(sessionStorage, key);
  }

  static setInLocal(key, data) {
    this.set(localStorage, key, data);
  }

  static getFromLocal(key) {
    return this.get(localStorage, key);
  }
}
