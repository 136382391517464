import React, {
  useState,
  useEffect,
  useMemo,
  useRef,
  useCallback,
} from "react";
import Page from "./../../components/_Others/Page";
import Graph from "../../components/Graph/Graph";
import LoaderButton from "./../../components/LoaderButton/LoaderButton";
import axios from "axios";
import { URLS } from "./../../constants/urls";
import Toast from "./../../components/Toast/Toast";
import { BOOKING_STATUS, SESSION_STORAGE_KEYS } from "../../constants/enums";
import {
  indexKeyValue,
  orderBy,
  lightenOrDarkenColor,
  convertHexToRGBA,
} from "../../helpers/utilities";
import Storage from "./../../helpers/storage";
import moment from "moment";
import useStateManagement from './../../StateManagement/StateManagement';

const skyblue = "#87ceeb";
const palevioletred = "#db7093";

export default function Dashboard() {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(null);
  const { state } = useStateManagement();
  const rentableIdNameMappingRef = useRef([]); // rentable id/name key value pair

  const getData = useCallback(() => {
    if (loading) return;

    setLoading(true);
    axios
      .get(URLS.DASHBOARD.BASE)
      .then(function (response) {
        if (response.data.success === true && response.data.data) {
          setData(response.data.data);
          Storage.setInSession(SESSION_STORAGE_KEYS.dashboardData, {
            data: response.data.data,
            date: new Date(),
          });
        }
      })
      .catch(function (response) {
        Toast.error(response.message, "Error fetching data");
      })
      .then(function () {
        setLoading(false);
      });
  }, [loading]);

  useEffect(() => {
    rentableIdNameMappingRef.current = indexKeyValue(
      state.rentables.getDeleted(false),
      "id",
      "name"
    );
  }, [state.rentables]);

  useEffect(() => {
    const cachedData = Storage.getFromSession(
      SESSION_STORAGE_KEYS.dashboardData
    );
    if (cachedData) {
      const hourDiff = moment(cachedData.date).diff(moment(), "hours");
      if (hourDiff <= 6) {
        // have cached data from less 6 hours ago
        setData(cachedData.data);
      } else {
        getData();
      }
    } else {
      getData();
    }
  }, [getData]);

  const graph_upcomingBookingsPerStatus = useMemo(() => {
    const labels = [],
      borderColors = [],
      backgroundColors = [],
      graphData = [];
    let indexedData = null;
    if (data && data.upcomingBookingsPerStatus.length > 0) {
      indexedData = indexKeyValue(
        data.upcomingBookingsPerStatus,
        "status",
        "bookings"
      );
    }
    Object.values(BOOKING_STATUS).forEach((status) => {
      labels.push(status.name);
      backgroundColors.push(convertHexToRGBA(status.color, 80));
      borderColors.push(lightenOrDarkenColor(status.color, 7, false));
      if (indexedData) {
        graphData.push(indexedData[status.value] || 0);
      }
    });

    return (
      <Graph
        className="col-md-6 px-md-5 mb-5"
        type="bar"
        data={{
          labels: labels,
          datasets: [
            {
              label: "Bookings",
              data: graphData,
              backgroundColor: backgroundColors,
              borderColor: borderColors,
              borderWidth: 1,
            },
          ],
        }}
        options={{
          responsive: true,
          title: { display: true, text: "All upcoming bookings' per status" },
          scales: {
            yAxes: [
              {
                ticks: {
                  beginAtZero: true,
                },
              },
            ],
          },
        }}
      />
    );
  }, [data]);

  const graph_bookingsActivatedThisMonths = useMemo(() => {
    let month;
    let labels = [],
      bookingsData = [],
      earningsData = [];

    if (data && data.bookingsActivatedThisMonths.length > 0) {
      const mmt = moment(data.bookingsActivatedThisMonths[0].date);
      month = mmt.format("MMMM");

      const indexedByDay = data.bookingsActivatedThisMonths.reduce(
        (map, obj) => {
          const day = new Date(obj.date).getDate();
          map[day] = obj;
          return map;
        },
        {}
      );

      for (let d = 1; d <= mmt.daysInMonth(); d++) {
        labels.push(d);
        const byDay = indexedByDay[d];
        bookingsData.push(byDay ? byDay.bookings : 0);
        earningsData.push(byDay ? byDay.earnings : 0);
      }
    }

    return (
      <Graph
        className="col-md-6 px-md-5 mb-5"
        // maxHeight="300px"
        type="bar"
        data={{
          labels,
          datasets: [
            {
              label: "Bookings",
              data: bookingsData,
              backgroundColor: skyblue,
              yAxisID: "y-axis-1",
            },
            {
              label: "Earnings",
              data: earningsData,
              backgroundColor: palevioletred,
              yAxisID: "y-axis-2",
            },
          ],
        }}
        options={{
          responsive: true,
          title: {
            text: `Bookings activated and earnings made per day ${
              month ? "in " + month : "this month"
            }`,
            display: true,
          },
          tooltips: {
            mode: "index", // show label of both bars on hover
            intersect: true,
            callbacks: {
              title: (tooltipItems) => `${tooltipItems[0].label} ${month}`, // show month name along with label (day)
              label: (tooltipItems) =>
                tooltipItems.datasetIndex === 1 // show dollar sign on tooltip of second bar's value
                  ? "$ " + tooltipItems.yLabel
                  : tooltipItems.yLabel,
            },
          },
          scales: {
            yAxes: [
              {
                type: "linear", // only linear but allow scale type registration. This allows extensions to exist solely for log scale for instance
                display: true,
                position: "left",
                id: "y-axis-1",
                ticks: {
                  precision: 0, // y axis increase step bigger than 1, decimal not allowed like 1.2
                },
              },
              {
                type: "linear", // only linear but allow scale type registration. This allows extensions to exist solely for log scale for instance
                display: true,
                position: "right",
                id: "y-axis-2",
                gridLines: {
                  drawOnChartArea: true, // grid lines of right y axis
                },
                ticks: {
                  callback: (value) => "$" + value, // show dollar sign on right axios along with steps,
                },
              },
            ],
          },
        }}
      />
    );
  }, [data]);

  const graph_bookingsActivatedLast6Months = useMemo(() => {
    let datasets_bookings = [],
      datasets_earnings = [],
      dates = [];

    if (data && data.bookingsActivatedLast6MonthsPerRentable.length > 0) {
      dates = data.bookingsActivatedLast6MonthsPerRentable.reduce(
        // get unique dates for x axis
        (acc, it) => {
          it.bookingCountAndEarningPerMonth.forEach((el) => {
            if (!acc.includes(el.date)) {
              acc.push(el.date);
            }
          });
          return acc;
        },
        []
      );
      dates = orderBy(dates);

      const colorDarkeningRatio = Math.floor(
        100 / data.bookingsActivatedLast6MonthsPerRentable.length
      );

      function getDatasets(type) {
        const isBookingsType = type === "bookings";
        data.bookingsActivatedLast6MonthsPerRentable.forEach((it, index) => {
          // get darker tones of color
          const color = lightenOrDarkenColor(
            isBookingsType ? skyblue : palevioletred,
            colorDarkeningRatio * index,
            false
          );

          const insertInto = isBookingsType
            ? datasets_bookings
            : datasets_earnings;

          insertInto.push({
            label: rentableIdNameMappingRef.current[it.rentableId],
            backgroundColor: color,
            borderColor: color,
            fill: false,
            data: dates.map((d) => {
              const perDate = it.bookingCountAndEarningPerMonth.find(
                (a) => a.date === d
              );
              return perDate
                ? isBookingsType
                  ? perDate.bookings
                  : perDate.earnings
                : 0;
            }),
          });
        });
      }

      getDatasets("bookings");
      getDatasets("earnings");
    }

    function getGraph(type) {
      const datasets =
        type === "bookings" ? datasets_bookings : datasets_earnings;
      const titleText = `${
        type === "bookings" ? "Bookings activated" : "Earnings made"
      } since ${
        dates[0] ? moment(dates[0]).format("MMM yy") : "last 6 months"
      } per rentable`;

      return (
        <Graph
          className="col-md-6 px-md-5 mb-5"
          type="line"
          data={{
            datasets,
            labels: dates.map((date) => moment(date).format("MMM yy")),
          }}
          options={{
            responsive: true,
            title: {
              display: true,
              text: titleText,
            },
            scales: {
              yAxes: [
                {
                  ticks: {
                    beginAtZero: true,
                  },
                },
              ],
            },
          }}
        />
      );
    }

    return (
      <>
        {getGraph("bookings")}
        {getGraph("earnings")}
      </>
    );
  }, [data]);

  function render() {
    return (
      <Page
        title="Dashboard"
        extras={
          <LoaderButton
            onClick={getData}
            className="btn btn-outline-primary btn-block"
            loading={loading}
            loadingText="Loading..."
          >
            Refresh Data
          </LoaderButton>
        }
      >
        <div className="row">
          {graph_upcomingBookingsPerStatus}
          {graph_bookingsActivatedThisMonths}
          {graph_bookingsActivatedLast6Months}
        </div>
      </Page>
    );
  }

  return render();
}
