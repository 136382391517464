import React, { useState } from "react";
import moment from "moment";
import { FloatingLabelTextArea } from "./../../../components/FloatingLabelFormElements/FloatingLabelFormElements";
import { BOOKING_STATUS } from "../../../constants/enums";

export default function Activities(props) {
  const [noteView, setNoteView] = useState(false);
  const [note, setNote] = useState("");

  function saveNote(){
    const booking = { ...props.booking };
    booking.activities = booking.activities.concat({ note });
    props.updateBooking(booking);
    setNoteView(false);
    setNote("");
  }

  function render() {
    return (
      <>
        {noteView ? (
          <div>
            <FloatingLabelTextArea
              name="note"
              placeholder="Notes"
              onChange={(e) => {
                setNote(e.target.value);
              }}
              value={note}
              minHeight="100px"
            />

            <div className="d-block text-right">
              <button
                className="btn btn-outline-secondary mr-2"
                onClick={() => {
                  setNoteView(false);
                }}
              >
                View Activities
              </button>
              <button
                className="btn btn-outline-primary"
                disabled={note.trim().length === 0}
                onClick={() => {
                  saveNote();
                }}
              >
                Add Note
              </button>
            </div>
          </div>
        ) : (
          <div>
            <div
              className="d-block text-right"
              style={{ marginTop: "-10px", marginBottom: "14px" }}
            >
              <button
                className="btn btn-outline-primary"
                disabled={
                  props.booking.status === BOOKING_STATUS.cancelled.value
                }
                onClick={() => {
                  setNoteView(true);
                }}
              >
                Add Note
              </button>
            </div>

            {!props.booking.activities ? (
              <div className="alert alert-warning">No activities loaded</div>
            ) : (
              <ul className="list-unstyled">
                {props.booking.activities.map((act) => (
                  <li
                    key={act.id}
                    className="media border px-2 py-1 rounded mb-2"
                    style={{ background: "#eef8f9", fontSize: ".9rem" }}
                  >
                    <div className="media-body">
                      <span className="mt-0 mb-1">
                        {act.note.split("\n").map((n, index) => {
                          return (
                            <p key={index} className="mb-0">
                              {n}
                            </p>
                          );
                        })}
                      </span>
                      <div
                        className="row text-black-50"
                        style={{ fontSize: ".9rem" }}
                      >
                        <div className="col-6">{act.by}</div>
                        <div className="col-6 text-right">
                          {moment.utc(act.date).local().calendar()}
                        </div>
                      </div>
                    </div>
                  </li>
                ))}
              </ul>
            )}
          </div>
        )}
      </>
    );
  }

  return render();
}
