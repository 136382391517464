import React from "react";
import "./BetterSelect.css";

/*
  <BetterSelect
    onChange={this.onRentableChange}
    className="btn-link"
    defaultText="Select Rentable"
    text={this.state.selectedRentable.name}
    value={this.state.selectedRentable.id}
  >
    {root.rentables.map((rentable) => (
        <option key={rentable.id} value={rentable.id}>
          {rentable.name}
        </option>
      ))}
  </BetterSelect>
*/
export default function BetterSelect(props) {
  return (
    <span
      className={"better-select " + (props.className ? props.className : "")}
    >
      {props.text || props.defaultText || "Select"}
      <select onChange={props.onChange} value={props.value}>
        {props.children}
      </select>
    </span>
  );
}
