import React from "react";
import "./FloatingLabelFormElements.css";
import Help from "./../Help/Help";

/*
<FloatingLabelInput 
  type="text"
  name="email" 
  placeholder="Email address" 
  onChange={this.handleChange} 
  value={this.state.email}
  classname=""
  autoComplete={false}  //default false
  disabled={true}
  required={true}
  help="Help text"
  />
*/
export function FloatingLabelInput(props) {
  const id = "fli-" + props.name;
  const required = props.required === true;
  const disabled = props.disabled === true;
  return (
    <div className={"form-label-group d-flex " + (props.className || "")}>
      <input
        id={id}
        className="form-control"
        disabled={disabled}
        type={props.type || "text"}
        name={props.name}
        value={props.value}
        placeholder={props.placeholder}
        onChange={props.onChange}
        autoComplete={props.autoComplete === true ? undefined : "none"}
        required={required}
      />
      <label htmlFor={id}>
        {required && <span>*</span>}
        {props.placeholder}
      </label>
      {props.help && (
        <Help
          className="ml-2"
          size="17px"
          style={{ position: "absolute", top: "0", right: "5px" }}
        >
          {props.help}
        </Help>
      )}
    </div>
  );
}

/*
<FloatingLabelSelect 
  name="type" 
  placeholder="Type" 
  onChange={this.handleChange} 
  value={this.state.type}
  classname=""
  disabled={true}
  required={true}
  >
    <option value="">Select item</option>
    { items.map((item) => <option key={item.id} value={item.id}>item.name<option/>) }
  </FloatingLabelSelect>
*/
export function FloatingLabelSelect(props) {
  const id = "fls-" + props.name;
  const required = props.required === true;
  const disabled = props.disabled === true;
  return (
    <div className="form-label-group">
      <select
        id={id}
        className={"form-control " + (props.className || "")}
        disabled={disabled}
        name={props.name}
        value={props.value}
        placeholder={props.placeholder}
        onChange={props.onChange}
        required={required}
      >
        {props.children}
      </select>
      <label htmlFor={id}>
        {required && <span>*</span>}
        {props.placeholder}
      </label>
    </div>
  );
}

/*
  <FloatingLabelTextArea
    name="description"
    placeholder="Description"
    onChange={this.onChange}
    value={this.state.description}
    classname=""
    disabled={true}
    required={true}
    minHeight="200px"
    help="Help text"
  />
*/
export function FloatingLabelTextArea(props) {
  const id = "flt-" + props.name;
  const required = props.required === true;
  const disabled = props.disabled === true;
  const style = {
    minHeight: props.minHeight || "80px",
  };

  return (
    <div className="form-label-group">
      <textarea
        id={id}
        className={"form-control " + (props.className || "")}
        disabled={disabled}
        name={props.name}
        value={props.value}
        placeholder={props.placeholder}
        onChange={props.onChange}
        style={style}
        required={required}
      />
      <label htmlFor={id}>
        {required && <span>*</span>}
        {props.placeholder}
      </label>
      {props.help && (
        <Help
          className="ml-2"
          size="17px"
          style={{ position: "absolute", top: "0", right: "5px" }}
        >
          {props.help}
        </Help>
      )}
    </div>
  );
}
