export const USER_ROLES = { user: "User", admin: "Admin" };

export const SESSION_STORAGE_KEYS = {
  dashboardData: "dashboardData",
  countries: "countries",
  appVersion: "appVersion",
};

export const BOOKING_DURATION_UNIT = {
  hour: { name: "Hour", value: 10 },
  day: { name: "Day", value: 20 },
};
export const BOOKING_CANCELLATION_BASE = {
  beforeBookingStartTime: { name: "Before booking start time", value: 10 },
  afterBookingMade: { name: "After booking made", value: 20 },
};
export const BOOKING_CANCELLATION_UNIT = {
  hour: { name: "Hours", value: 10 },
  day: { name: "Days", value: 20 },
};
export const BOOKING_TYPE = {
  inHouse: { name: "In House", value: 10 },
  online: { name: "Online", value: 20 },
};
export const BOOKING_STATUS = {
  draft: { name: "Draft", value: 10, color: "#d3d3d3" },
  active: { name: "Active", value: 20, color: "#0ed2d2" }, //approved
  pending: { name: "Pending", value: 30, color: "#ffa500" },
  cancelled: { name: "Cancelled", value: 40, color: "#fababa" },
  declined: { name: "Declined", value: 50, color: "#dfcc86" },
};
export const BOOKING_LIST_ORDER_TYPE = {
  createdDate: { name: "Created date", value: 10 },
  clientName: { name: "Client name", value: 20 },
  startDate: { name: "Start date", value: 30 },
  title: { name: "Title", value: 40 },
  referenceNumber: { name: "Reference number", value: 50 },
  rentable: { name: "Rentable", value: 60 },
};
export const LOCATION_TYPE = {
  country: { name: "Country", value: 10 },
  province: { name: "Province", value: 20 },
  city: { name: "City", value: 30 },
};
