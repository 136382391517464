import React from "react";

/*
<Card 
  className="bg-secondary">
  Children Element
</Card>
*/
export default function Card(props) {
  return (
    <div
      className={"p-3 bg-white rounded shadow-sm " + (props.className || "")}
    >
      {props.children}
    </div>
  );
}