import React from "react";
import { Icon } from "@mdi/react";
import {
  mdiCalendarClock,
  mdiHome,
  mdiWeb,
  mdiHomeOutline,
} from "@mdi/js";
import { onFormChange } from "./../../../helpers/utilities";
import { BOOKING_TYPE } from "./../../../constants/enums";
import {
  FloatingLabelInput,
  FloatingLabelTextArea,
} from "./../../../components/FloatingLabelFormElements/FloatingLabelFormElements";
import BookingStartEndTimes from "../BookingStartEndTimes";

const MAX_TITLE_LENGTH = 70; // same constant name as backend

export default function Details(props) {
  function onChange(e) {
    let { name, value } = onFormChange(e);

    if (name === "title" && value && value.length > MAX_TITLE_LENGTH) {
      return;
    }

    const booking = { ...props.booking };
    booking[name] = value;
    props.updateBooking(booking);
  }

  function render() {
    const { booking } = props;

    const inHouseBooking = booking.type === BOOKING_TYPE.inHouse.value;
    return (
      <div className="row text-md-center">
        <div className="col-md-4 mb-3">
          <Icon
            path={mdiCalendarClock}
            size={1}
            color="#818181"
            className="mdi mr-2"
          />
          <BookingStartEndTimes start={booking.start} end={booking.end} />
        </div>
        <div className="col-md-4 mb-2">
          <Icon
            path={mdiHome}
            size={1}
            color="#818181"
            className="mdi mr-2"
          />
          {props.rentable.name}
        </div>
        <div className="col-md-4 mb-2">
          <Icon
            path={inHouseBooking ? mdiHomeOutline : mdiWeb}
            size={1}
            color="#818181"
            className="mdi mr-2"
          />
          {inHouseBooking ? "In House Booking" : "Booked Online"}
        </div>
        <div className="col-md-12">
          <FloatingLabelInput
            name="title"
            value={booking.title}
            placeholder="Title"
            onChange={onChange}
            required={true}
          />
        </div>
        <div className="col-md-12 mt-3">
          <FloatingLabelTextArea
            name="info"
            placeholder="Info"
            onChange={onChange}
            value={booking.info}
            minHeight="100px"
            help="Add any information or note related to booking. This will also be included in the confirmation email that client will recieve"
          />
        </div>
      </div>
    );
  }

  return render();
}
