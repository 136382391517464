import React, { useState, useCallback } from "react";
import axios from "axios";
import moment from "moment";
import { onFormChange, isValidEmail } from "./../../../helpers/utilities";
import Toast from "../../../components/Toast/Toast";
import { URLS } from "./../../../constants/urls";
import { FloatingLabelInput } from "./../../../components/FloatingLabelFormElements/FloatingLabelFormElements";
import LoaderButton from "./../../../components/LoaderButton/LoaderButton";
import LocationSelect from "../../../components/LocationSelect/LocationSelect";

export default function Client(props) {
  const [searchedEmail, setSearchedEmail] = useState("");
  const [searching, setSearching] = useState(false);

  const onChange = useCallback((e) =>{
    let { name, value } = onFormChange(e);
    const booking = { ...props.booking };
    const client = booking.client;

    if (
      name === "emailAddress" &&
      isValidEmail(value) &&
      searchedEmail !== value
    ) {
      setSearchedEmail("");
    }

    client[name] = value;
    props.updateBooking(booking);
  }, [props, searchedEmail]);

  function applyExistingClientInfo(client) {
    const booking = { ...props.booking };
    const _client = booking.client;

    _client.firstName = client.firstName;
    _client.lastName = client.lastName;
    _client.emailAddress = client.emailAddress;
    _client.phoneNumber = client.phoneNumber;
    _client.company = client.company;
    _client.country = client.country;
    _client.province = client.province;
    _client.city = client.city;
    _client.addressLine1 = client.addressLine1;
    _client.addressLine2 = client.addressLine2;
    _client.postalCode = client.postalCode;

    props.updateBooking(booking);
  }

  function searchClient() {
    if (searchedEmail !== props.booking.client.emailAddress) {
      setSearchedEmail(props.booking.client.emailAddress);
      setSearching(true);
      axios
        .get(URLS.BOOKING_CLIENTS.BY_EMAIL + props.booking.client.emailAddress)
        .then(function (response) {
          const client = response.data.data;
          if (response.data.success === true && response.data.data) {
            Toast.success(
              <div className="text-left">
                <p className="mb-2">
                  <small
                    className="text-secondary d-block"
                    style={{ fontSize: "13px" }}
                  >
                    Name
                  </small>
                  {client.fullName}
                </p>
                <p className="mb-2">
                  <small
                    className="text-secondary d-block"
                    style={{ fontSize: "13px" }}
                  >
                    Phone Number
                  </small>
                  {client.phoneNumber}
                </p>
                <p className="mb-2">
                  <small
                    className="text-secondary d-block"
                    style={{ fontSize: "13px" }}
                  >
                    Company
                  </small>
                  {client.company || "N/A"}
                </p>
                <p className="mb-2">
                  <small
                    className="text-secondary d-block"
                    style={{ fontSize: "13px" }}
                  >
                    Last Booked On
                  </small>
                  {moment.utc(client.createdDate).local().calendar()}
                </p>
                <button
                  className="btn btn-primary btn-sm mt-1"
                  onClick={() => applyExistingClientInfo(response.data.data)}
                >
                  Use details
                </button>
              </div>,
              "Client found",
              8
            );
          } else if (response.data.errors) {
            Toast.error(
              `No previous client exists with email address \n${props.booking.client.emailAddress}`,
              "Client not found"
            );
          }
        })
        .catch(function (response) {
          Toast.error(response.message, "Error searching client");
        })
        .then(function () {
          setSearching(false);
        });
    }
  }

  function render() {
    const { client } = props.booking;
    return (
      <div className="row">
        <div className="col-md-8 d-flex">
          <FloatingLabelInput
            name="emailAddress"
            value={client.emailAddress}
            placeholder="Email address"
            onChange={onChange}
            className="flex-fill"
            required={true}
          />
          {props.isNewBooking && (
            <LoaderButton
              onClick={searchClient}
              className="btn btn-outline-primary h-75 ml-2"
              loading={searching}
              loadingText=""
              disabled={
                searchedEmail === client.emailAddress ||
                !isValidEmail(client.emailAddress)
              }
            >
              Search
            </LoaderButton>
          )}
        </div>
        <div className="col-md-4">
          <FloatingLabelInput
            name="phoneNumber"
            value={client.phoneNumber}
            placeholder="Phone number"
            onChange={onChange}
            required={true}
          />
        </div>
        <div className="col-6 col-md-4">
          <FloatingLabelInput
            name="firstName"
            value={client.firstName}
            placeholder="First name"
            onChange={onChange}
            required={true}
          />
        </div>
        <div className="col-6 col-md-4">
          <FloatingLabelInput
            name="lastName"
            value={client.lastName}
            placeholder="Last name"
            onChange={onChange}
            required={true}
          />
        </div>
        <div className="col-6 col-md-4">
          <FloatingLabelInput
            name="company"
            value={client.company}
            placeholder="Company"
            onChange={onChange}
          />
        </div>

        <LocationSelect
          onChange={onChange}
          valueProp="name"
          countryValue={client.country}
          countryClass="col-6 col-md-4"
          countryRequired={true}
          provinceValue={client.province}
          provinceClass="col-6 col-md-4"
          cityValue={client.city}
          cityClass="col-6 col-md-4"
          cityRequired={true}
        />

        <div className="col-md-4">
          <FloatingLabelInput
            name="addressLine1"
            value={client.addressLine1}
            placeholder="Address line 1"
            onChange={onChange}
            required={true}
          />
        </div>
        <div className="col-6 col-md-4">
          <FloatingLabelInput
            name="addressLine2"
            value={client.addressLine2}
            placeholder="Address line 2"
            onChange={onchange}
          />
        </div>
        <div className="col-6 col-md-4">
          <FloatingLabelInput
            name="postalCode"
            value={client.postalCode}
            placeholder="Postal code"
            onChange={onChange}
          />
        </div>
      </div>
    );
  }

  return render();
}
