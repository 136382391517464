import React from "react";
import axios from "axios";
import Swal from "sweetalert2";
import { Navigate } from "react-router-dom";
import { URLS } from "../../../constants/urls";
import Toast from "../../../components/Toast/Toast";
import { onFormChange, convertToFormData } from "../../../helpers/utilities";
import LoaderButton from "../../../components/LoaderButton/LoaderButton";
import NavTab from "../../../components/NavTab/NavTab";
import Page from "../../../components/_Others/Page";
import Advanced from "./Advanced";
import Intervals from "./Intervals";
import DayTimes from "./DayTimes";
import OffDates from "./OffDates";
import OnlineBooking from "./OnlineBooking";
import Images from "./Images";
import FeesCancellation from "./FeesCancellation";
import General from "./General";
import Features from "./Features";
import { MainContext } from "./../../../StateManagement/StateManagement";
import Actions from "./../../../StateManagement/actions";
import { getUrlSearchParams } from "./../../../helpers/utilities";

export default class RentableSettings extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      saving: false,
      disabled: true,
      rentableTypeOptions: [],
      featureOptions: [],
      id: null,
      rentable: {
        name: "",
        typeId: null,
        description: "",
        secretDetails: "",
        bookingDurationUnit: 0,
        maxInterval: 90,
        minInterval: 0,
        isDeleted: false,
        images: [], // used to display and delete exiting files
        imageFiles: [], // used to upload files,
        fee: "",
        deposit: "",
        feeRefund: "",
        depositRefund: "",
        bookingCancellationAllowed: false,
        bookingCancellationBase: null,
        bookingCancellationUnit: null,
        bookingCancellationQuantity: null,
        onlineBookingAllowed: false,
        bookingApprovalRequired: false,
        features: [],
        offDates: [],
        dayTimes: [],
      },
    };

    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.isDisabled = this.isDisabled.bind(this);
  }

  static contextType = MainContext;

  componentDidMount() {
    const root = this;

    const { id } = getUrlSearchParams();

    if (id && id > 0) {
      root.setState({ id });

      axios
        .get(URLS.RENTABLES.BASE + id)
        .then(function (response) {
          if (response.data.success === true && response.data.data) {
            root.setState((state) => {
              // This will copy non null/undefined property values in response.data.data to the state.rentable property values
              // leaving "" property values in state.rentable which is required by inputs
              const rentable = Object.assign(
                {},
                state.rentable,
                response.data.data
              );
              return { rentable };
            });
          } else if (response.data.errors) {
            Toast.error(response.data.errors);
          }
        })
        .catch(function (response) {
          Toast.error(response.message, "Error getting rentable");
        })
        .then(function () {
          root.setState({ loading: false });
        });
    } else {
      root.setState({ loading: false });
    }
  }

  onChange(e) {
    let { name, value, checked } = onFormChange(e);
    let rentable = { ...this.state.rentable };

    if (name === "typeId") {
      if (value !== rentable.typeId) {
        Swal.fire({
          title: "Warning",
          text: "You must set up the features that associated with this rentable type as you have changed the type",
          type: "warning",
          showCancelButton: true,
          confirmButtonText: "Okay",
          cancelButtonText: 'Don\'t change "Type"',
          allowOutsideClick: false,
        }).then((response) => {
          if (response.value && response.value === true) {
            rentable[name] = value;
            this.setState({ rentable, featureOptions: [] }); //Reset featureOptions if type changes
          }
        });
      }
    } else if (name === "features") {
      value = parseInt(value);
      let feature = rentable.features.find((f) => f.featureId === value);
      if (feature) {
        feature.isDeleted = !checked;
      } else {
        feature = { featureId: value, isDeleted: !checked };
        rentable.features.push(feature);
      }
      this.setState({ rentable });
    } else {
      rentable[name] = value;
      this.setState({ rentable });
    }
  }

  onSubmit() {
    const root = this;
    root.setState({ saving: true });

    const data = convertToFormData(root.state.rentable);

    axios
      .post(URLS.RENTABLES.BASE + root.state.rentable.id, data, {
        headers: { "Content-Type": "multipart/form-data" },
      })
      .then(function (response) {
        if (response.data.success === true) {
          if (response.data.data) {
            root.setState({ rentable: response.data.data });

            // update rentables in MainContext
            const rentables = root.context.state.rentables.data.map((r) =>
              r.id === root.state.rentable.id ? response.data.data : r
            );
            root.context.dispatch(Actions.setRentables(rentables));
          }
          Toast.success("Changes saved");
        } else if (response.data.errors) {
          Toast.error(response.data.errors);
        }
      })
      .catch(function (response) {
        Toast.error(response.message, "Error updating rentable");
      })
      .then(function () {
        root.setState({ saving: false });
      });
  }

  isDisabled() {
    return this.state.disabled || this.state.rentable.isDeleted;
  }

  render() {
    if (!this.state.loading && !this.state.rentable.id) {
      return <Navigate to="/App/Rentables" />;
    }

    const title = `Edit ${this.state.rentable.name}`;
    const currentUser = this.context.state.currentUser;

    return (
      <Page
        title={title}
        loading={this.state.loading}
        type="tabs"
        extras={
          this.state.rentable.isDeleted ? (
            <div className="alert alert-warning m-0 py-1">
              {`This rentable has been archived on ${new Date(
                this.state.rentable.deletedDate
              ).toDateString()}. `}
            </div>
          ) : !this.isDisabled() ? (
            <LoaderButton
              onClick={this.onSubmit}
              className="btn-primary float-md-right btn-block"
              loading={this.state.saving}
              loadingText="Saving..."
            >
              Save Changes
            </LoaderButton>
          ) : (
            this.state.disabled &&
            (currentUser.isAdmin ? (
              <button
                onClick={() => {
                  this.setState({ disabled: false });
                }}
                className="btn btn-outline-primary btn-block"
              >
                Edit Settings
              </button>
            ) : (
              <div className="alert alert-info m-0 py-1">
                Only admins are allowed to make changes to rentable settings.
              </div>
            ))
          )
        }
      >
        <NavTab
          tabs={[
            {
              name: "General",
              content: (
                <General
                  isDisabled={this.isDisabled()}
                  onChange={this.onChange}
                  rentable={this.state.rentable}
                  rentableTypeOptions={this.state.rentableTypeOptions}
                  onUpdateRentableTypesOptions={(options) =>
                    this.setState({ rentableTypeOptions: options })
                  }
                />
              ),
            },
            {
              name: "Features",
              content: (
                <Features
                  isDisabled={this.isDisabled()}
                  onChange={this.onChange}
                  rentable={this.state.rentable}
                  featureOptions={this.state.featureOptions}
                  onUpdateFeatureOptions={(options) =>
                    this.setState({ featureOptions: options })
                  }
                  rentableTypeOptions={this.state.rentableTypeOptions}
                  onUpdateRentable={(rentable) => this.setState({ rentable })}
                />
              ),
            },
            {
              name: "Fees & Cancellation",
              content: (
                <FeesCancellation
                  isDisabled={this.isDisabled()}
                  onChange={this.onChange}
                  rentable={this.state.rentable}
                />
              ),
            },
            {
              name: "Images",
              content: (
                <Images
                  isDisabled={this.isDisabled()}
                  onChange={this.onChange}
                  rentable={this.state.rentable}
                  onUpdateRentable={(rentable) => this.setState({ rentable })}
                />
              ),
            },
            {
              name: "Online Booking",
              content: (
                <OnlineBooking
                  isDisabled={this.isDisabled()}
                  onChange={this.onChange}
                  rentable={this.state.rentable}
                  onUpdateRentable={(rentable) => this.setState({ rentable })}
                />
              ),
            },
            {
              name: "Availability",
              subTabs: [
                {
                  name: "Non-Working Dates",
                  content: (
                    <OffDates
                      isDisabled={this.isDisabled()}
                      rentable={this.state.rentable}
                      onUpdateRentable={(rentable) =>
                        this.setState({ rentable })
                      }
                    />
                  ),
                },
                {
                  name: "Working Day & Times",
                  content: (
                    <DayTimes
                      isDisabled={this.isDisabled()}
                      rentable={this.state.rentable}
                      onUpdateRentable={(rentable) =>
                        this.setState({ rentable })
                      }
                    />
                  ),
                },
                {
                  name: "Intervals",
                  content: (
                    <Intervals
                      isDisabled={this.isDisabled()}
                      onChange={this.onChange}
                      rentable={this.state.rentable}
                      onUpdateRentable={(rentable) =>
                        this.setState({ rentable })
                      }
                    />
                  ),
                },
              ],
            },
            {
              name: "Advanced",
              content: (
                <Advanced
                  disabled={this.state.disabled}
                  rentable={this.state.rentable}
                />
              ),
            },
          ]}
        />
      </Page>
    );
  }
}
