import React from "react";
import Page from "./../../components/_Others/Page";
import Card from "./../../components/Card/Card";

export default class Page404 extends React.Component {
  render() {
    return (
      <Page title="Page Not Found">
        <Card className="text-center">
          <h1 className="mb-3">404</h1>
          <h3 className="text-danger">Page Not Found</h3>
        </Card>
      </Page>
    );
  }
}
