import React, { useState } from "react";
import axios from "axios";
import moment from "moment";
import { URLS } from "./../../../constants/urls";
import { toastBookingMessage } from "./BookingModal";
import Toast from "../../../components/Toast/Toast";
import { BOOKING_STATUS } from "../../../constants/enums";
import LoaderButton from "./../../../components/LoaderButton/LoaderButton";
import BookingStatus from "./../BookingStatus";
import BookingStartEndTimes from "../BookingStartEndTimes";

export default function Review(props) {
  const [loading, setLoading] = useState(false);

  function activate() {
    setLoading(true);

    axios
      .post(URLS.BOOKING_ENTRIES.ACTIVATE + props.booking.id)
      .then(function (response) {
        if (response.data.success === true && response.data.data) {
          const booking = response.data.data;
          props.onUpdateBookingEntries(booking);
          toastBookingMessage("Booking activated", booking);
          props.onClose();
        } else {
          Toast.error(response.data.errors);
        }
      })
      .catch(function (response) {
        Toast.error(response.message, "Error activating booking");
      })
      .then(function () {
        setLoading(false);
      });
  }

  function render() {
    const { booking } = props;
    return (
      <div className="row">
        <div className="col-md-8">
          <div className="row">
            <div className="col-md-4">
              <div className="mb-2">
                <small className="text-secondary d-block">Status</small>
                <BookingStatus status={booking.status} className="mt-n1" />
              </div>
            </div>
            <div className="col-md-8">
              <p className="mb-2">
                {booking.bookingDates && booking.bookingDates.length > 0 && (
                  <>
                    <small className="text-secondary d-block mb-1">
                      {`Booking ${
                        booking.bookingDates.length === 1 ? "Date" : "Dates"
                      }`}
                    </small>
                    {booking.bookingDates.length === 1 ? (
                      <BookingStartEndTimes
                        start={booking.start}
                        end={booking.end}
                      />
                    ) : (
                      <>
                        {booking.bookingDates.map((bd, index) => (
                          <span
                            key={index}
                            className="border px-1 mr-1 mb-1 d-inline-block"
                          >
                            {moment(bd).format("DD MMM YY")}
                          </span>
                        ))}
                      </>
                    )}
                  </>
                )}
              </p>
            </div>
            <div className="col-md-4">
              <p className="mb-2">
                <small className="text-secondary d-block">
                  Reference Number
                </small>
                {booking.referenceNumber}
              </p>
            </div>
            <div className="col-md-8">
              <p className="mb-2">
                <small className="text-secondary d-block">Features</small>
                {booking.payment.features &&
                booking.payment.features.length > 0 ? (
                  booking.payment.features.map((a) => (
                    <span className="badge badge-light mr-1">{a.name}</span>
                  ))
                ) : (
                  <span>None</span>
                )}
              </p>
            </div>
          </div>
        </div>

        <div className="col-md-4">
          <table className="table table-bordered table-sm">
            <tbody>
              <tr>
                <td>Fee</td>
                <td>${booking.payment.fee}</td>
              </tr>
              <tr>
                <td>Deposit</td>
                <td>${booking.payment.deposit || 0}</td>
              </tr>
              <tr>
                <td>Feature Fees</td>
                <td>${booking.payment.featureFees || 0}</td>
              </tr>
              <tr>
                <td>Discount</td>
                <td>${booking.payment.discount || 0}</td>
              </tr>
              <tr className="bg-light">
                <td>Total</td>
                <td>${booking.payment.totalPaid}</td>
              </tr>
            </tbody>
          </table>
        </div>

        {booking.status === BOOKING_STATUS.draft.value && (
          <div className="col-12">
            <LoaderButton
              onClick={activate}
              className="btn-primary float-right"
              loading={loading}
              loadingText="Activating..."
            >
              Activate
            </LoaderButton>
          </div>
        )}
      </div>
    );
  }
  return render();
}
