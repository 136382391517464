import React from "react";
import { FloatingLabelSelect } from "../../../components/FloatingLabelFormElements/FloatingLabelFormElements";
import Modal from "./../../../components/Modal/Modal";
import {
  updateElementPropertyInList,
  orderBy,
} from "../../../helpers/utilities";
import Toast from "../../../components/Toast/Toast";
import { onFormChange } from "./../../../helpers/utilities";

export default class DayTimes extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedDayTime: {},
      open: false,
    };

    this.onChange = this.onChange.bind(this);
    this.onEdit = this.onEdit.bind(this);
    this.onDelete = this.onDelete.bind(this);

    this.weekDays = [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
    ];

    this.times = [];
    let mins = ["00", "30"];
    for (var i = 0; i <= 24; i++) {
      if (i === 24) {
        mins = ["00"];
      }
      for (var j = 0; j < mins.length; j++) {
        this.times.push(("0" + i).slice(-2) + ":" + mins[j]);
      }
    }

    this.defaultDayTime = {
      weekDay: 0,
      startTime: "00:00",
      endTime: "00:30",
      isDeleted: false,
      key: null, // just used to check new or already added item
    };
  }

  componentDidMount() {
    this.feedKeysAndTimes();
  }

  componentDidUpdate() {
    if (this.props.rentable.dayTimes.some((dt) => !dt.key)) {
      this.feedKeysAndTimes();
    }
  }

  feedKeysAndTimes() {
    let rentable = { ...this.props.rentable };
    rentable.dayTimes.forEach((dt) => {
      if (dt.id) {
        dt.key = dt.id;

        const st = dt.startTime.split(":");
        dt.startTime = `${st[0]}:${st[1]}`; //get hours and minutes, ignore seconds

        const et = dt.endTime.split(":");
        dt.endTime = `${et[0]}:${et[1]}`;
      }
    });

    rentable.dayTimes = orderBy(rentable.dayTimes, "weekDay");
    this.props.onUpdateRentable(rentable);
  }

  onChange(e) {
    let { name, value } = onFormChange(e);
    let selectedDayTime = { ...this.state.selectedDayTime };
    selectedDayTime[name] = value;
    this.setState({ selectedDayTime });
  }

  onEdit(dayTime) {
    this.setState({ selectedDayTime: { ...dayTime }, open: true });
  }

  onSave(closeModal) {
    const rentable = { ...this.props.rentable };
    let dayTimes = rentable.dayTimes;
    const selectedDayTime = { ...this.state.selectedDayTime };

    if (
      !selectedDayTime.endTime ||
      selectedDayTime.startTime >= selectedDayTime.endTime
    ) {
      selectedDayTime.endTime = this.times.filter(
        (t) => t > this.state.selectedDayTime.startTime
      )[0];
    }

    const similar = dayTimes.find(
      (d) =>
        !d.isDeleted &&
        d.key !== selectedDayTime.key &&
        d.weekDay === selectedDayTime.weekDay &&
        selectedDayTime.startTime >= d.startTime &&
        selectedDayTime.endTime <= d.endTime
    );
    if (similar) {
      Toast.error(
        `A record already exists with similar or better range: ${similar.startTime}-${similar.endTime}`
      );
      return;
    }

    if (selectedDayTime.key) {
      rentable.dayTimes = dayTimes.map((dt) => {
        if (dt.key === selectedDayTime.key) {
          return selectedDayTime;
        }
        return dt;
      });
    } else {
      selectedDayTime.key = parseInt(Date.now());
      dayTimes.push(selectedDayTime);
    }

    rentable.dayTimes = orderBy(rentable.dayTimes, "weekDay");

    // update via parent
    this.props.onUpdateRentable(rentable);

    // reset selected
    this.setState({ selectedDayTime: { ...this.defaultDayTime } });

    if (closeModal) {
      this.setState({ open: false });
    }
  }

  onDelete(dayTime) {
    const rentable = { ...this.props.rentable };
    rentable.dayTimes = updateElementPropertyInList(
      rentable.dayTimes,
      "key",
      dayTime.key,
      "isDeleted",
      true
    );

    // update parent component
    this.props.onUpdateRentable(rentable);
  }

  render() {
    const dayTimes = this.props.rentable.dayTimes;
    return (
      <fieldset disabled={this.props.isDisabled}>
        <div className="row">
          <div className="col-md-9">
            Days of week and times that this rentable can be booked
          </div>
          <div className="col-md-3">
            {/* show only if admin */}

            <button
              className="btn btn-primary float-md-right btn-xs-block mt-2 mt-md-0 mb-4"
              onClick={() =>
                this.setState({
                  open: true,
                  selectedDayTime: { ...this.defaultDayTime },
                })
              }
            >
              Add New Day/Time
            </button>

            <Modal
              header="Add new working day/time"
              open={this.state.open}
              onClose={() => this.setState({ open: false })}
              bodyClass="bg-light"
              footer={
                <>
                  {!this.state.selectedDayTime.key && (
                    <button
                      onClick={() => this.onSave(false)}
                      className="btn btn-outline-primary"
                    >
                      Add Next
                    </button>
                  )}
                  <button
                    onClick={() => this.onSave(true)}
                    className="btn btn-primary"
                  >
                    {this.state.selectedDayTime.key ? "Update" : "Add"}
                  </button>
                </>
              }
            >
              <div className="row">
                <div className="col-md-6">
                  <FloatingLabelSelect
                    name="weekDay"
                    placeholder="Week day"
                    onChange={this.onChange}
                    value={this.state.selectedDayTime.weekDay}
                  >
                    {this.weekDays.map((weekDay, index) => (
                      <option key={index} value={index}>
                        {weekDay}
                      </option>
                    ))}
                  </FloatingLabelSelect>
                </div>
                <div className="col-6 col-md-3">
                  <FloatingLabelSelect
                    name="startTime"
                    placeholder="Start time"
                    onChange={this.onChange}
                    value={this.state.selectedDayTime.startTime}
                  >
                    {this.times
                      .filter((time, index) => index < this.times.length - 1) // ignore last element
                      .map((time) => (
                        <option key={time} value={time}>
                          {time}
                        </option>
                      ))}
                  </FloatingLabelSelect>
                </div>
                <div className="col-6 col-md-3">
                  <FloatingLabelSelect
                    name="endTime"
                    placeholder="End time"
                    onChange={this.onChange}
                    value={this.state.selectedDayTime.endTime}
                  >
                    {this.times
                      .filter((t) => t > this.state.selectedDayTime.startTime)
                      .map((time) => (
                        <option key={time} value={time}>
                          {time}
                        </option>
                      ))}
                  </FloatingLabelSelect>
                </div>
              </div>
            </Modal>
          </div>

          <div className="col-12">
            {dayTimes.length === 0 ? (
              <div className="alert alert-secondary">
                No days/times have been added yet
              </div>
            ) : (
              <div className="table-responsive-sm">
                <table className="table table-hover no-wrap">
                  <thead>
                    <tr>
                      <th scope="col" width="150px">
                        Day of week
                      </th>
                      <th scope="col">Time range</th>
                      <th scope="col" className="text-right">
                        Action {/* show only if admin */}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {dayTimes.map((dayTime, index) => (
                      <tr
                        key={dayTime.key || index}
                        style={{
                          textDecoration: dayTime.isDeleted
                            ? "line-through"
                            : "none",
                        }}
                      >
                        <td>{this.weekDays[dayTime.weekDay]}</td>
                        <td>{dayTime.startTime + " - " + dayTime.endTime}</td>
                        <td className="text-right">
                          {/* show only if admin */}
                          {!dayTime.isDeleted && (
                            <>
                              <button
                                onClick={() => this.onEdit(dayTime)}
                                className="btn btn-sm btn-outline-primary"
                              >
                                Edit
                              </button>
                              <button
                                onClick={() => this.onDelete(dayTime)}
                                className="btn btn-sm btn-outline-secondary ml-2"
                              >
                                Delete
                              </button>
                            </>
                          )}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            )}
          </div>
        </div>
      </fieldset>
    );
  }
}
