import React, {
  useState,
  useEffect,
  useRef,
  useMemo,
  useCallback,
} from "react";
import Page from "../../../components/_Others/Page";
import axios from "axios";
import { URLS } from "../../../constants/urls";
import Toast from "../../../components/Toast/Toast";
import Paging from "../../../components/Paging/Paging";
import "./BookingsListView.css";
import LoaderButton from "../../../components/LoaderButton/LoaderButton";
import {
  BOOKING_TYPE,
  BOOKING_STATUS,
  BOOKING_LIST_ORDER_TYPE,
} from "./../../../constants/enums";
import moment from "moment";
import BetterSelect from "../../../components/BetterSelect/BetterSelect";
import Table from "./Table";
import FilterArea from "./FilterArea";
import BookingModal from "../BookingModal/BookingModal";
import { getDateWithoutTimeZone } from "../../../helpers/utilities";
import useStateManagement from "./../../../StateManagement/StateManagement";
import { useParams } from "react-router";

const defaultSearchModel = {
  page: 1,
  pageSize: 25,
  rentableIds: [],
  statuses: Object.values(BOOKING_STATUS).map((a) => a.value),
  types: Object.values(BOOKING_TYPE).map((a) => a.value),
  referenceNumber: "",
  clientEmail: "",
  clientName: "",
  title: "",
  start: moment().add(-15, "days").toDate(),
  end: moment().add(15, "days").toDate(),
  orderBy: BOOKING_LIST_ORDER_TYPE.createdDate.value,

  //only for ui - ignored by API
  orderByKey: "createdDate",
  dateRange: "",
};

export default function BookingsListView() {
  const [loading, setLoading] = useState(true);
  const [searchModel, setSearchModel] = useState({ ...defaultSearchModel });
  const [bookings, setBookings] = useState({
    items: [],
    numberOfPages: 0,
    totalItemsCount: 0,
  });
  const { state } = useStateManagement();
  const rentablesRef = useRef({ active: [], deleted: [] });
  const [showAdvanced, setShowAdvanced] = useState(false);
  const [bookingModal, setBookingModal] = useState({
    booking: null,
    rentable: null,
  });
  const { referenceNumber } = useParams();

  useEffect(() => {
    rentablesRef.current.active = state.rentables.getDeleted(false);
    rentablesRef.current.deleted = state.rentables.getDeleted(true);

    let _searchModel = onReset();

    // if reference number is present in url
    if (referenceNumber) {
      _searchModel = { ..._searchModel, referenceNumber };
      setSearchModel(_searchModel);
      setShowAdvanced(true);
    }

    getBookings(_searchModel);
  }, [referenceNumber, state.rentables]);

  function getBookings(_searchModel) {
    setLoading(true);

    const data = { ..._searchModel };
    // as string (despite js object that has timezone) so that api doesn't convert it to server's local timezone in post data
    data.start = data.start && getDateWithoutTimeZone(data.start);
    data.end = data.end && getDateWithoutTimeZone(data.end);

    axios
      .post(URLS.BOOKING_ENTRIES.SEARCH, data)
      .then(function (response) {
        if (response.data.success === true && response.data.data) {
          setBookings(response.data.data);
        }
      })
      .catch(function (response) {
        Toast.error(response.message, "Error getting bookings");
      })
      .then(function () {
        setLoading(false);
      });
  }

  function onReset() {
    // as default all active rentables selected
    const _searchModel = {
      ...defaultSearchModel,
      rentableIds: rentablesRef.current.active.map((r) => r.id),
    };
    setSearchModel(_searchModel);
    return _searchModel;
  }

  function updateBookings(booking) {
    if (booking.isDeleted) {
      // delete
      getBookings(searchModel); // so that you get the new numberOfPages, totalItemsCount as well as items (bookings)
      setBookingModal({ ...bookingModal, booking: null });
    } else {
      // update
      const bookingEntries = bookings.items.map((b) => {
        if (b.id === booking.id) {
          return booking;
        }
        return b;
      });
      setBookings({ ...bookings, items: bookingEntries });
    }
  }

  const viewBooking = useCallback(
    (booking) => {
      const _bookingModal = { ...bookingModal, booking: { ...booking } };
      if (
        !bookingModal.rentable ||
        bookingModal.rentable.id !== booking.rentableId
      ) {
        _bookingModal.rentable = state.rentables.data.find(
          (r) => r.id === booking.rentableId
        );
      }
      setBookingModal(_bookingModal);
    },
    [bookingModal, state.rentables.data]
  );

  function onOrderTypeChange(e) {
    const { value } = e.target;
    const orderByTye = BOOKING_LIST_ORDER_TYPE[value];
    const _searchModel = {
      ...searchModel,
      page: 1,
      orderBy: orderByTye.value,
      orderByKey: value,
    };
    setSearchModel(_searchModel);
    getBookings(_searchModel);
  }

  const onPagingChange = useCallback(
    (obj) => {
      const _searchModel = {
        ...searchModel,
        pageSize: obj.pageSize,
        page: obj.page,
      };
      setSearchModel(_searchModel);
      getBookings(_searchModel);
    },
    [searchModel]
  );

  function onAdvancedToggle() {
    if (showAdvanced) {
      setSearchModel((sm) => {
        // reset advanced filter option when hiding them
        return {
          ...sm,
          clientEmail: "",
          clientName: "",
          referenceNumber: "",
          title: "",
        };
      });
    }
    setShowAdvanced(!showAdvanced);
  }

  const table = useMemo(() => {
    return bookings.totalItemsCount > 0 ? (
      <Table bookings={bookings.items} viewBooking={viewBooking} />
    ) : null;
  }, [bookings.items, bookings.totalItemsCount, viewBooking]);

  const paging = useMemo(() => {
    return (
      bookings.totalItemsCount > 0 && (
        <Paging
          numberOfPages={bookings.numberOfPages}
          page={searchModel.page}
          pageSize={searchModel.pageSize}
          totalItemsCount={bookings.totalItemsCount}
          onChange={onPagingChange}
          className="float-md-left text-center"
        />
      )
    );
  }, [
    bookings.numberOfPages,
    bookings.totalItemsCount,
    searchModel.page,
    searchModel.pageSize,
    onPagingChange,
  ]);

  function render() {
    return (
      <Page title="Bookings">
        {bookingModal.booking && (
          <BookingModal
            booking={bookingModal.booking}
            rentable={bookingModal.rentable}
            onUpdateBookingEntries={updateBookings}
            onClose={() => setBookingModal({ ...bookingModal, booking: null })}
          />
        )}
        <div className="row bookings">
          <div className="col-12">
            <FilterArea
              searchModel={searchModel}
              setSearchModel={setSearchModel}
              showAdvanced={showAdvanced}
              activeRentables={rentablesRef.current.active}
              deletedRentables={rentablesRef.current.deleted}
            />
          </div>
          <div className="col-12">
            <div className="row">
              <div className="col-md-4 text-md-right d-flex d-md-block order-md-3">
                <LoaderButton
                  onClick={() => getBookings(searchModel)}
                  className="btn-primary btn-xs-block"
                  loading={loading}
                  loadingText="Loading..."
                >
                  Search
                </LoaderButton>
                <button
                  className={
                    "btn mx-1 btn-xs-block " +
                    (showAdvanced ? "btn-primary" : "btn-outline-primary")
                  }
                  onClick={onAdvancedToggle}
                >
                  Advanced
                </button>
                <button
                  className="btn btn-outline-secondary btn-xs-block"
                  onClick={onReset}
                >
                  Reset
                </button>
              </div>
              <div className="col-md-4 py-3 py-md-2 text-center text-secondary order-md-2">
                {bookings.totalItemsCount < 1
                  ? !loading && "No bookings found with this search criteria"
                  : `${bookings.totalItemsCount} ${
                      bookings.totalItemsCount === 1
                        ? "booking"
                        : "bookings ordered by"
                    } `}
                {bookings.totalItemsCount > 1 && (
                  <BetterSelect
                    onChange={onOrderTypeChange}
                    className="btn-link text-lowercase"
                    defaultText="order"
                    text={BOOKING_LIST_ORDER_TYPE[searchModel.orderByKey].name}
                    value={searchModel.orderByKey}
                  >
                    {Object.keys(BOOKING_LIST_ORDER_TYPE).map((key) => (
                      <option key={key} value={key}>
                        {BOOKING_LIST_ORDER_TYPE[key].name}
                      </option>
                    ))}
                  </BetterSelect>
                )}
              </div>
              <div className="col-md-4 order-md-1">{paging}</div>
            </div>
            {table}
            {paging}
          </div>
        </div>
      </Page>
    );
  }

  return render();
}
