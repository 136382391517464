import React from "react";
import DropDown from "./../DropDown/DropDown";

/* 
<NavTab
  className="mx-n1"
  style={{margin: "0 -16px"}}
  tabs={[
      {name: "First Tab", disabled: true, content: <FirstElement/>},
      {name: "Second Tab", content: <div>Test Div</div>}
      {name: "Second Tab", hide: true, content: <div>Test Div</div>}
      {name: "Second Tab", subTabs: [{name: "Sub tab 1", content: <div>Test Div</div>}]}
  ]}
/> 
*/
export default class NavTab extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      activeTab: 0,
      subActiveTab: null,
    };
  }

  render() {
    let currentTab = this.props.tabs[this.state.activeTab];
    if (this.state.subActiveTab !== null) {
      currentTab = currentTab.subTabs[this.state.subActiveTab];
    } else if (currentTab.subTabs) {
      currentTab = currentTab.subTabs[0];
    }

    return (
      <div className={this.props.className || ""} style={this.props.style}>
        <ul className="nav nav-tabs px-3 px-md-4" role="tablist">
          {this.props.tabs.map((tab, index) => {
            if (!tab.hide) {
              if (tab.content) {
                return (
                  <li key={index} className="nav-item">
                    <a
                      onClick={() =>
                        this.setState({ activeTab: index, subActiveTab: null })
                      }
                      className={
                        "nav-link " +
                        (this.state.activeTab === index ? "active" : "") +
                        (tab.disabled ? "disabled" : "")
                      }
                      href="##"
                    >
                      {tab.name}
                    </a>
                  </li>
                );
              } else if (tab.subTabs) {
                return (
                  <li key={index} className="nav-item">
                    <DropDown
                      closeOnContentClick={false}
                      btnClass={
                        "nav-link " +
                        (this.state.activeTab === index
                          ? "active"
                          : "text-primary") +
                        (tab.disabled ? "disabled" : "")
                      }
                      contentClass="px-0 py-2"
                      text={tab.name}
                    >
                      {tab.subTabs.map((subTab, subIndex) => {
                        return (
                          <a
                            key={subIndex}
                            href="##"
                            onClick={() =>
                              this.setState({
                                activeTab: index,
                                subActiveTab: subIndex,
                              })
                            }
                            className={
                              "dropdown-item " +
                              (subTab.disabled ? "disabled " : "") +
                              (this.state.subActiveTab === subIndex
                                ? "bg-primary text-white"
                                : "")
                            }
                          >
                            {subTab.name}
                          </a>
                        );
                      })}
                    </DropDown>
                  </li>
                );
              }
            }
            return null;
          })}
        </ul>

        <div className="tab-content bg-light py-4 px-3 px-md-4">
          <div className="tab-pane active">{currentTab.content}</div>
        </div>
      </div>
    );
  }
}
