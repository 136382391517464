import React from "react";
import Icon, { Stack } from "@mdi/react";
import { mdiLoading, mdiCircleOutline } from "@mdi/js";

/*
<LoaderButton 
    onClick={clickEvent}
    className="btn-primary" 
    loading={this.state.loading} 
    loadingText="Loading..."
    disabled={true}>
  Submit
</LoaderButton>
*/
export default function LoaderButton(props) {
  const disabled =
    props.disabled === true || props.disabled === "true" ? true : false;
  const className =
    "btn " +
    (props.className || "btn-primary") +
    (props.loading || disabled ? " disabled" : "");
  const loadingText = props.loadingText || "";
  // if btn-outline-, color should be darker
  const circleColor = className.includes("outline")
    ? "rgba(100, 100, 100, 0.5)"
    : "rgba(255, 255, 255, 0.49)";

  return (
    <button
      onClick={props.onClick}
      className={className}
      disabled={disabled ? true : false}
    >
      {!props.loading ? (
        props.children && <span className="mr-1">{props.children}</span>
      ) : (
        <span>
          <Stack size={1} spin={true} className="mdi">
            <Icon
              path={mdiCircleOutline}
              size={1}
              color={circleColor}
              className="mdi"
            />
            <Icon path={mdiLoading} size={1} color="white" className="mdi" />
          </Stack>
          {loadingText}
        </span>
      )}
    </button>
  );
}
