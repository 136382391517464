import React, { useRef, useState, useEffect } from "react";
import "./DropDown.css";

/*
<DropDown
  text="My Dropdown"
  label="Select Date"   //use this to make dropdown floating label. (btnClass="form-control" & className="form-label-group")
  className="mb-3"
  btnClass="btn btn-link"
  contentClass="px-0"
  closeOnContentClick={false}  // default true
  direction="up"
  disabled={true}
  >
  Children content
</DropDown>
*/
export default function DropDown(props) {
  const [open, setOpen] = useState(false);
  const aRef = useRef(null);
  const contentRef = useRef(null);
  const [contentLeft, setContentLeft] = useState(0); // absolute left position
  const closeOnContentClick = useRef(props.closeOnContentClick !== false);

  useEffect(() => {
    // after content div binded (appeared) push it to left if it overflows out of window to right
    if (contentRef.current && contentLeft === 0) {
      const { right } = contentRef.current.getBoundingClientRect();
      if (right > window.outerWidth) {
        setContentLeft(window.outerWidth - right - 20 + "px");
      }
    }
  }, [contentLeft]);

  useEffect(() => {
    function onClickOutside(event) {
      if (contentRef.current) {
        // Outside click
        if (
          !aRef.current.contains(event.target) &&
          !contentRef.current.contains(event.target)
        ) {
          setOpen(false);
        }
        // Inside (content) click
        else if (
          closeOnContentClick.current &&
          contentRef.current.contains(event.target)
        ) {
          // Otherwise dropdown will be closed before click happens
          setTimeout(() => {
            setOpen(false);
          }, 400);
        }
      }
    }

    document.addEventListener("mousedown", onClickOutside); // Bind the event listener
    return () => {
      document.removeEventListener("mousedown", onClickOutside); // Unbind the event listener on clean up
    };
  }, [closeOnContentClick]);

  function render() {
    const disabled = props.disabled === true;
    return (
      <div
        className={`custom-dropdown drop${props.direction || "down"} ${
          props.className || ""
        }`}
      >
        <a
          className={(props.btnClass || "") + (disabled ? " disabled" : "")}
          ref={aRef}
          onClick={() => setOpen((o) => !o)}
          href="##"
          id="dd-a"
          disabled={disabled}
        >
          {props.text}
        </a>
        {props.label && <label htmlFor="dd-a">{props.label}</label>}
        {open && (
          <div
            ref={contentRef}
            style={{ left: contentLeft }}
            className={`dropdown-menu show animate-dropdown shadow ${
              props.contentClass || ""
            }`}
          >
            {props.children}
          </div>
        )}
      </div>
    );
  }

  return render();
}
